export const wrapper = {
  marginBottom: 40,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const headerWrap = (theme) => ({
  backgroundColor: theme.secondaryDark,
  marginBottom: 50,
});

export const heading = (theme) => ({
  paddingTop: 75,
  paddingBottom: 75,
  color: theme.white,
  fontSize: 48,
  fontWeight: 500,
});

export const expertBox = (image) => (theme) => ({
  backgroundColor: theme.white,
  marginBottom: 40,
  '& .course-image': {
    width: '100%',
    height: '100%',
    minHeight: 315,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${image})`,
  },
  '& .course-text-box': {
    padding: 40,
    '& .title': {
      marginBottom: 8,
      fontSize: 28,
    },
    '& .subtitle': {
      marginBottom: 24,
      fontSize: 21,
      color: theme.grayLight,
    },
    '& .text': {
      fontSize: 14,
      color: theme.grayLight,
      lineHeight: 1.5,
      marginBottom: 24,
      wordBreak: 'break-all',
    },
  },
});

export const linkButton = (theme) => ({
  textTransform: 'uppercase',
  borderRadius: 4,
  cursor: 'pointer',
  padding: '10px 20px',
  color: theme.white,
  border: `1px solid ${theme.primaryLight}`,
  letterSpacing: 0.5,
  lineHeight: 1,
  textAlign: 'center',
  backgroundColor: theme.primaryLight,
  userSelect: 'none',
  outline: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  fontSize: 14,
  '&:hover': {
    backgroundColor: theme.primary,
  },
});

export const skeletonStyle = {
  marginBottom: 40,
};

export const footerWrap = {
  marginBottom: 50,
  marginTop: 50,
  display: 'flex',
  justifyContent: 'center',
};
