import { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import Dropdown from '../Dropdown';
import { outputValue } from '../Dropdown/utils';
import { compareDropdownProps } from './utils';
import { validate as inputValidation } from '../../../utils';
import { inputContainer, inputLabel, dropdownInput, horizontalContainer } from './styles';

const DropdownForm = ({
  dropdownRef,
  id,
  required,
  onChange,
  onError,
  label,
  validate,
  horizontal,
  value,
  mapValue,
  noSimplify,
  isTouched: isTouchedInit,
  className,
  uniqueKey = 'value',
  displayKey = 'label',
  ...dropDownProps
}) => {
  const { disabled, options, multiSelect } = dropDownProps;
  const [error, setError] = useState(false);
  const internalDropDownRef = useRef(null);
  const [isTouched, setIsTouched] = useState(isTouchedInit);
  const hasError = isTouched && error;
  const isDisabled = disabled || !options?.length;

  useImperativeHandle(dropdownRef, () => ({
    changeValue: (newValue) => {
      handleSelect(newValue);
      internalDropDownRef.current?.changeValue(newValue);
    },
    value: () => internalDropDownRef.current?.value,
    displayValue: () => internalDropDownRef.current?.displayValue,
    changeIsTouched: setIsTouched,
  }));

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    checkForError(internalDropDownRef.current.value);
  }, [isTouched]);

  const handleSelect = (input) => {
    const newValue = input ?? (multiSelect ? [] : null);
    checkForError(newValue);
    onChange(id, outputValue(newValue, displayKey, mapValue, !noSimplify));
  };

  const handleBlur = ({ currentTarget }) =>
    !isDisabled &&
    setTimeout(() => {
      if (currentTarget.contains(document.activeElement)) return;
      isTouched ? checkForError(internalDropDownRef.current.value) : setIsTouched(true);
    });

  const checkForError = (newValue) => {
    let newError = null;

    // If validate function is provided check for error
    if (isFunction(validate)) newError = validate(newValue, id);
    else if (required) newError = inputValidation(newValue, 'required');
    onError(id, newError);
    isTouched && setError(newError);
  };

  const dropdownComponent = (
    <>
      <Dropdown
        ref={internalDropDownRef}
        value={value}
        autoWidth
        noSimplify
        noPrefilled={!required}
        css={dropdownInput(hasError)}
        onChange={handleSelect}
        {...dropDownProps}
        placeholder={dropDownProps.placeholder || `Select ${label}...`}
        displayKey={displayKey}
        uniqueKey={uniqueKey}
      />
    </>
  );

  return (
    <div
      role="menuitem"
      tabIndex="0"
      onBlur={handleBlur}
      {...(isString(className) && { className })}
      css={inputContainer(horizontal, className)}>
      <label htmlFor={id} css={inputLabel(horizontal, isDisabled)}>
        {label}
      </label>
      {horizontal ? <div css={horizontalContainer}>{dropdownComponent}</div> : dropdownComponent}
    </div>
  );
};

DropdownForm.propTypes = {
  dropdownRef: PropTypes.object,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  mapValue: PropTypes.func,
  horizontal: PropTypes.bool,
  validate: PropTypes.func,
  noSimplify: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  uniqueKey: PropTypes.string,
  displayKey: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default forwardRef((initProps, ref) =>
  useMemo(() => <DropdownForm {...initProps} dropdownRef={ref} />, compareDropdownProps(initProps)),
);
