import { postReq, getReq } from './axios/makeRequest';
import { getAllQuizTakesParams, getQuizHistoryModel } from './models/quizHistory';

const baseUrl = `${apiUrls.oneAcademy}/quiz-history`;

export const postNewQuizHistory = (values) => postReq(`${baseUrl}/new`, values);

export const getAllQuizTakes = async (options = {}) => {
  const params = getAllQuizTakesParams(options);
  const [response, error] = await getReq(`${baseUrl}/quiz-takes/list?${params}`);
  return [getQuizHistoryModel(response), error];
};
