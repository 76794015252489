import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { isNull } from 'lodash-es';
import { Row, Col, Tabs, Tab, Skeleton, showApiError } from '../../../components/UI';
import { useTranslations } from '../../../components/UI/Theme';
import CourseCategoryCard from '../../../components/Pages/Course/CourseCategoryCard';
import { getAllCategories } from '../../../services/categories';
import { wrapper, headerWrap, heading, tabContainer, skeletonStyle } from './styles';

const Courses = () => {
  const [categories, setCategories] = useState(null);
  const { translations } = useTranslations();
  const match = useRouteMatch();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return showApiError(err);

    setCategories(res?.data ?? []);
  };

  const renderTabs = () => {
    const tabs = [
      {
        id: 0,
        name: translations.all,
      },
      ...categories,
    ];

    return tabs.map((el) => (
      <Tab key={el.id} label={el.name} url={`/${el?.id}`} component={() => <CourseCategoryCard category={el} />} />
    ));
  };

  return (
    <div css={wrapper}>
      <div css={headerWrap}>
        <Row horizontalGap={12} noWrap container>
          <Col xs={12}>
            <h1 css={heading}>{translations.courses}</h1>
          </Col>
        </Row>
      </div>
      <Row horizontalGap={12} noWrap container>
        <Col xs={12}>
          {!isNull(categories) ? (
            <Tabs startingRoute={match.path} tabsContentClass={tabContainer}>
              {renderTabs()}
            </Tabs>
          ) : (
            <>
              <Skeleton height="50px" width="100%" />
              <div css={tabContainer}>
                {Array(5)
                  .fill()
                  .map((el, index) => (
                    <div key={index} css={skeletonStyle}>
                      <Skeleton height="280px" width="100%" />
                    </div>
                  ))}
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Courses;
