import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, showApiError } from '../../../components/UI';
import { useTranslations } from '../../../components/UI/Theme';
import noAvatar from '../../../assets/images/default-avatar.png';
import LessonCard from '../../../components/Shared/LessonCard';
import { getExpertById } from '../../../services/experts';
import {
  headerWrapper,
  avatarWrap,
  avatarImgWrap,
  subtitleStyle,
  biography,
  biographyText,
  lessonsStyle,
  lessonsHeading,
  lessonBuffer,
} from './styles';

const Expert = () => {
  const { translations } = useTranslations();
  const { id: expertId } = useParams();
  const location = useLocation();
  const [expert, setExpert] = useState(location.state);

  useEffect(() => {
    fetchExpert();
  }, []);

  const fetchExpert = async () => {
    const [res, err] = await getExpertById(expertId);
    if (err) return showApiError(err);
    // console.log(res);
    setExpert(res);

    setExpert((prev) => ({
      ...prev,
      res,
    }));
  };

  return (
    <>
      <div css={headerWrapper}>
        <Row noWrap container>
          <Col sm={12}>
            <div css={avatarWrap}>
              <div css={avatarImgWrap(expert?.picUrl ?? noAvatar)} />
              <div>
                <h2 className="name">
                  {expert?.firstName} {expert?.lastName}
                </h2>
                <h4 className="position">{expert?.position}</h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div css={biography}>
        <Row noWrap container>
          <Col sm={12}>
            <h1 css={subtitleStyle}>{translations.biography}</h1>
          </Col>
        </Row>
        <Row noWrap horizontalGap={24} container>
          <Col md={12}>
            <div css={biographyText} dangerouslySetInnerHTML={{ __html: expert?.description }} />
          </Col>
        </Row>
      </div>
      <div css={lessonsStyle}>
        <Row noWrap container>
          <Col sm={12}>
            <div css={lessonsHeading}>
              <h1>{translations.lessons}</h1>
            </div>
          </Col>
        </Row>
        <Row noWrap horizontalGap={12} container>
          {expert?.courses &&
            expert?.courses.map((el) => (
              <Col md={3} key={el.id}>
                <div css={lessonBuffer}>
                  <LessonCard lesson={el} author={expert} />
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default Expert;
