import { Global, css } from '@emotion/react';
import '../assets/icons/font-awesome/font-awesome.css';
import '../assets/icons/line-awesome/line-awesome.css';
import theme from './theme.json';

export default () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

      :root {
        font-size: 13px;
      }
      html {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        margin-right: calc(100% - 100vw);
      }
      body {
        font-family: 'Public Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        color: ${theme.secondaryDark};
        background-color: #f5f5fa;
        overflow-x: hidden;
        margin: 0;
      }

      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      main,
      menu,
      nav,
      section,
      summary {
        display: block;
      }
      audio,
      canvas,
      progress,
      video {
        display: inline-block;
        vertical-align: baseline;
      }
      audio:not([controls]) {
        display: none;
        height: 0;
      }
      [hidden],
      .hidden,
      template {
        display: none;
      }
      p,
      figure,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      button,
      input,
      optgroup,
      select,
      textarea {
        margin: 0;
      }
      a {
        background-color: transparent;
      }
      a:active,
      a:hover {
        outline: 0;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      a:focus,
      a:hover {
        text-decoration: none;
      }
      a:focus {
        outline: 0;
      }
      abbr[title] {
        border-bottom: 1px dotted;
      }
      b,
      strong {
        font-weight: 700;
      }
      dfn {
        font-style: italic;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Public Sans', sans-serif;
        font-weight: 700;
      }
      h1 {
        font-size: 2em;
      }
      h2 {
        font-size: 1.5em;
      }
      h3 {
        font-size: 1.38em;
      }
      h4 {
        font-size: 1.25em;
      }
      h5 {
        font-size: 1.08em;
      }
      h6 {
        font-size: 1em;
      }
      mark {
        background: #ff0;
        color: #000;
      }
      small {
        font-size: 80%;
      }
      sub,
      sup {
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
      }
      sup {
        top: -0.5em;
      }
      sub {
        bottom: -0.25em;
      }
      img {
        border: 0;
      }
      svg:not(:root) {
        overflow: hidden;
      }
      hr {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
      }
      pre {
        overflow: auto;
      }
      code,
      kbd,
      pre,
      samp {
        font-family: monospace, monospace;
        font-size: 1em;
      }
      button,
      input,
      optgroup,
      select,
      textarea {
        color: inherit;
        font: inherit;
      }
      button {
        overflow: visible;
      }
      button,
      select {
        text-transform: none;
      }
      button,
      html input[type='button'],
      input[type='reset'],
      input[type='submit'] {
        -webkit-appearance: button;
        cursor: pointer;
      }
      button[disabled],
      html input[disabled] {
        cursor: not-allowed;
      }
      button::-moz-focus-inner,
      input::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      input {
        line-height: normal;
      }
      input[type='checkbox'],
      input[type='radio'] {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
      }
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        height: auto;
      }
      input[type='search'] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
      }
      fieldset {
        border: 1px solid silver;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
      }
      legend {
        border: 0;
        padding: 0;
      }
      textarea {
        overflow: auto;
      }
      optgroup {
        font-weight: 700;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      td,
      th {
        padding: 0;
      }
      * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      :after,
      :before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      button,
      input,
      select,
      textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
      img {
        vertical-align: middle;
      }
      .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
      }
      hr {
        border: 0;
        border-top: 1px solid #eee;
      }
      [role='button'] {
        cursor: pointer;
        outline: none;
      }
    `}
  />
);
