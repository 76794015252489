import PropTypes from 'prop-types';
import { fromPairs, isString } from 'lodash-es';
import { colComp } from './styles';

export const COLUMN_DISPLAY_NAME = 'GridColumn';

const Col = (props) => {
  const { children, role, tabIndex, onClick, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onDrag, className } =
    props;
  let eventProps = {};
  const events = [
    ['onClick', onClick],
    ['onMouseDown', onMouseDown],
    ['onMouseUp', onMouseUp],
    ['onMouseEnter', onMouseEnter],
    ['onMouseLeave', onMouseLeave],
    ['onDrag', onDrag],
  ].filter((el) => Boolean(el[1]));

  if (events.length) {
    events.push(['role', role || 'button'], ['tabIndex', tabIndex || -1]);
    eventProps = fromPairs(events);
  }

  return (
    <div css={colComp(props)} {...eventProps} {...(isString(className) && { className })}>
      {children}
    </div>
  );
};

Col.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDrag: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Col.displayName = COLUMN_DISPLAY_NAME;

export default Col;
