import { useRef, useEffect, useState, useLayoutEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { loader } from './styles';

const Spinner = memo((props) => {
  const { size: sizeProp = 50, loading, fullScreen, className } = props;
  const [size, setSize] = useState(sizeProp);
  const loaderRef = useRef(null);

  useEffect(() => {
    toggleLoader(loading);
    loading && !fullScreen && setUpPosition();

    return () => {
      toggleLoader(false);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading]);

  useEffect(() => {
    setSize(sizeProp);
  }, [sizeProp]);

  useLayoutEffect(() => {
    const container = loaderRef.current.parentNode;
    const { height, width } = container.getBoundingClientRect();

    if (height === 0 || width === 0) return;

    // If the component is smaller than the default size set the size to half of component size
    if (height / 2 < size || width / 2 < size) setSize(Math.min(height, width) / 2);
  }, []);

  const setUpPosition = () => {
    const container = loaderRef.current.parentNode;
    const { top, bottom } = container.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // If the component to which the loader will be applied is bigger than the window height
    // we need to put the spinner into the middle of the screen so the user can see it even when scroll
    if (top < windowHeight && bottom > windowHeight) {
      loaderRef.current.style.top = `${windowHeight / 2 - top - size / 2}px`;
      // Listen for changes into the scroll and place the loader into the middle of the screen
      window.addEventListener('scroll', handleScroll);
    }
  };

  const handleScroll = () => {
    if (!loaderRef.current?.parentNode) window.removeEventListener('scroll', handleScroll);
    else {
      const { parentNode } = loaderRef.current;
      const windowHeight = window.innerHeight;
      const { top } = parentNode.getBoundingClientRect();
      loaderRef.current.style.top = `${windowHeight / 2 - top - size / 2}px`;
    }
  };

  const toggleLoader = (show) => {
    const { parentNode, style: loaderStyles } = loaderRef.current;
    const { style: parentStyles, childNodes } = parentNode;

    parentStyles.position = show ? 'relative' : '';
    parentStyles.pointerEvents = show ? 'none' : '';
    loaderStyles.display = show ? 'block' : 'none';

    // If there is only the loader inside the parent exit from function
    if (childNodes.length === 1) return;

    show ? wrapChilds() : unwrapChilds();
  };

  // Put all childrens into container with blur effect and no pointer events so it will be disabled
  const wrapChilds = () => {
    const { parentNode } = loaderRef.current;
    const { childNodes } = parentNode;

    const blurContainer = document.createElement('div');
    blurContainer.style.filter = 'blur(3px)';
    blurContainer.id = 'blurContainer';
    isString(className) && blurContainer.classList.add(className);
    parentNode.insertBefore(blurContainer, loaderRef.current);

    const validChilds = [...childNodes];
    validChilds.forEach(
      (child) =>
        !child.isSameNode(loaderRef.current) && !child.isSameNode(blurContainer) && blurContainer.appendChild(child),
    );
  };

  const unwrapChilds = () => {
    const { parentNode } = loaderRef.current;
    const { childNodes } = parentNode;
    const blurContainer = childNodes.item(
      [...childNodes].findIndex((el) => el.getAttribute && el.getAttribute('id') === 'blurContainer'),
    );

    if (!blurContainer) return;

    [...blurContainer.childNodes].forEach((el) => parentNode.appendChild(el));
    parentNode.removeChild(blurContainer);
  };

  return (
    <svg ref={loaderRef} css={loader(size, props)} className="spinner" viewBox={`0 0 ${size} ${size}`}>
      <circle css="path" cx={`${size / 2}`} cy={`${size / 2}`} r={`${size / 2 - 5}`} fill="none" strokeWidth="5" />
    </svg>
  );
});

Spinner.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Spinner;
