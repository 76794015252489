const NUMBER_OF_COLUMNS = 12;

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

const baseContainer = {
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
};

export const containerComp = () => ({
  ...baseContainer,
  '@media (min-width: 576px)': {
    maxWidth: 540,
  },
  '@media (min-width: 768px)': {
    maxWidth: 720,
  },
  '@media (min-width: 992px)': {
    maxWidth: 960,
  },
  '@media (min-width: 1200px)': {
    maxWidth: 1140,
  },
});

export const rowComp = ({
  column,
  reverse,
  noWrap,
  align,
  justify,
  padding,
  margin,
  minWidth,
  gap,
  horizontalGap,
  verticalGap,
  flex,
}) => [
  row,
  {
    flexDirection: column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row',
    flexWrap: noWrap ? 'no-wrap' : 'wrap',
  },
  align && {
    alignItems: align,
  },
  justify && {
    justifyContent: justify,
  },
  gap && {
    maxWidth: `calc(100% + ${2 * gap}px)`,
    margin: `-${gap}px`,
  },
  horizontalGap && {
    maxWidth: `calc(100% + ${2 * horizontalGap}px)`,
    marginLeft: `-${horizontalGap}px`,
    marginRight: `-${horizontalGap}px`,
  },
  verticalGap && {
    maxWidth: `calc(100% + ${2 * verticalGap}px)`,
    marginTop: `-${verticalGap}px`,
    marginBottom: `-${verticalGap}px`,
  },
  flex && { flex },
  padding && { padding },
  margin && { margin },
  minWidth && { minWidth },
];

export const baseCol = {
  flex: '1 0 0',
  maxWidth: '100%',
  outline: 'none',
};

export const autoCol = {
  flex: '0 0 auto',
  maxWidth: '100%',
  outline: 'none',
};

export const getColumnStyles = (type) => {
  if (type === true) return baseCol;
  if (type === 'auto') return autoCol;
  return {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
  };
};

export const col = baseCol;

export const xsCol = (xs) =>
  xs && {
    ...baseCol,
    '@media (max-width: 575px)': getColumnStyles(xs),
  };

export const smCol = (sm) =>
  sm && {
    ...baseCol,
    '@media (min-width: 576px)': getColumnStyles(sm),
  };

export const mdCol = (md) =>
  md && {
    ...baseCol,
    '@media (min-width: 768px)': getColumnStyles(md),
  };

export const lgCol = (lg) =>
  lg && {
    ...baseCol,
    '@media (min-width: 992px)': getColumnStyles(lg),
  };

export const xlCol = (xl) =>
  xl && {
    ...baseCol,
    '@media (min-width: 1200px)': getColumnStyles(xl),
  };

export const colComp = ({ xs, sm, md, lg, xl, gap, verticalGap, horizontalGap, dFlex }) => [
  baseCol,
  xsCol(xs),
  smCol(sm),
  mdCol(md),
  lgCol(lg),
  xlCol(xl),
  gap && {
    padding: gap,
  },
  verticalGap && {
    paddingTop: verticalGap,
    paddingBottom: verticalGap,
  },
  horizontalGap && {
    paddingLeft: horizontalGap,
    paddingRight: horizontalGap,
  },
  dFlex && {
    display: 'flex',
  },
];
