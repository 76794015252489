import queryString from 'query-string';
import { pick, isEmpty } from 'lodash-es';

export const getQuizStepModel = (response) =>
  response && {
    ...response,
    ...(!isEmpty(response?.translations) && {
      ...pick(response?.translations[0], ['title']),
    }),
    answers: response?.answers && !isEmpty(response?.answers) ? getAnswerModel(response?.answers) : [],
  };

export const getAnswerModel = (response) =>
  response &&
  response?.map((el) => ({
    ...el,
    ...(!isEmpty(el?.translations) && {
      ...pick(el?.translations[0], ['text', 'correctText']),
    }),
  }));

export const getQuestionsModel = (response) =>
  response &&
  response?.map((el) => ({
    ...el,
    ...(!isEmpty(el?.translations) && {
      ...pick(el?.translations[0], ['title', 'correctText']),
    }),
    answers: el?.answers && !isEmpty(el?.answers) ? getAnswerModel(el?.answers) : [],
  }));

export const getQuizParams = (options) =>
  queryString.stringify(
    {
      ...(options.accountId && { accountId: options.accountId }),
      ...(options.stepId && { stepId: options.stepId }),
      ...(options.randomize && { randomize: options.randomize }),
      ...pick(options, ['pageSize']),
    },
    { arrayFormat: 'index' },
  );
