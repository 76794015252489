import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { isObject, isUndefined, omit, isFunction, isString } from 'lodash-es';
import { inputField } from './styles';
import './rdtStyles.css';

const Input = forwardRef(({ value, onChange, dateTimeProps, className, ...inputAttr }, ref) => {
  const inputProps = isUndefined(value) || isUndefined(onChange) ? { ref } : { value: value || '', onChange, ref };
  const Component = inputAttr.type === 'textarea' ? 'textarea' : 'input';
  const dateTimeFormat = dateTimeProps?.format ?? 'DD/MM/YYYY';

  return dateTimeProps ? (
    <Datetime
      dateFormat={dateTimeFormat}
      onBlur={inputAttr.onBlur}
      value={value && moment(value).format(dateTimeFormat)}
      closeOnSelect
      onChange={(val) =>
        isObject(val) &&
        isFunction(onChange) &&
        onChange({
          target: {
            value: moment(val).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
          },
        })
      }
      inputProps={{
        ref,
        ...(isString(className) && { className }),
        css: inputField,
      }}
      {...omit(dateTimeProps, 'format')}
    />
  ) : (
    <Component ref={ref} {...(isString(className) && { className })} css={inputField} {...inputProps} {...inputAttr} />
  );
});

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateTimeProps: PropTypes.object,
};

export default Input;
