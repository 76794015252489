import { useEffect, useState } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Row, Col, showApiError, Skeleton, Button } from '../../../UI';
import CourseCard from '../../../Shared/CourseCard';
import { useAuth } from '../../../UI/Authenticate';
import { getMyCourses } from '../../../../services/courses';
import { ReactComponent as NoCoursesImg } from '../../../../assets/images/no-courses-img.svg';
import { noData } from './styles';

const ContinueLearning = () => {
  const history = useHistory();
  const [myCourses, setMyCourses] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
      myPackages,
    },
  } = useAuth();

  useEffect(() => {
    fetchMyCourses();
  }, [currentAccount?.id]);

  const fetchMyCourses = async () => {
    const [res, err] = await getMyCourses({
      accountId,
      includeDetails: true,
      pageIndex: 0,
      pageSize: 3,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setMyCourses(res?.data ?? []);
  };

  return (
    <Row horizontalGap={12} noWrap container>
      {!isNull(myCourses) ? (
        !isEmpty(myCourses) ? (
          myCourses.map((el) => (
            <Col sm={4} key={el.id} className="bottomBuffer">
              <CourseCard data={el} />
            </Col>
          ))
        ) : (
          <div css={noData}>
            <div className="no-data-inner">
              <NoCoursesImg />
              {isEmpty(myPackages) ? (
                <>
                  <h3>Buy an educational module to start learning in the Academy! </h3>
                  <Button
                    onClick={() => {
                      const win = window.open(`${applicationUrls.oneLife}/shop/all-packages`, '_blank');
                      win.focus();
                    }}>
                    Go to shop
                  </Button>
                </>
              ) : (
                <>
                  <h3>Start your first course!</h3>
                  <Button onClick={() => history.push('/courses')}>See all courses</Button>
                </>
              )}
            </div>
          </div>
        )
      ) : (
        Array(3)
          .fill()
          .map((el, index) => (
            <Col sm={4} key={index} className="bottomBuffer">
              <Skeleton height="340px" width="100%" />
            </Col>
          ))
      )}
    </Row>
  );
};

export default ContinueLearning;
