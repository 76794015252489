export const headerWrapper = (theme) => ({
  backgroundColor: theme.secondaryDark,
  paddingTop: 40,
  paddingBottom: 40,
});

export const avatarWrap = (theme) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '& .name': {
    color: theme.white,
    marginBottom: 8,
  },
  '& .position': {
    fontWeight: 400,
    color: theme.grayLight,
    marginBottom: 16,
  },
});

export const avatarImgWrap = (image) => ({
  position: 'relative',
  marginRight: 40,
  height: 120,
  width: 120,
  borderRadius: 120,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

export const subtitleStyle = {
  marginBottom: 32,
};

export const biography = (theme) => ({
  paddingBottom: 40,
  paddingTop: 40,
  marginBottom: 56,
  backgroundColor: theme.whiteGray,
});

export const biographyText = (theme) => ({
  '& p': {
    fontSize: 14,
    color: theme.grayLight,
    lineHeight: 1.5,
    marginBottom: 16,
  },
});

export const lessonsStyle = {
  paddingBottom: 80,
};

export const lessonsHeading = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 35,
};

export const lessonBuffer = {
  marginBottom: 24,
};
