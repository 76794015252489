import { Route } from 'react-router-dom';
import Courses from './Courses';
import FriendsRankList from './FriendsRankList';
import Course from './Course';
import CourseCategories from './CourseCategories';
import MyCourses from './MyCourses';
import Dashboard from './Dashboard';
import Experts from './Experts';
import Expert from './Expert';

export default [
  {
    exact: true,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/my-courses',
    component: MyCourses,
  },
  {
    path: '/courses',
    component: Courses,
  },
  {
    path: '/friends-rank-list',
    component: FriendsRankList,
  },
  {
    path: '/course-categories',
    component: CourseCategories,
  },
  {
    path: '/course/:id',
    component: Course,
  },
  {
    exact: true,
    path: '/experts',
    component: Experts,
  },
  {
    path: '/expert/:id',
    component: Expert,
  },
].map((route) => <Route key={route.path} {...route} />);
