import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { container, contentContainer, headerContainer } from './styles';

const Paper = (props) => {
  const { children, header, className } = props;

  return (
    <div css={container(props)} {...(isString(className) && { className })}>
      {header &&
        (isValidElement(header) ? (
          header
        ) : (
          <div css={headerContainer}>
            <h2>{header}</h2>
          </div>
        ))}
      <div css={contentContainer(props)}>{children}</div>
    </div>
  );
};

Paper.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  className: PropTypes.string,
};

export default Paper;
