import { getReq, putReq } from './axios/makeRequest';
import { myProfileModel } from './models/profile';

const baseUrl = `${apiUrls.oneLife}/profile`;

export const getMyProfile = async () => {
  const [data, err] = await getReq(`${baseUrl}/v2/my?withAccounts=true`);
  return [data && myProfileModel(data), err];
};

export const switchAccount = (id) => putReq(`${baseUrl}/setcurrent`, { id });

export const getIdentityProviderByEmail = email =>
  getReq(`${apiUrls.oneLife}/users/checkstatus?email=${email}`);
