import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { add, mul } from 'exact-math';
import uuid from '../../../utils/uuid';

const FEE_AMOUNT = 30;

const ShopContext = React.createContext({});
export const useShop = () => useContext(ShopContext);

const ShopProvider = (props) => {
  const { children } = props;
  const [items, setItems] = useState([]);

  const getPrice = (item) => mul(item.amount, add(item.price, item.activationFee ? FEE_AMOUNT : 0));

  const addToShoppingCart = (item) => {
    const match = items.find(
      (el) => el.catalogueItemId === item.catalogueItemId && el.activationFee === item.activationFee,
    );

    setItems((prev) =>
      match
        ? prev.map((el) =>
            el.catalogueItemId === item.catalogueItemId ? { ...el, amount: add(el.amount, item.amount) } : el,
          )
        : prev.concat({ ...item, uuid: uuid() }),
    );
  };

  const removeFromShoppingCart = (itemId) => {
    const newItems = items.filter((el) => el.uuid !== itemId);
    setItems(newItems);
  };

  const editItemInShoppingCart = (item) => {
    const newItems = items.map((el) => (el.uuid === item.uuid ? { ...el, ...item } : el));
    setItems(newItems);
  };
  const deleteShoppingCart = () => {
    setItems([]);
  };

  return (
    <ShopContext.Provider
      value={{
        total: items.reduce((acc, cur) => add(acc, getPrice(cur)), 0),
        items,
        addToShoppingCart,
        removeFromShoppingCart,
        editItemInShoppingCart,
        deleteShoppingCart,
      }}>
      {children}
    </ShopContext.Provider>
  );
};

ShopProvider.propTypes = {
  children: PropTypes.node,
};

export default ShopProvider;
