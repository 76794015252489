export const courseBox = (theme) => ({
  backgroundColor: theme.white,
  marginBottom: 40,
  '& .course-image': {
    width: '100%',
    height: '100%',
    minHeight: 300,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  '& .course-text-box': {
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 40,
    maxWidth: 360,
    margin: '0px auto',
    '& .title': {
      marginBottom: 16,
      fontSize: 24,
    },
    '& .text': {
      fontSize: 14,
      color: theme.grayLight,
      wordBreak: 'break-all',
      lineHeight: 1.5,
      marginBottom: 16,
    },
  },
});

export const buttonStyle = {
  textTransform: 'uppercase',
};

export const skeletonStyle = {
  marginBottom: 40,
};
