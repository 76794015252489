import { useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';
import Ripple from '../Ripple';
import Icon from '../Icon';
import { scrollLeftIcon, scrollRightIcon } from './styles';

let interval = null;

const ScrollArrows = forwardRef(({ arrowsProps = {}, setArrowsProps, onScroll, scrollContainer: scrollRef }, ref) => {
  const { scrollStart, scrollEnd } = arrowsProps;

  useImperativeHandle(ref, () => ({
    changeStart: (val) => setArrowsProps((prev) => ({ ...prev, scrollStart: val })),
    changeEnd: (val) => setArrowsProps((prev) => ({ ...prev, scrollEnd: val })),
    getStart: () => scrollStart,
    getEnd: () => scrollEnd,
  }));

  useEffect(() => {
    const containerEnd = scrollRef.current?.lastChild.getBoundingClientRect().right;
    const { scrollWidth, scrollLeft, offsetWidth } = scrollRef.current;

    setArrowsProps((prev) => ({
      ...prev,
      show: containerEnd > window.innerWidth - 40,
      scrollEnd: scrollWidth - scrollLeft === offsetWidth,
      scrollStart: scrollLeft === 0,
    }));

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [arrowsProps.show]);

  const handleWindowResize = throttle(({ target: { innerWidth } }) => {
    const containerEnd = scrollRef.current?.lastChild.getBoundingClientRect().right;
    if (!containerEnd) return;

    const show = containerEnd > innerWidth - 40;
    show !== arrowsProps.show && setArrowsProps((prev) => ({ ...prev, show }));
  }, 16);

  const handleMouseDown = (val) => {
    clearInterval(interval);

    interval = setInterval(() => {
      if (!scrollRef.current) return;

      const { scrollWidth, scrollLeft, offsetWidth } = scrollRef.current;

      if (scrollLeft !== 0 && scrollStart) setArrowsProps((prev) => ({ ...prev, scrollStart: false }));
      if (scrollLeft === 0 && !scrollStart) setArrowsProps((prev) => ({ ...prev, scrollStart: true }));

      if (scrollWidth - scrollLeft === offsetWidth && !scrollEnd)
        setArrowsProps((prev) => ({ ...prev, scrollEnd: true }));
      if (scrollWidth - scrollLeft !== offsetWidth && scrollEnd)
        setArrowsProps((prev) => ({ ...prev, scrollEnd: false }));

      onScroll(val);
    }, 16);
  };

  const handleMouseUpLeave = () => clearInterval(interval);

  return (
    arrowsProps.show && (
      <>
        {scrollStart ? (
          <Icon material iconName="chevron_left" css={scrollLeftIcon(scrollStart)} />
        ) : (
          <Ripple flat backColor="grayLight" css={scrollLeftIcon(scrollStart)}>
            <div
              role="presentation"
              onMouseDown={() => handleMouseDown(-5)}
              onMouseUp={handleMouseUpLeave}
              onMouseLeave={handleMouseUpLeave}>
              <Icon material iconName="chevron_left" />
            </div>
          </Ripple>
        )}
        {scrollEnd ? (
          <Icon material iconName="chevron_right" css={scrollRightIcon(scrollEnd)} />
        ) : (
          <Ripple flat backColor="grayLight" css={scrollRightIcon(scrollEnd)}>
            <div
              role="presentation"
              onMouseDown={() => handleMouseDown(5)}
              onMouseUp={handleMouseUpLeave}
              onMouseLeave={handleMouseUpLeave}>
              <Icon material iconName="chevron_right" />
            </div>
          </Ripple>
        )}
      </>
    )
  );
});

ScrollArrows.propTypes = {
  arrowsProps: PropTypes.object,
  setArrowsProps: PropTypes.func,
  onScroll: PropTypes.func,
  scrollContainer: PropTypes.object,
};

export default ScrollArrows;
