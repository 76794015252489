export const wrapper = (theme) => ({
  backgroundColor: theme.white,
});

export const thumbWrap = (big) => (theme) => ({
  position: 'relative',
  backgroundColor: theme.secondaryLighter,
  height: big ? 320 : 160,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .thumb-label': {
    position: 'absolute',
    bottom: 16,
    left: big ? 32 : 16,
    backgroundColor: theme.primaryLight,
    height: 26,
    borderRadius: 47,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px',
    color: theme.white,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
  },
});

export const body = (big) => (theme) => ({
  backgroundColor: theme.white,
  minHeight: big ? 350 : 164,
  padding: big ? '16px 32px' : '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const infoSection = (big) => (theme) => ({
  marginBottom: big ? 16 : 8,
  color: theme.highlightsBlue,
  display: 'flex',
  alignItems: 'center',
  justifyContent: big ? 'space-between' : 'flex-start',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 8,
    },
    '& > p': {
      fontSize: 14,
      lineHeight: '20px',
    },
    '&:first-of-type': {
      marginRight: 12,
    },
  },
});

export const bodyText = (big) => (theme) => ({
  '& > h2': {
    fontSize: big ? 48 : 20,
    lineHeight: big ? 1.2 : 1.15,
    marginBottom: 8,
  },
  '& > p': {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.grayLight,
    wordBreak: 'break-all',
  },
});

export const bodyFooter = (big, authorData) => (theme) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: big ? 50 : 40,
  justifyContent: authorData ? 'space-between' : 'flex-end',
  '& .authorWrap': {
    display: 'flex',
    alignItems: 'center',
    '& .authorAvatar': {
      marginRight: 16,
      width: 50,
      height: 50,
      borderRadius: 50,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    '& .authorName': {
      fontSize: 14,
      lineHeight: 1.4,
      color: theme.gray,
      fontWeight: 600,
    },
    '& .authorPosition': {
      fontSize: 14,
      lineHeight: 1.4,
      color: theme.gray,
      fontWeight: 300,
    },
  },
});
