import { useRef, useState, useEffect } from 'react';
import { isNil, isFunction } from 'lodash-es';
import Icon from '../Icon';
import { useConstant } from '../hooks/useConstant';
import { dropDownOptionItem, dropDownGroupLabel, noResultsContainer, noResultsIcon } from './styles';

export default ({
  isOpen,
  options: initOptions,
  selected,
  multiSelect,
  onSelect,
  displayKey,
  containerRef,
  uniqueKey,
}) => {
  const searchRef = useRef({});
  const [searchResults, setSearchResults] = useConstant([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [scrollItems, setScrollItems] = useState([]);
  const hasSearch = searchRef.current?.value?.length;
  const scrollSize = 50;
  const needInfiniteScroll = (hasSearch ? searchResults?.length : initOptions.length) > scrollSize;

  useEffect(() => {
    isOpen && searchRef.current?.focus && searchRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    needInfiniteScroll && setScrollItems(initOptions.slice(0, scrollSize));
  }, [initOptions.length]);

  const getOptionValue = (val) => val[displayKey] ?? 'N/A';
  const getOptionCode = (val) => (isNil(val) ? null : val[uniqueKey] ?? val[displayKey]);
  const selectedCodes = multiSelect ? selected.map(getOptionCode) : getOptionCode(selected);

  const filterValues = (input) => (el) =>
    el.groupLabel ?? getOptionValue(el).toLowerCase().trim().indexOf(input.toLowerCase().trim()) !== -1;

  const filterResults = (input) => {
    containerRef.current.scrollTop = 0;
    const results = initOptions
      .filter(filterValues(input))
      .filter((el, i, arr) => !el?.groupLabel || (arr[i + 1] && !arr[i + 1]?.groupLabel));

    setSearchResults(results);
    return results.length ? setFilteredResults(results.slice(0, scrollSize)) : setFilteredResults(null);
  };

  const fetchMoreResults = () =>
    !hasSearch
      ? setScrollItems((prev) => prev.concat(initOptions.slice(prev.length, prev.length + scrollSize)))
      : setFilteredResults((prev) => prev.concat(searchResults.slice(prev.length, prev.length + scrollSize)));

  const renderBaseOptionElement = (option, i) => {
    const optionCode = getOptionCode(option);
    const optionRender = isFunction(option.render) ? option.render(option) : option[displayKey];
    const isSelected = multiSelect ? selectedCodes.indexOf(optionCode) !== -1 : optionCode === selectedCodes;

    return option.groupLabel ? (
      <h2 key={`dropDownItem${i}`} css={dropDownGroupLabel}>
        {option.groupLabel}
      </h2>
    ) : (
      <div
        key={`dropDownItem${i}`}
        role="menuitem"
        tabIndex={0}
        onClick={() => onSelect(option)}
        css={dropDownOptionItem(isSelected, isFunction(option.render))}>
        {multiSelect && (
          <Icon material iconName={isSelected ? 'check_box' : 'check_box_outline_blank'} color="primary" />
        )}
        {optionRender}
      </div>
    );
  };

  const noResults = (
    <div css={noResultsContainer}>
      <Icon material size={30} iconName="search" color="primary" css={noResultsIcon} />
      <h5>No results found</h5>
    </div>
  );

  const allOptions = hasSearch ? filteredResults : needInfiniteScroll ? scrollItems : initOptions;

  const renderList = allOptions ? allOptions.map(renderBaseOptionElement) : noResults;

  return {
    allOptionsLength: (hasSearch ? searchResults : initOptions)?.length,
    optionsLength: allOptions?.length,
    filterResults,
    searchRef,
    renderList,
    fetchMoreResults,
    needInfiniteScroll,
  };
};
