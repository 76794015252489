export const publicLinks = translations => [
  {
    path: '/home',
    label: translations.dashboard,
  },
  {
    path: '/courses',
    label: translations.courses,
  },
  {
    path: '/products',
    label: translations.products,
  },
  {
    path: '/get-started',
    label: translations.getStarted,
  },
];

export const privateLinks = translations => ({
  left: [
    {
      path: '/dashboard',
      label: translations.dashboard,
    },
    {
      path: '/courses',
      label: translations.courses,
    },
    // {
    //   path: '/products',
    //   label: translations.products,
    // },
  ],
  right: [
    {
      path: '/my-courses',
      label: translations.myCourses,
    },
  ],
});
