export const navBarContainer = (theme) => ({
  backgroundColor: theme.secondaryDark,
  color: theme.primary,
  height: 64,
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const appAvatarIcon = {
  marginRight: 32,
};

export const navContainer = {
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
};

export const sidesContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const linkStyles = (isRight) => (theme) => ({
  color: theme.secondaryLighter,
  fontSize: 12,
  border: 'none',
  marginLeft: 12,
  marginRight: isRight ? 24 : 12,
  textTransform: 'uppercase',
});

export const navSeparator = (theme) => ({
  height: 42,
  width: 1,
  backgroundColor: theme.whiteGrayDark,
  opacity: 0.2,
});

export const shopIconStyles = {
  marginLeft: 24,
  marginRight: 24,
};

export const profileIconStyles = {
  marginLeft: 16,
  marginRight: 8,
};

export const profileDropdownBtn = {
  height: 42,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export const shopDropdownBtn = (theme) => ({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .icn-wrap': {
    position: 'relative',
    '& .icn-badge': {
      position: 'absolute',
      top: '-8px',
      right: '16px',
      width: '13px',
      height: '13px',
      borderRadius: '13px',
      color: theme.white,
      fontSize: '9px',
      fontWeight: '600',
      letterSpacing: '-0.11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '1',
      backgroundColor: theme.primary,
    },
  },
});

export const viewProfileDropdown = {
  minWidth: 260,
};

export const viewProfileStyle = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 32px',
  backgroundColor: theme.secondaryVeryLighter,
  '& .wrapper': {
    textAlign: 'left',
    paddingRight: 16,
  },
  '& .swap-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      color: theme.gray,
      cursor: 'pointer',
      '&:hover': {
        color: theme.primaryLight,
      },
    },
  },
  '& .title': {
    color: theme.gray,
    marginBottom: 4,
    lineHeight: 1.1,
  },
  '& .label': {
    color: theme.grayLight,
    fontSize: 13,
  },
  '& .fa-chevron-right': {
    color: theme.grayLight,
    fontSize: 18,
    transition: 'color .3s linear',
  },
  '&:hover': {
    '& .fa-chevron-right': {
      color: theme.gray,
    },
  },
});

export const signOutStyle = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 32px',
  borderTop: `1px solid ${theme.borderLight}`,
  '& .icon': {
    marginRight: 8,
  },
  '& .title': {
    fontWeight: 300,
    color: theme.gray,
    transition: 'color .3s linear',
  },
  '&:hover': {
    '& .title': {
      color: theme.primary,
    },
  },
});

export const viewShopDropdown = (theme) => ({
  minWidth: '356px',
  '& .title': {
    margin: '16px 32px 24px',
    paddingBottom: '16px',
    fontSize: '13px',
    letterSpacing: '0.2px',
    color: theme.grayDark,
    fontWeight: '500',
    borderBottom: `1px solid ${theme.whiteGray}`,
  },
  '& .body': {
    '& .body-head': {
      padding: '0 32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .body-label': {
        fontSize: '12px',
        letterSpacing: '0.2px',
        fontWeight: '600',
        color: theme.grayDark,
        textTransform: 'uppercase',
      },
    },
    '& .items-wrap': {
      '& .shop-item': {
        padding: '16px 32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .shop-item-left': {
          display: 'flex',
          alignItems: 'center',
          '& .shop-item-image': {
            width: '48px',
            height: '40px',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginRight: '8px',
          },
          '& .shop-item-name': {
            fontSize: '13px',
            letterSpacing: '0.2px',
            fontWeight: '500',
            color: theme.grayDark,
            '& > span': {
              fontSize: '11px',
              fontWeight: '300',
              color: theme.grayLight,
            },
          },
        },
        '& .shop-item-right': {
          textAlign: 'right',
          '& .shop-item-price': {
            fontSize: '14px',
            fontWeight: '500',
            color: theme.grayDark,
          },
          '& .shop-item-fee': {
            fontSize: '12px',
            letterSpacing: '0.5px',
            color: '$label-gray',
          },
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.whiteGrayLight,
        },
      },
    },
  },
  '& .total-wrap': {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    padding: '0 32px',
    marginBottom: '24px',
    '& .total-label': {
      fontSize: '13px',
      letterSpacing: '0.5px',
      fontWeight: '500',
      color: theme.grayDark,
      marginRight: '8px',
    },
    '& .total-amount': {
      fontSize: '18px',
      fontWeight: '600',
      letterSpacing: '-0.53px',
      color: theme.grayDark,
    },
  },
  '& .shop-btn': {
    margin: '0 32px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.grayLighter}`,
    borderRadius: '2px',
    padding: '16px 8px',
    cursor: 'pointer',
    '& .shop-btn-label': {
      fontSize: '13px',
      marginLeft: '8px',
      letterSpacing: '0.1px',
      color: theme.gray,
    },
    '&:hover': {
      transition: 'all 0.3s ease',
      backgroundColor: theme.primary,
      '& .shop-btn-label': {
        transition: 'all 0.3s ease',
        color: theme.white,
      },
    },
  },
});

export const accountDropdownStyle = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 9999,
};

export const accountDropdownInner = (theme) => ({
  minWidth: '250px',
  '& .account-list-scroll-wrap': {
    maxHeight: 300,
    overflowY: 'auto',
  },
  '& .title': {
    textTransform: 'uppercase',
    marginTop: 16,
    paddingBottom: '16px',
    fontSize: '12px',
    letterSpacing: '0.2px',
    color: theme.grayDark,
    fontWeight: '500',
    borderBottom: `1px solid ${theme.whiteGray}`,
  },
});

export const accountDropdownBtn = (theme) => ({
  padding: '6px 16px 12px 16px',
  borderRadius: 3,
  backgroundColor: theme.primary,
  color: theme.white,
  cursor: 'pointer',
  textAlign: 'left',
  '& .icon-wrap': {
    textAlign: 'center',
  },
  '& .label': {
    marginBottom: 6,
    paddingBottom: 4,
    fontSize: 14,
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.white}`,
  },
  '& .box-inner': {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    '& .id': {
      marginBottom: 4,
      fontWeight: 600,
    },
  },
});

export const accountsList = (theme) => ({
  position: 'relative',
  '& .header': {
    borderBottom: `1px solid ${theme.whiteGray}`,
    paddingBottom: 12,
    paddingTop: 6,
    '& .back-button': {
      display: 'flex',
      alignItems: 'center',
      color: theme.gray,
      marginBottom: 8,
      '&:hover': {
        color: theme.primaryLight,
      },
    },
    '& .nickname-search': {
      '& .search-icon > i': {
        fontSize: 18,
      },
      '& .close-icon > i': {
        fontSize: 18,
      },
      '& .search-input': {
        fontSize: 12,
        lineHeight: 1.2,
        backgroundColor: 'white',
        border: `1px solid ${theme.whiteGray}`,
      },
    },
  },
  '& .body': {
    maxHeight: 300,
    overflowY: 'auto',
  },
});

export const accountLoaderWrap = (loading) => (theme) => ({
  background: theme.whiteGrayLighter,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999,
  display: loading ? 'flex' : 'none',
});

export const accountChangeLoader = {
  width: 24,
  height: 24,
};

export const accountDropdownItem = (active) => (theme) => ({
  padding: '16px 32px',
  cursor: 'pointer',
  textAlign: 'left',
  backgroundColor: active ? theme.primary : theme.white,
  color: active ? theme.white : theme.gray,
  borderBottom: `1px solid ${theme.whiteGray}`,
  '& .item-id': {
    fontWeight: 300,
  },
  '& .item-name': {
    fontSize: 14,
  },
  '&:nth-of-type(even)': {
    color: active ? theme.white : theme.grayLight,
  },
  '&:hover': {
    backgroundColor: active ? theme.primaryDark : theme.secondaryVeryLighter,
  },
});
