import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import { Button } from '../Button';
import { useForm } from './useForm';
import { formContainer, formFieldsContainer } from './styles';

const Form = forwardRef((props, ref) => {
  const { className, renderSubmit, children, submitButton = {} } = props;

  const { loading, handleSubmit, newChildren, formOptions, reset } = useForm(props);

  const { resetKey, resetForm, reloadForm } = reset;

  useImperativeHandle(ref, () => ({
    ...formOptions,
    children,
    reset: resetForm,
    reload: reloadForm,
  }));

  const SubmitButton = ({ secondary, children: submitChilds, ...rest }) => (
    <Button secondary={secondary} large onClick={handleSubmit} loading={loading} {...rest}>
      {submitChilds}
    </Button>
  );

  const CancelButton = ({ secondary, children: cancelChilds, ...rest }) => (
    <Button secondary={!secondary} onClick={resetForm} disabled={loading} {...rest}>
      {cancelChilds || 'Cancel'}
    </Button>
  );

  const SubmitBar = renderSubmit;

  const onKey = (el) => {
    el.keyCode === 13 && handleSubmit();
  };

  return (
    <div key={resetKey} {...(isString(className) && { className })} css={[formContainer, className]}>
      <div onKeyDown={onKey} role="presentation" css={formFieldsContainer}>
        {newChildren}
      </div>

      {isFunction(renderSubmit) ? (
        <SubmitBar
          submitButton={SubmitButton}
          cancelButton={CancelButton}
          options={formOptions}
          onSubmit={handleSubmit}
        />
      ) : (
        <SubmitButton {...submitButton}>{submitButton.children || 'Submit'}</SubmitButton>
      )}
    </div>
  );
});

Form.propTypes = {
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loaderclassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitButton: PropTypes.object,
  renderSubmit: PropTypes.func,
};

export default Form;
