import { Link } from 'react-router-dom';
import { Row, Col } from '../Grid';
import logo from '../../../assets/images/logo.svg';
import NavLinks from './NavLinks';
// import AccountDropdown from './AccountDropdown';
import { useAuth } from '../Authenticate';
import { navBarContainer, appAvatarIcon } from './styles';

const NavBar = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <nav css={navBarContainer}>
        <Row container align="center" noWrap padding={8}>
          <Col lg="auto">
            <Link to={isAuthenticated ? '/dashboard' : '/'}>
              <img src={logo} css={appAvatarIcon} alt="Logo" />
            </Link>
          </Col>
          <Col lg>
            <NavLinks />
          </Col>
        </Row>
      </nav>
      {/* {isAuthenticated && <AccountDropdown />} */}
    </>
  );
};

export default NavBar;
