import { useTranslations } from '../Theme';
import Dropdown from '../Dropdown';
import { dropdownStyles, dropdownOptionsStyles, dropdownValueStyles } from './styles';

const LanguageDropdown = () => {
  const { setTranslations, language, languages } = useTranslations();

  const languagesOptions = languages?.map((el) => ({
    ...el,
    render: (option) => (
      <div css={dropdownOptionsStyles}>
        <h4>{option.name}</h4>
      </div>
    ),
  }));

  return (
    <Dropdown
      onTop
      options={languagesOptions}
      renderSelectedValue={(selected) => (
        <div css={dropdownValueStyles}>
          <h4>{selected.name}</h4>
        </div>
      )}
      value={languages?.find((el) => el.code === language)}
      onChange={({ code }) => setTranslations(code)}
      noClear
      displayKey="name"
      uniqueKey="code"
      css={dropdownStyles}
      rightIcon={{ color: 'white' }}
    />
  );
};

export default LanguageDropdown;
