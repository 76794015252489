export const wrapper = {
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const headerWrap = (theme) => ({
  backgroundColor: theme.secondaryDark,
  marginBottom: 50,
});

export const heading = (theme) => ({
  paddingTop: 75,
  paddingBottom: 75,
  color: theme.white,
  fontSize: 48,
  fontWeight: 500,
});

export const tabContainer = {
  padding: 40,
};

export const skeletonStyle = {
  marginBottom: 40,
};
