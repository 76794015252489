import { useEffect, useState } from 'react';
import { wrapper } from './styles';

export let showLoader = () => {};

const PageLoader = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    showLoader = setVisible;
  }, []);

  return (
    <div css={wrapper(visible)}>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default PageLoader;
