import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash-es';
import hash from 'object-hash';
import ReactPlayer from 'react-player/vimeo';
import { Row, Col, Button, Icon, useAuth, showApiError } from '../../../UI';
import { useTranslations } from '../../../UI/Theme';
import { wrapper, heading, listWrapper, playerWrap, pdfIcon, videoItemWrap, embedResponsive } from './styles';
import Quiz from '../Quiz';
import PdfModal from '../../../Shared/PdfModal';
import { ReactComponent as PlayIcn } from '../../../../assets/images/icn-play.svg';
import { ReactComponent as QuizIcn } from '../../../../assets/images/icn-quiz.svg';
import { ReactComponent as LockIcn } from '../../../../assets/images/icn-lock.svg';
import { hourMinutesFormat } from '../../../../utils';
import { getLessonByStepId } from '../../../../services/lessons';
import { getQuizByStepId } from '../../../../services/quizzes';
import { updateAccountCourse } from '../../../../services/accountCourse';

const VideoPlayer = (props) => {
  const { course, isAvailable } = props;
  const { translations } = useTranslations();
  const { user } = useAuth();
  const history = useHistory();
  const [step, setStep] = useState(null);
  const [nextStep, setNextStep] = useState(false);
  const quizRef = useRef(null);
  const pdfModalRef = useRef(null);
  const nextStepValue = course?.steps[course?.steps?.findIndex((e) => e?.id === step?.stepId) + 1];

  useEffect(() => {
    if (course?.steps?.length && (isAvailable || course?.steps[0]?.isFree)) fetchLesson(course?.steps[0]);
    else setStep(0);
  }, [hash({ course }), isAvailable]);

  const fetchLesson = async (data) => {
    setStep(null);
    const [res, err] = await (data.type === 'quiz'
      ? getQuizByStepId({
          stepId: data.id,
          accountId: user?.academyAccount?.id,
          randomize: true,
        })
      : getLessonByStepId(data.id, user?.academyAccount?.id));
    if (err) return showApiError(err);
    setStep(res);
  };

  const changeStep = async (data) => {
    setNextStep(false);
    if (data.type === 'quiz') {
      history.push(`/course/${course?.id}/my-quiz`);
      quizRef.current && quizRef.current.resetQuiz();
    }
    fetchLesson(data);
  };

  const handleCourseProgressUpdate = async () => {
    if (!(isAvailable || nextStepValue?.isFree)) return nextStepValue ? setStep(0) : setNextStep({});

    setNextStep(nextStepValue ?? {});

    if (isAvailable) {
      const [, err] = await updateAccountCourse(course?.accountCourse?.id, {
        accountId: course?.accountCourse?.accountId,
        courseId: course?.id,
        completedLessonId: step.id,
      });
      if (err) return showApiError(err);
    }

    setTimeout(() => {
      nextStepValue && changeStep(nextStepValue);
    }, 3000);
  };

  const openPdfModal = async (source) => {
    pdfModalRef.current.setSource(source);
    pdfModalRef.current.setIsShow();
  };

  const renderListIcon = (type) => {
    const defaultIcon = <PlayIcn className="icon" />;

    switch (type) {
      case 'video':
        return defaultIcon;
      case 'text':
        return (
          <div className="icon">
            <Icon iconName="picture_as_pdf" material />
          </div>
        );
      case 'quiz':
        return <QuizIcn className="icon" />;
      default:
        return defaultIcon;
    }
  };

  const renderBlackWindow = (text, icon, btnText, event) => (
    <div css={embedResponsive}>
      <div className="embedResponsiveItem">
        <div className="noVideo">
          {isNull(step) ? (
            <svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          ) : (
            <>
              {icon}
              <h2>{text}</h2>
              <Button onClick={event}>{btnText}</Button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div css={wrapper}>
      <Row noWrap container>
        <Col sm={12}>
          <div css={heading}>
            <h1>{course?.name}</h1>
            <p>
              {course?.expert && `${course?.expert?.firstName} ${course?.expert?.lastName} | `}
              {course?.category && `${course?.category?.name} | `}
              {course?.steps && course?.steps.length && `${course?.steps.length} ${translations.lessons}, `}
              {hourMinutesFormat(course?.totalVideoDurationInSeconds)}
            </p>
          </div>
        </Col>
        {course?.steps &&
          !isEmpty(course?.steps) &&
          (isAvailable || step ? (
            <Col sm={8}>
              {step?.questions ? (
                <Quiz
                  quiz={step}
                  course={course}
                  ref={quizRef}
                  onSkip={() => nextStepValue && changeStep(nextStepValue)}
                  skipVisible={nextStepValue}
                />
              ) : (
                <>
                  {step?.sourceUrl && !nextStep ? (
                    <>
                      {step?.type === 'text' ? (
                        renderBlackWindow(
                          translations.openPff,
                          <div css={pdfIcon}>
                            <Icon iconName="picture_as_pdf" material />
                          </div>,
                          translations.open,
                          () => openPdfModal(step?.sourceUrl),
                        )
                      ) : (
                        <div css={playerWrap}>
                          <ReactPlayer
                            url={step?.sourceUrl}
                            controls
                            onEnded={handleCourseProgressUpdate}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div css={embedResponsive}>
                      <div className="embedResponsiveItem">
                        <div className="noVideo">
                          {nextStep ? (
                            <div className="upNext">
                              {!isEmpty(nextStep) ? (
                                <>
                                  <h1>{translations.upNext}</h1>
                                  <h4>{nextStep?.title}</h4>
                                  <svg className="spinner" viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                  </svg>
                                </>
                              ) : (
                                <h1>{translations.theEnd}</h1>
                              )}
                            </div>
                          ) : (
                            <svg className="spinner" viewBox="0 0 50 50">
                              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>
          ) : (
            <Col sm={8}>
              {renderBlackWindow(translations.UpgradePackageText, <LockIcn />, translations.buyPackage, () => {
                const win = window.open(
                  `${applicationUrls.oneLife}/shop/package-details/${course?.catalogueItems[0]?.catalogueItemId}`,
                  '_blank',
                );
                win.focus();
              })}
            </Col>
          ))}
        <Col sm={4}>
          <div css={listWrapper}>
            {course?.steps.map((el, index) => (
              <div
                onClick={() => (isAvailable || el?.isFree ? changeStep(el) : setStep(0))}
                role="button"
                tabIndex="-1"
                key={el.id}
                css={videoItemWrap(el?.id === step?.stepId, isAvailable || el?.isFree)}>
                {renderListIcon(el.type)}
                <p>
                  {index + 1}. {el?.title}
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <PdfModal ref={pdfModalRef} />
    </div>
  );
};

VideoPlayer.propTypes = {
  course: PropTypes.object,
  isAvailable: PropTypes.bool,
};

export default VideoPlayer;
