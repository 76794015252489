export const getLanguage = (languages, defaultLanguage) => {
  let userLanguage = localStorage.getItem('language');

  if (!userLanguage) {
    const browserLanguage = window.navigator.languages
      ? window.navigator.languages[0]
      : window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    const languageExist = languages.find((el) => el.code === browserLanguage.split('-')[0]);
    userLanguage = languageExist ? languageExist?.code : defaultLanguage;
    localStorage.setItem('language', String(userLanguage));
  }

  return userLanguage;
};
