import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import BodyOverflow from '../BodyOverflow';
import { container, content } from './styles';

const Modal = (props) => {
  const { children, show = false, withoutClose, onClose, className } = props;

  const contentRef = useRef();

  const close = isFunction(onClose) ? onClose : () => {};

  useEffect(() => {
    if (withoutClose) return () => {};

    const handleKeyDown = (e) =>
      (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && isFunction(onClose) && onClose();

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    show ? document.body.style.setProperty('overflow', 'hidden') : document.body.style.removeProperty('overflow');
  }, [show]);

  const handleMouseDownOnFade = () => {
    if (withoutClose) return;
    contentRef.current = true;
  };

  const handleClick = () => {
    if (withoutClose) return;
    if (contentRef.current) contentRef.current = false;
    else close();
  };

  return (
    show && (
      <BodyOverflow fullScreen zIndex={5000}>
        <div role="presentation" {...(isString(className) && { className })} css={container} onClick={handleClick}>
          <section role="presentation" css={content(props)} onMouseDown={handleMouseDownOnFade}>
            {children}
          </section>
        </div>
      </BodyOverflow>
    )
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool,
  withoutClose: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Modal;
