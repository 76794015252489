import { keyframes } from '@emotion/react';

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

export const wrapper = (visible) => ({
  visibility: visible ? 'visible' : 'hidden',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 998,
  '& .spinner': {
    animation: `${rotate} 2s linear infinite`,
    marginLeft: 'calc(100vw - 100%)',
    width: '50px',
    height: '50px',
    '& .path': {
      stroke: '#e72d18',
      strokeLinecap: 'round',
      animation: `${dash} 1.5s ease-in-out infinite`,
    },
  },
});
