import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash-es';
import { showApiError, Skeleton, Icon, useAuth } from '../../../UI';
import PdfModal from '../../../Shared/PdfModal';
import { getCourseMaterial } from '../../../../services/courses';
import { resourceList, resourceLink, noData, skeletonStyle } from './styles';

const MaterialsList = (props) => {
  const { course } = props;
  const [materials, setMaterials] = useState(null);
  const pdfModalRef = useRef(null);
  const {
    user: {
      academyAccount: { id: accountId },
    },
  } = useAuth();

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    const [res, err] = await getCourseMaterial(course?.id, {
      accountId,
    });
    if (err) return showApiError(err);

    setMaterials(res?.data ?? []);
  };

  const openPdfModal = async (source) => {
    pdfModalRef.current.setSource(source);
    pdfModalRef.current.setIsShow();
  };

  return !isNull(materials) ? (
    <>
      {materials.length ? (
        <div css={resourceList}>
          {materials?.map((el, index) => (
            <div key={index}>
              {el?.mimeType === 'application/pdf' ? (
                <div css={resourceLink} role="presentation" tabIndex={-1} onClick={() => openPdfModal(el?.sourceUrl)}>
                  <p>
                    <Icon iconName="picture_as_pdf" material />
                    <span>{el?.name}</span>
                  </p>
                </div>
              ) : (
                <a css={resourceLink} rel="noreferrer" target="_blank" type="button" href={el?.sourceUrl} download>
                  <Icon iconName="picture_as_pdf" material />
                  <span>{el?.name}</span>
                </a>
              )}
            </div>
          ))}
          <PdfModal ref={pdfModalRef} />
        </div>
      ) : (
        <div css={noData}>
          <h2>There are materials for this course.</h2>
        </div>
      )}
    </>
  ) : (
    <>
      {Array(5)
        .fill()
        .map((el, index) => (
          <div key={index} css={skeletonStyle}>
            <Skeleton height="24px" width="100%" />
          </div>
        ))}
    </>
  );
};
MaterialsList.propTypes = {
  course: PropTypes.object,
};
export default MaterialsList;
