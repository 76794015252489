import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  downloadBtn: (theme) => ({
    border: `2px solid ${theme.highlightsBlue}`,
    color: theme.highlightsBlue,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: theme.secondaryLighter05,
    },
  }),
});

export const questionMainContainer = {
  backgroundColor: '#fff',
  width: '100%',
  height: 440,
  overflowY: 'auto',
  padding: 32,
};

export const tooltipsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 32,
};

export const questionIndex = (theme) => ({
  color: theme.grayLight,
});

export const skipQuizButton = (big) => (theme) => ({
  color: theme.highlightsBlue,
  textDecoration: 'underline',
  fontSize: big ? 16 : 13,
});

export const questionTitle = (theme) => ({
  color: theme.textBlueDark,
});

export const questionAnswersContainer = {
  padding: 0,
};

export const questionAnswer = (theme) => ({
  color: theme.white,
  backgroundColor: theme.highlightsBlue,
  padding: '18px 50px',
  fontSize: '1.2em',
  display: 'block',
  cursor: 'pointer',
  position: 'relative',
  marginBottom: 24,
  textAlign: 'center',

  '&:hover': {
    '& > div': {
      backgroundColor: theme.white,
    },
  },
});

export const questionAnswerRadio = (active) => (theme) => ({
  width: 20,
  height: 20,
  border: `3px solid ${theme.white}`,
  borderRadius: '50%',
  position: 'absolute',
  left: 18,
  top: 'cacl(50% - 10px)',
  backgroundColor: active ? theme.white : 'transparent',
});

export const questionFooterContainer = (finalPreview) => (theme) => ({
  backgroundColor: theme.secondaryVeryLighter,
  padding: 32,
  display: 'flex',
  justifyContent: 'flex-end',
  '& .downloadBtnWrap': {
    display: finalPreview ? 'block' : 'none',
  },
});

export const questionNextButton = (disabled) => (theme) => ({
  opacity: disabled ? 0.5 : 1,
  border: `2px solid ${theme.highlightsBlue}`,
  color: theme.highlightsBlue,
  fontWeight: 600,
  textTransform: 'uppercase',
  padding: '12px 24px',
  '&:hover': {
    backgroundColor: theme.secondaryLighter05,
  },
});

export const answerPreviewBox = {
  marginTop: 32,
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '& p': {
      marginRight: 8,
      fontSize: 16,
    },
    '& svg': {
      width: 30,
      height: 30,
      marginRight: 8,
    },
  },
};

export const finalPreviewBox = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    width: 80,
    height: 80,
    marginBottom: 24,
  },
  '& h1': {
    marginBottom: 8,
  },
  '& p': {
    fontSize: 16,
    fontWeight: 300,
    marginBottom: 16,
  },
};
