const getTooltipOpenClass = (align, position) => ({
  visibility: 'visible',
  transform: align
    ? 'translate(0, 0)'
    : position === 'top' || position === 'bottom'
    ? 'translate(-50%, 0)'
    : 'translate(0, -50%)',
});

export const tooltipContainer = ({ position, align, onClick }, isOpen) => [
  {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    outline: 0,
  },
  !onClick && {
    '&:hover > *:first-of-type': getTooltipOpenClass(align, position),
  },
  isOpen && {
    '& > *:first-of-type': getTooltipOpenClass(align, position),
  },
];

export const popupTooltip =
  ({ position, align, singleLine, backgroundColor, color, border, borderRadius }) =>
  (theme) =>
    [
      {
        overflow: 'hidden',
        position: 'absolute',
        outline: 0,
        padding: 5,
        backgroundColor: backgroundColor ? theme[backgroundColor] || backgroundColor : theme.white,
        color: color ? theme[color] || color : theme.textLightPrimary,
        whiteSpace: singleLine ? 'nowrap' : 'normal',
        visibility: 'hidden',
        transition: 'all .3s linear',
        boxShadow: '0 0 6px 0 rgba(86, 89, 92, 0.31)',
        border: border || 'none',
        borderRadius: borderRadius || 3,
        zIndex: 1,
      },
      position === 'bottom' && {
        top: 'calc(100% + 8px)',
        left: align ? (align === 'left' ? 0 : 'auto') : '50%',
        right: align && align === 'right' ? 0 : 'auto',
        transform: align ? 'translate(0, 8px)' : 'translate(-50%, 8px)',
      },
      position === 'top' && {
        bottom: 'calc(100% + 8px)',
        left: align ? (align === 'left' ? 0 : 'auto') : '50%',
        right: align && align === 'right' ? 0 : 'auto',
        transform: align ? 'translate(0, -8px)' : 'translate(-50%, -8px)',
      },
      position === 'left' && {
        right: 'calc(100% + 8px)',
        top: align ? (align === 'top' ? 0 : 'auto') : '50%',
        bottom: align && align === 'bottom' ? 0 : 'auto',
        transform: align ? 'translate(-8px, 0)' : 'translate(-8px, -50%)',
      },
      // Default position
      position === 'right' && {
        left: 'calc(100% + 8px)',
        top: align ? (align === 'top' ? 0 : 'auto') : '50%',
        bottom: align && align === 'bottom' ? 0 : 'auto',
        transform: align ? 'translate(8px, 0)' : 'translate(8px, -50%)',
      },
    ];
