import PropTypes from 'prop-types';
import moment from 'moment';
import { Page, Text, Image, View, Font, Document } from '@react-pdf/renderer';
import styles from './styles';
import certificateTemplate from '../../../../assets/certificates/certificate-template.jpg';
import certificateTemplateLogo from '../../../../assets/certificates/certificate-template-logo.png';
import veskaSignature from '../../../../assets/certificates/veska-signature.png';

Font.register({
  family: 'Public Sans-Bold',
  src: 'https://fonts.gstatic.com/s/publicsans/v4/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z6JxAcufVotfzbj.woff',
  fontStyle: 'normal',
  fontWeight: 700,
});

Font.register({
  family: 'Public Sans-Regular',
  src: 'https://fonts.gstatic.com/s/publicsans/v4/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpmJxAcufVotfzbj.woff',
  fontStyle: 'normal',
  fontWeight: 400,
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Certificate = ({ userName, courseName }) => (
  <Document>
    <Page size="A4" orientation="landscape">
      <View style={styles.wrap}>
        <Image src={certificateTemplate} allowDangerousPaths style={styles.bgImage} />
        <View style={styles.row}>
          <View style={styles.leftCol}>
            <Image src={certificateTemplateLogo} allowDangerousPaths style={styles.logo} />
            <View>
              <Text style={styles.name}>{userName}</Text>
              <View style={styles.leftColSeparator} />
              <Text style={styles.successText}>
                Has successfully completed with distinction OneAcademy&lsquo;s Course
              </Text>
              <Text style={styles.courseName}>{courseName}</Text>
            </View>
            <View>
              <Image src={veskaSignature} allowDangerousPaths style={styles.signature} />
              <Text style={styles.signatureName}>Veska Ignatov</Text>
              <Text style={styles.signaturePosition}>Founder &amp; CEO</Text>
            </View>
          </View>
          <View style={styles.rightCol}>
            <View style={styles.certificateTextWrap}>
              <Text style={styles.certificateText}>Certificate</Text>
              <Text style={styles.completionText}>of Completion</Text>
            </View>
            <View style={styles.dateWrap}>
              <Text style={styles.date}>{moment().format('MMMM DD, YYYY')} </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

Certificate.propTypes = {
  userName: PropTypes.any,
  courseName: PropTypes.any,
};

export default Certificate;
