import { useRef } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { rippleBaseStyles, animationOn, onFocus } from './styles';

const Ripple = (props) => {
  const rippleClassName = useRef();
  const onFocusClassName = useRef();

  const { children, className, backColor, focusColor, flat, guideId } = props;
  const guideIdProp = guideId ? { 'data-guide-id': guideId } : {};

  const handleMouseDown = (e) => {
    e.preventDefault();
    const { width, height, left, top } = e.currentTarget.getBoundingClientRect();
    const size = Math.max(width, height);

    const x = flat ? 0 : parseInt(e.clientX - left - size / 2, 10);
    const y = flat ? 0 : parseInt(e.clientY - top - size / 2, 10);
    rippleClassName.current = animationOn(x, y, size);
  };

  const handleFocus = (e) => {
    e.preventDefault();
    onFocusClassName.current = onFocus(focusColor);
  };

  const handleBlur = (e) => {
    e.preventDefault();
  };

  return (
    <div
      role="presentation"
      onMouseDown={handleMouseDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...(isString(className) && { className })}
      css={[rippleBaseStyles(backColor, focusColor), className]}
      {...guideIdProp}
    >
      {children}
    </div>
  );
};

Ripple.propTypes = {
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backColor: PropTypes.string,
  focusColor: PropTypes.string,
  flat: PropTypes.bool,
  guideId: PropTypes.string,
};

export default Ripple;
