import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isNull, isEmpty } from 'lodash-es';
import { Skeleton, showApiError } from '../../../UI';
import { useTranslations } from '../../../UI/Theme';
import { useAuth } from '../../../UI/Authenticate';
import { getAllFriends } from '../../../../services/accounts';
import userImg from '../../../../assets/images/user-img.png';
import FriendsRankListImg from '../../../../assets/images/friend-rank-list-img.png';
import { wrapper, header, body, listItemStyle, footer, noData } from './styles';

const FriendsRankList = () => {
  const { translations } = useTranslations();
  const [friends, setFriends] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();

  useEffect(() => {
    fetchMyFriends();
  }, [currentAccount?.id]);

  const fetchMyFriends = async () => {
    const [res, err] = await getAllFriends(accountId, {
      includeDetails: true,
      pageIndex: 0,
      pageSize: 5,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setFriends(res?.data ?? []);
  };

  return (
    <div css={wrapper}>
      <div css={header}>
        <img src={FriendsRankListImg} className="avatar" alt="course-card-logo" />
        <h6 className="title">{translations.friendsRankList}</h6>
      </div>
      <div css={body}>
        {!isNull(friends) ? (
          !isEmpty(friends) ? (
            friends.map((el, i) => (
              <div css={listItemStyle} key={el.externalAccountId}>
                <div className="item-left">
                  <span>{i + 1}</span>
                  <img src={el?.pictureUrl ?? userImg} className="avatar" alt="course-card-logo" />
                  <div>
                    <p className="name">
                      {el?.firstName} {el?.lastName}
                    </p>
                    <small>{el?.nickName}</small>
                  </div>
                </div>
                <div className="item-right">
                  <span>{el?.accomplishment?.points ?? 0}</span>
                </div>
              </div>
            ))
          ) : (
            <div css={noData}>No friends found!</div>
          )
        ) : (
          Array(5)
            .fill()
            .map((el, i) => (
              <div css={listItemStyle} key={i}>
                <div className="item-skeleton">
                  <span>{i + 1}</span>
                  <div className="skeleton-avatar">
                    <Skeleton circle height="32px" width="32px" />
                  </div>
                  <Skeleton height="14px" width="100%" />
                </div>
              </div>
            ))
        )}
      </div>
      <div css={footer}>
        {!isEmpty(friends) && (
          <Link to="/friends-rank-list" className="link">
            {translations.seeAll}
          </Link>
        )}
      </div>
    </div>
  );
};

export default FriendsRankList;
