import { Route } from 'react-router-dom';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

export default [
  {
    exact: true,
    path: '/login',
    component: Login,
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
  },
].map((route) => <Route key={route.path} {...route} />);
