import Recommended from '../../../components/Shared/Recommended';
import MyCourses from '../../../components/Pages/MyCourses';

const AllCourses = () => (
  <>
    <MyCourses />
    <Recommended />
  </>
);

AllCourses.propTypes = {};

export default AllCourses;
