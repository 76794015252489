import { loginWithPassword } from '@oneecosystem/authenticate';
import { useTranslations } from '../../../components/UI/Theme';
import { Paper, Form, FormInput, validate, showError } from '../../../components/UI';
import {
  container,
  formTitleContainer,
  loginTitle,
  title,
  detailStylesLogin,
  loginSubtitle,
  forgotStyles,
} from './styles';
import { isArray, isPlainObject } from 'lodash-es';
import { Link } from 'react-router-dom';

const Login = () => {
  const { translations } = useTranslations();

  const onSubmit = async values => {
    const res = await loginWithPassword(values);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message.split(' Http')[0]) ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map(el => el[0]));
    //     Error from sts identity                  Default behavior
    err && (isArray(err) ? err.forEach(showError) : showError(err));
  };

  return (
    <div css={container}>
      <div css={formTitleContainer}>
        <Paper
          header={
            <div css={loginTitle}>
              <h1 css={title}>{translations.signIn}</h1>
              <div css={detailStylesLogin}>
                <p css={loginSubtitle}>
                  {translations.signInToOneAcademy}
                  <span css={forgotStyles}><Link to="/forgot-password">{`${translations.forgotPassword}?`}</Link></span>
                </p>
              </div>
            </div>
          }>
          <Form
            onSubmit={onSubmit}
            submitButton={{ children: translations.signIn }}
            preFilledValues={{
              email: '',
              password: '',
            }}>
            <FormInput
              id="email"
              label={translations.emailAddress}
              placeholder={translations.emailAddress}
              required
            />
            <FormInput
              id="password"
              type="password"
              label={translations.password}
              placeholder={translations.password}
              required
              validate={val => validate(val, ['required', 'password'])}
            />
          </Form>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
