import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import Icon from '../Icon';
import { checkButtonContainer, iconContainer } from './styles';

const checkboxFilter = 'invert(63%) sepia(89%) saturate(2820%) hue-rotate(115deg) brightness(91%) contrast(101%)';

const Checkbox = ({ checked, onChange, radio, children, className }) => {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(check);
  }, [check]);

  const handleChange = () => {
    setCheck((prev) => {
      isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };

  const iconName = radio
    ? check
      ? 'radio_button_checked'
      : 'radio_button_unchecked'
    : check
    ? 'check_box'
    : 'check_box_outline_blank';

  return (
    <div
      role="button"
      tabIndex={0}
      {...(isString(className) && { className })}
      css={checkButtonContainer(className)}
      onClick={handleChange}>
      <Icon
        iconName={iconName}
        material
        outline
        twoTone={!radio && check}
        color={radio && check ? 'primaryLight' : 'grayLight'}
        filter={!radio && check && checkboxFilter}
        css={iconContainer}
      />
      {children}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Checkbox;
