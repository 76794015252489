import awardImg from '../../../assets/images/award.png';

export const wrapper = {
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const headerWrap = (theme) => ({
  backgroundColor: theme.secondaryDark,
  marginBottom: 50,
});

export const heading = (theme) => ({
  paddingTop: 75,
  paddingBottom: 75,
  color: theme.white,
  fontSize: 48,
  fontWeight: 500,
  textTransform: 'capitalize',
});

export const tableWrap = (theme) => ({
  '& .tr': {
    borderBottom: `1px solid ${theme.secondaryLighter}`,
    paddingTop: 12,
    paddingBottom: 12,
    '& .th': {
      color: theme.grayLighter,
      fontSize: 14,
    },
  },
  '& .cell-row': {
    borderBottom: `1px solid ${theme.secondaryLighter}`,
    paddingTop: 12,
    paddingBottom: 12,
    '& .info-cell': {
      display: 'flex',
      alignItems: 'center',
      '& .avatar': {
        width: 46,
        height: 48,
        borderRadius: 48,
        marginRight: 12,
      },
      '& h3': {
        color: theme.secondaryDark,
        fontSize: 21,
      },
    },
    '& .points-cell': {
      '& p': {
        fontSize: 14,
        color: theme.secondaryLight,
      },
    },
    '& .badge-cell': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: -4,
      marginRight: -4,
      '& .badge-wrap': {
        marginLeft: 4,
        marginRight: 4,
      },
    },
    '& .packages-cell': {
      display: 'flex',
      alignItems: 'center',
      '& .package-wrap': {
        marginLeft: 8,
        marginRight: 8,
      },
    },
  },
});

export const numberWrap = (isFirst) => (theme) => ({
  width: 28,
  height: 28,
  backgroundImage: `url(${isFirst ? awardImg : ''})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginRight: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .number': {
    fontSize: 12,
    marginBottom: isFirst ? 8 : 0,
    color: theme.secondaryLight,
  },
});

export const badgeStyle = (image) => (theme) => ({
  width: 32,
  height: 32,
  borderRadius: 32,
  backgroundColor: theme.whiteGray,
  backgroundImage: `url(${image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const packageCircle = (image) => (theme) => ({
  width: 56,
  height: 56,
  borderRadius: 56,
  backgroundColor: image ? '#ffffff00' : theme.whiteGray,
  backgroundImage: `url(${image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const footerWrap = {
  marginBottom: 50,
  marginTop: 50,
  display: 'flex',
  justifyContent: 'center',
};

export const noData = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.white,
  height: 200,
});

export const packagesTooltipWrap = (widthIndex) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: widthIndex * 88,
  marginLeft: -4,
  marginRight: -4,
  '& .package-tooltip-wrap': {
    width: 80,
    marginLeft: 4,
    marginRight: 4,
    padding: 4,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export const badgesTooltipWrap = (widthIndex) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: widthIndex * 68,
  marginLeft: -4,
  marginRight: -4,
  '& .badges-tooltip-wrap': {
    width: 60,
    marginLeft: 4,
    marginRight: 4,
    padding: 4,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
