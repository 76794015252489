import React, { useState, useLayoutEffect, useContext, useRef, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import translations from './translations';
import { showApiError } from '../Notifications/Store';
import { getLanguage } from './utils';
import { getAllLanguages } from '../../../services/languages';

const DEFAULT_LANG = 'en';

const TranslationsContext = React.createContext({
  translations: {},
  setTranslations: () => {},
  language: null,
  languages: null,
});

export const useTranslations = () => useContext(TranslationsContext);

export const TranslationsProvider = ({ children }) => {
  const [language, setLanguage] = useState();
  const languagesRef = useRef(null);

  useLayoutEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    const [res, err] = await getAllLanguages();
    if (err) return showApiError(err);

    languagesRef.current = res?.data;

    const newLanguage = getLanguage(res?.data, DEFAULT_LANG);
    axios.defaults.headers['Accept-Language'] = newLanguage;

    setLanguage(newLanguage);
  };

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', String(lang));
    axios.defaults.headers['Accept-Language'] = String(lang);

    setLanguage(lang);
  };

  const contextChilds = Children.map(children, (el) => cloneElement(el, { key: language }));

  return (
    <TranslationsContext.Provider
      value={{
        translations: translations[language] ?? translations[DEFAULT_LANG],
        setTranslations: handleLanguageChange,
        language,
        languages: languagesRef.current,
      }}
    >
      {language && contextChilds}
    </TranslationsContext.Provider>
  );
};

TranslationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
