import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash-es';
import { Row, Col, Skeleton, showApiError } from '../../UI';
import { useAuth } from '../../UI/Authenticate';
import { useTranslations } from '../../UI/Theme';
import LessonCard from '../LessonCard';
import { getAllCourses } from '../../../services/courses';
import { wrapper, heading, headingLink } from './styles';

const Recommended = (props) => {
  const { categoryId = null } = props;
  const { translations } = useTranslations();
  const [courses, setCourses] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      myPackages,
      currentAccount,
    },
  } = useAuth();

  useEffect(() => {
    fetchCourses();
  }, [categoryId, currentAccount?.id]);

  const fetchCourses = async () => {
    const [res, err] = await getAllCourses({
      notAccountId: accountId,
      categoryId,
      includeDetails: true,
      randomize: true,
    });
    if (err) return showApiError(err);

    const filteredCourses = res?.data?.filter(
      (item) =>
        !myPackages.some((elem) =>
          item.catalogueItems.map((el) => el?.catalogueItemId).includes(elem?.catalogueItemId),
        ),
    );

    setCourses(filteredCourses);
  };

  return (
    <div css={wrapper}>
      <Row horizontalGap={12} noWrap container>
        <Col xs={12}>
          <div css={heading}>
            <h1>{translations.recommendedToYou}</h1>
            <Link to="/courses" css={headingLink}>
              {translations.seeAll}
            </Link>
          </div>
        </Col>
      </Row>
      {!isNull(courses) ? (
        <Row horizontalGap={12} noWrap container>
          <Col sm={9} className="bottomBuffer">
            {courses[0] && <LessonCard lesson={courses[0]} big />}
          </Col>
          <Col sm={3} className="bottomBuffer">
            {courses[1] && <LessonCard lesson={courses[1]} />}
            <div className="bottomBuffer" />
            {courses[2] && <LessonCard lesson={courses[2]} />}
          </Col>
        </Row>
      ) : (
        <Row horizontalGap={12} noWrap container>
          <Col sm={9} className="bottomBuffer">
            <Skeleton height="670px" width="100%" />
          </Col>
          <Col sm={3} className="bottomBuffer">
            <Skeleton height="323px" width="100%" />
            <div className="bottomBuffer" />
            <Skeleton height="323px" width="100%" />
          </Col>
        </Row>
      )}
    </div>
  );
};

Recommended.propTypes = {
  categoryId: PropTypes.any,
};

export default Recommended;
