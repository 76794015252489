export const noData = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  minHeight: 340,
  backgroundColor: theme.white,
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 24,
  paddingBottom: 24,
  '& .no-data-inner': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& svg': {
      width: '100%',
      height: 'auto',
      maxWidth: 300,
      marginBottom: 24,
    },
    '& h3': {
      marginBottom: 16,
      textAlign: 'center',
    },
  },
});
