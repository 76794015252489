import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { baseIcon } from './styles';

const Icon = (props) => {
  const { onClick, style, material, iconName, className } = props;
  const classList = [isString(className) && className, !material && iconName].filter(Boolean).join(' ');

  return <i role="presentation" css={baseIcon(props)} className={classList} style={style} onClick={onClick} />;
};

Icon.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  material: PropTypes.bool,
  iconName: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Icon;
