import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isString } from 'lodash-es';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { tooltipContainer, popupTooltip } from './styles';

const Tooltip = (props) => {
  const { children, content, onClick, open, style, className } = props;
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    isOpen && setIsOpen(false);
  }, [location.pathname]);

  const handleClick = () => onClick && setIsOpen((prev) => !prev);

  const handleBlur = ({ currentTarget, relatedTarget }) => {
    onClick && !currentTarget.contains(relatedTarget) && setIsOpen(false);
  };

  return (
    <div
      tabIndex={-1}
      role="presentation"
      onClick={handleClick}
      onBlur={handleBlur}
      {...(isString(className) && { className })}
      css={tooltipContainer(props, isOpen)}
      style={style}>
      <div role="presentation" css={popupTooltip(props)} tabIndex={-5} onClick={(e) => e.stopPropagation()}>
        {isFunction(content) ? content({ close: () => setIsOpen(false) }) : content}
      </div>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Tooltip;
