import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash-es';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTheme } from '@emotion/react';
import Certificate from '../Certificate';
import { Row, Col, Button, showApiError, useTranslations, useAuth, Skeleton } from '../../../UI';
import { getAllQuizTakes } from '../../../../services/quizHistory';
import { ReactComponent as AccordionWrapIcn } from '../../../../assets/images/icn-accordion-arrow.svg';
import { ReactComponent as SuccessIcn } from '../../../../assets/images/checked.svg';
import { ReactComponent as FailIcn } from '../../../../assets/images/cancel.svg';

import {
  styles,
  quizResultsStyle,
  questionTitle,
  answerPreviewBox,
  collapseStyle,
  collapseRow,
  collapseHeader,
  collapseArrow,
  collapseContent,
  collapseBody,
  paginationStyle,
  noData,
  skeletonStyle,
} from './styles';

const PAGE_SIZE = 5;

const QuizResults = (props) => {
  const { course } = props;
  const [quizHistory, setQuizHistory] = useState(null);
  const [collapse, setCollapse] = useState(null);
  const theme = useTheme();
  const { translations } = useTranslations();
  const {
    user: {
      profile,
      academyAccount: { id: accountId },
    },
  } = useAuth();
  const pageIndexRef = useRef(0);

  const fullName = `${profile?.firstName} ${profile?.lastName}`;

  useEffect(() => {
    pageIndexRef.current = 0;
    fetchQuizHistory();
  }, [accountId]);

  const fetchQuizHistory = async (pageIndex) => {
    const [res, err] = await getAllQuizTakes({
      accountId,
      courseId: course?.id,
      pageSize: PAGE_SIZE,
      pageIndex: pageIndex ?? 0,
    });
    if (err) return showApiError(err);

    setQuizHistory((prev) => (pageIndex ? [...prev, ...res] : res ?? []));
  };

  const handleCollapse = (id) => {
    setCollapse((prev) => (prev === id ? null : id));
  };

  const handlePageChange = () => {
    pageIndexRef.current += 1;
    fetchQuizHistory(pageIndexRef.current);
  };

  return !isNull(quizHistory) ? (
    <>
      {quizHistory && quizHistory.length > 0 ? (
        <>
          {quizHistory?.map((el, index) => (
            <div css={collapseStyle} key={index}>
              <div css={collapseHeader(collapse === index)}>
                <Row horizontalGap={8} css={collapseRow}>
                  <Col sm>
                    <div className="section-wrap">
                      <h4 className="section-heading">{el?.step?.title}</h4>
                      <p>
                        <b>{translations.score} </b>
                        {el.scorePercentage}%
                      </p>
                    </div>
                  </Col>
                  <Col sm="auto">
                    <div
                      role="button"
                      tabIndex={0}
                      css={collapseArrow(collapse === index)}
                      onClick={() => handleCollapse(index)}>
                      <AccordionWrapIcn />
                    </div>
                  </Col>
                </Row>
              </div>
              <div css={collapseContent(collapse === index)}>
                <div css={collapseBody}>
                  <h2>{`${translations.youScoreIs} ${el.scorePercentage}%.`}</h2>
                  {el.scorePercentage > 70 && (
                    <div className="downloadBtnWrap">
                      <PDFDownloadLink
                        style={styles.downloadBtn(theme)}
                        document={<Certificate userName={fullName} courseName={course?.name} />}
                        fileName={`${course?.name} Certificate.pdf`}>
                        {({ loading }) =>
                          loading ? `${translations.loadingDocument}...` : translations.downloadCertificate
                        }
                      </PDFDownloadLink>
                    </div>
                  )}
                  {el?.questions?.map((question, questionIndex) => (
                    <div css={quizResultsStyle} key={questionIndex}>
                      <h5 css={questionTitle}>{translations.answerTo(question?.title)}</h5>
                      {question.answers.map((answer) => (
                        <div css={answerPreviewBox} key={answer.id}>
                          <div>
                            {answer.isCorrect ? (
                              <>
                                <p>{answer.text}</p>
                                <SuccessIcn /> <h5>{`${translations.correct}!`}</h5>
                              </>
                            ) : (
                              <>
                                <p>{answer.text}</p>
                                <FailIcn /> <h5>{`${translations.wrong}!`}</h5>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <div css={paginationStyle}>
            <Button onClick={handlePageChange}>{translations.loadMore}</Button>
          </div>
        </>
      ) : (
        <div css={noData}>
          <h2>{translations.noQuizResultMessage}</h2>
        </div>
      )}
    </>
  ) : (
    <>
      {Array(5)
        .fill()
        .map((el, index) => (
          <div key={index} css={skeletonStyle}>
            <Skeleton height="74px" width="100%" />
          </div>
        ))}
    </>
  );
};
QuizResults.propTypes = {
  course: PropTypes.object,
};
export default QuizResults;
