export const tabs = {
  width: '100%',
};

export const tabsListContainerWrapper = (showArrows) => [
  {
    position: 'relative',
    overflow: 'hidden',
  },
  showArrows && { paddingRight: 90 },
];

export const tabsListContainer = (isDragging) => (theme) =>
  [
    {
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
      width: '100%',
      backgroundColor: theme.white,
      position: 'relative',
      '&:after': {
        content: '""',
        width: '100%',
        height: 1,
        backgroundColor: theme.grayLightest,
        position: 'absolute',
        bottom: 0,
      },
      '& > *': {
        display: 'inline-block',
      },
    },
    isDragging && {
      '& *': {
        cursor: 'grabbing',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  ];

export const tabStyle = (active) => (theme) => ({
  cursor: 'pointer',
  padding: active ? '16px 40px' : '18px 40px',
  minWidth: 170,
  textAlign: 'center',
  fontSize: 12,
  lineHeight: '14px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'border 0.3s ease-in-out',
  color: active ? theme.primaryLight : theme.grayLighter,
  borderBottom: `${active ? 4 : 1}px solid ${active ? theme.primaryLight : theme.grayLightest}`,
});

const arrowStyle = (isDisabled) => (theme) => ({
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 26,
  color: isDisabled ? theme.textLightDisabled : theme.textLightPrimary,
  borderRadius: '50%',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
});

export const scrollLeftIcon = (isDisabled) => (theme) => ({
  position: 'absolute',
  top: 12,
  right: 44,
  ...arrowStyle(theme, isDisabled),
});

export const scrollRightIcon = (isDisabled) => (theme) => ({
  position: 'absolute',
  top: 12,
  right: 0,
  ...arrowStyle(theme, isDisabled),
});
