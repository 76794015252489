import queryString from 'query-string';
import { pick, isEmpty } from 'lodash-es';
import { getQuestionsModel } from './quizzes';

export const getQuizHistoryModel = (response) =>
  response &&
  response?.map((el) => ({
    ...el,
    questions: el?.questions && !isEmpty(el?.questions) ? getQuestionsModel(el?.questions) : [],
    step: el.step
      ? {
          ...el.step,
          ...(!isEmpty(el?.step?.translations) && {
            ...pick(el?.step?.translations[0], ['title']),
          }),
        }
      : null,
  }));

export const getAllQuizTakesParams = (options) =>
  queryString.stringify(
    {
      ...(options.accountId && { accountId: options.accountId }),
      ...(options.courseId && { courseId: options.courseId }),
      ...pick(options, ['pageIndex', 'pageSize']),
    },
    { arrayFormat: 'index' },
  );
