import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import Icon from '../Icon';
import useSearchBar from './useSearchBar';
import {
  searchBarContainer,
  searchBarInput,
  searchBarSearchIcon,
  searchBarDeleteIcon,
  searchBarHistoryContainer,
  searchBarHistoryItem,
} from './styles';

// This component is for searching based on previous searches that the user is done
// Into localStorage we safe the previously done searches and during typing we show suggestions to the user
// Those suggestions are called history and they are generated by useSearchBar hook

const SearchBar = (props) => {
  const {
    inputRef,
    placeholder,
    // maxHistory indicates how much options below the input you can see
    maxHistory = 7,
    value = '',
    storageKey,
    id = 'searchBar',
    onSubmit,
    className,
  } = props;

  const { input, container, isOpen, history } = useSearchBar(value, onSubmit, storageKey);

  const { onBlur, onSelect, onDelete } = container;

  const hasText = !!input.value.length;
  const hasMatches = !!history.length;

  return (
    <div
      css={searchBarContainer(isOpen, hasMatches)}
      {...(isString(className) && { className })}
      role="button"
      tabIndex="0"
      onBlur={onBlur}>
      <label htmlFor={id.toLowerCase()} css={searchBarSearchIcon} className="search-icon">
        <Icon material iconName="search" />
      </label>
      <input
        ref={inputRef}
        id={id.toLowerCase()}
        className="search-input"
        placeholder={placeholder}
        css={searchBarInput(hasText)}
        maxLength="2048"
        type="text"
        spellCheck="false"
        aria-autocomplete="both"
        aria-haspopup="false"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        {...input}
      />
      {hasText && (
        <div css={searchBarDeleteIcon} className="close-icon">
          <Icon material iconName="close" onClick={onDelete} />
        </div>
      )}
      {isOpen && !!history.length && (
        <div css={searchBarHistoryContainer(history.length, maxHistory)}>
          {history.map((el, i) => (
            <div
              key={`Suggestion${i}`}
              role="button"
              tabIndex="-2"
              css={searchBarHistoryItem}
              onClick={() => onSelect(el)}>
              {el}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  storageKey: PropTypes.string,
  maxHistory: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
};

export default SearchBar;
