import { useState, useEffect, useImperativeHandle, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isString, isFunction } from 'lodash-es';
import hash from 'object-hash';
import Input from '../Input';
import Dropdown from '../Dropdown';
import { compareInputProps } from './utils';
import validateInput from '../../../utils/inputValidation';
import { countryList } from '../../../enum/countries';
import errorIcon from '../../../assets/images/icn-alert-warning-input.svg';
import successIcon from '../../../assets/images/icn-sm-checkmark-tick.svg';
import {
  inputContainer,
  inputField,
  inputLabel,
  inputHelpContainer,
  inputHelpIcon,
  countryContainer,
  phoneInputContainer,
  phoneInputCountrySelect,
} from './styles';

const validateIsRequired = (value) => validateInput(value, 'required');

const phonePattern = (val) => /^([ 0-9]){0,16}$/i.test(val);

const countryListOptions = countryList.map((el) => ({
  ...el,
  value: `${el?.name}/${el.phone}`,
  render: () => (
    <div css={countryContainer}>
      <span className="flag-img">{el.flag}</span>
      <span>{el.phoneCode}</span>
    </div>
  ),
}));

const FormPhoneInput = (props) => {
  const {
    id,
    type = 'text',
    required,
    label,
    horizontal,
    placeholder,
    successLabel = 'Field',
    className,
    value: initValue,
    onChange,
    onError,
    validate,
    pattern = phonePattern,
    disabled,
    isTouched: isTouchedInit,
    inputProps,
    inputRef,
  } = props;

  const selectValue = countryListOptions.find((el) => el.phoneCode === initValue.split('/')[0]);
  const inputValue = initValue.indexOf('/') === -1 ? initValue : initValue.split('/')[1] ?? '';

  const [value, setValue] = useState(inputValue);
  const [selected, setSelected] = useState(selectValue);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit);

  const isCorrect = isTouched && !error;

  useEffect(() => {
    selectValue?.phoneCode !== selected?.phoneCode && setSelected(selectValue);
    inputValue !== value && setValue(inputValue);
  }, [hash({ selectValue, inputValue })]);

  useEffect(() => {
    checkForError(value);
  }, [isTouched]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    if (disabled) return;
    // Form component holds all values and errors into state so we need to pass the new value and error to the form component
    checkForError(value);
    isFunction(onChange) && selected?.phoneCode && value?.length && onChange(id, `${selected.phoneCode}/${value}`);
  }, [value]);

  useImperativeHandle(inputRef, () => ({
    changeValue: (newValue) => handleChange({ target: { value: newValue } }),
    checkForError,
    setError: handleError,
    value,
    changeIsTouched: setIsTouched,
  }));

  // Handle change of the input,
  // All form inputs are controlled and have onChange
  const handleChange = (event) => {
    const {
      target: { value: newValue },
    } = event;

    if (newValue.length && isFunction(pattern) && !pattern(newValue)) return;

    setValue(newValue);
  };

  // On blur is used only to change the flag for touched
  const handleBlur = () => {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };

  const checkForError = (newValue) => {
    let newError = null;

    // If validate function is provided check for error
    if (isFunction(validate) && !newError) newError = validate(newValue, id);
    else if (required) newError = validateIsRequired(newValue);

    handleError(newError);
  };

  const handleError = (newError) => {
    if (error === newError) return;
    // The first time when you edit the field it will not generate error for the field
    // but it will pass the error to the form so you can not submit it until there is error
    isTouched && setError(newError);
    // The form component holds all errors into ref so we need to pass the new error into the form component
    isFunction(onError) && onError(id, newError);
  };

  return (
    <div css={inputContainer(horizontal, className)} {...(isString(className) && { className })}>
      {label && (
        <label htmlFor={id} css={inputLabel(horizontal)}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}
      <div css={phoneInputContainer}>
        <Dropdown
          options={countryListOptions}
          value={selectValue}
          placeholder="Code"
          onChange={setSelected}
          css={phoneInputCountrySelect}
          renderSelectedValue={(val) => val?.phoneCode}
        />
        <Input
          type={type}
          value={value}
          placeholder={isString(placeholder) ? placeholder : `${label}...`}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          css={inputField(!!error, isCorrect)}
          {...inputProps}
        />
      </div>
      {(error || isCorrect) && (
        <div css={inputHelpContainer(!!error)}>
          <div css={inputHelpIcon(!!error)}>
            <img src={error ? errorIcon : successIcon} alt="Logo" />
          </div>
          <span>{error ? error.msg : `${label || successLabel} is correct`}</span>
        </div>
      )}
    </div>
  );
};

FormPhoneInput.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  pattern: PropTypes.func,
  horizontal: PropTypes.bool,
  successLabel: PropTypes.string,
  dateTimeProps: PropTypes.any,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default forwardRef((initProps, ref) =>
  useMemo(() => <FormPhoneInput {...initProps} inputRef={ref} />, compareInputProps(initProps)),
);
