import { Switch, Redirect } from 'react-router-dom';
import { useAuth } from '../../components/UI/Authenticate';
import PrivateRoutes from '../Private/Routes';
import PublicRoutes from '../Public/Routes';
import NavBar from '../../components/UI/NavBar';
import Footer from '../../components/UI/Footer';
import { contentContainer, applicationContainer } from './styles';
import { Notifications } from '../../components/UI/Notifications';
import PageLoader from '../../components/UI/Loader/PageLoader';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <main css={applicationContainer}>
      <Notifications />
      <PageLoader />
      <NavBar />
      <section css={contentContainer}>
        <Switch>
          {isAuthenticated ? PrivateRoutes : PublicRoutes}
          <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
        </Switch>
      </section>
      <Footer />
    </main>
  );
};

export default App;
