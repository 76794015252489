export const dropdownStyles = (theme) => ({
  borderColor: theme.whiteOpacity01,
  backgroundColor: theme.whiteOpacity01,
  borderRadius: 4,
  color: theme.white,
  minWidth: 1,
  width: 155,
  '& > div': {
    backgroundColor: 'transparent',
    border: 'none',
  },
});

export const dropdownOptionsStyles = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 8,
};

export const dropdownValueStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  '& > img': {
    maxWidth: 24,
    marginRight: 8,
  },
  '& > h4': {
    color: theme.white,
    fontSize: 14,
  },
});
