import { getReq } from './axios/makeRequest';
import {
  getAllCoursesParams,
  getCourseModel,
  getAllCourseMaterialParams,
  getMyCoursesParams,
  getMaterialModel,
} from './models/courses';

const baseUrl = `${apiUrls.oneAcademy}/courses`;

export const getAllCourses = async (options = {}) => {
  const params = getAllCoursesParams(options);

  const [res, err] = await getReq(`${baseUrl}/list?${params}`);

  const data = res?.data?.map((el) => getCourseModel(el));

  return [
    {
      ...res,
      data,
    },
    err,
  ];
};

export const getMyCourses = async (options = {}) => {
  const params = getMyCoursesParams(options);
  const [res, err] = await getReq(`${baseUrl}/my?${params}`);

  const data = res?.data?.map((el) => getCourseModel(el));

  return [
    {
      ...res,
      data,
    },
    err,
  ];
};

export const getCourseById = async (id, accountId) => {
  const [response, error] = await getReq(`${baseUrl}/${id}?accountId=${accountId}`);
  return [getCourseModel(response), error];
};

export const getCourseMaterial = async (courseId, options = {}) => {
  const params = getAllCourseMaterialParams(options);
  const [res, err] = await getReq(`${baseUrl}/${courseId}/materials?${params}`);

  const data = res?.data?.map((el) => getMaterialModel(el));

  return [
    {
      ...res,
      data,
    },
    err,
  ];
};
