import PropTypes from 'prop-types';
import { Tabs, Tab, Row, Col } from '../../../UI';
import { useTranslations } from '../../../UI/Theme';
import QuizResults from '../QuizResults';
import MaterialsList from '../MaterialsList';
import AuthorBox from '../AuthorBox';
import { wrapper, tabContainer, aboutTab } from './styles';

const InfoTabs = (props) => {
  const { course, isAvailable } = props;
  const { translations } = useTranslations();

  const aboutContent = () => (
    <div css={aboutTab}>
      <p>{course?.description}</p>
    </div>
  );

  return (
    <div css={wrapper}>
      <Row horizontalGap={12} noWrap container>
        <Col sm={course?.expert ? 9 : 12} className="bottomBuffer">
          {isAvailable ? (
            <Tabs routeMatch="/course/:id" startingRoute={`/course/${course?.id}`} tabsContentClass={tabContainer}>
              <Tab label={translations.aboutCourseTab} url="/about" component={() => aboutContent()} />
              <Tab
                label={translations.quizResultsCourseTab}
                url="/my-quiz"
                component={() => <QuizResults course={course} />}
              />
              <Tab
                label={translations.resources}
                url="/resources"
                component={() => <MaterialsList course={course} />}
              />
            </Tabs>
          ) : (
            aboutContent()
          )}
        </Col>
        {course?.expert && (
          <Col sm={3} className="bottomBuffer">
            <AuthorBox author={course?.expert} />
          </Col>
        )}
      </Row>
    </div>
  );
};

InfoTabs.propTypes = {
  course: PropTypes.object,
  isAvailable: PropTypes.bool,
};

export default InfoTabs;
