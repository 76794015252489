import { useRef, useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Route, Link, useHistory } from 'react-router-dom';
import ScrollArrows from './ScrollArrows';
import useDragScroll from './useDragScroll';
import { tabs, tabsListContainer, tabsListContainerWrapper } from './styles';

const Tabs = ({ children, startingRoute, tabsContentClass }) => {
  const hasRoutes = startingRoute && children.every((el) => el.props.url);
  const selected =
    (hasRoutes && children.find((el) => window.location.href.split('/').pop() === el.props.url.replace(/\//g, ''))) ??
    null;
  const activeRouteProps = selected ? selected.props : children[0].props;
  const defaultUrl = `${startingRoute}${activeRouteProps.url}`;

  const tabsRef = useRef();
  const arrowsRef = useRef();
  const [activeTab, setActiveTab] = useState(activeRouteProps.label);
  const [arrowsProps, setArrowsProps] = useState({
    show: false,
    scrollStart: true,
    scrollEnd: false,
  });
  const history = useHistory();

  const handleChangeTab = (tabLabel) => {
    setActiveTab(tabLabel);
  };

  useEffect(() => {
    selected && selected?.props?.label !== activeTab && setActiveTab(selected.props.label);
    activeRouteProps.label === activeTab && !selected && history.replace(defaultUrl);
  });

  const handleScrollChange = (val) => {
    tabsRef.current.scrollLeft += val;
  };
  const { isDragging, ...dragScroll } = useDragScroll(tabsRef, arrowsRef, handleScrollChange);

  return (
    <div css={tabs}>
      <div css={tabsListContainerWrapper(arrowsProps?.show)}>
        <div ref={tabsRef} {...dragScroll} css={tabsListContainer(isDragging)}>
          {children.map((child) => {
            const { label, url } = child.props;
            const Component = (
              <child.type {...child.props} active={activeTab === label} key={label} onClick={handleChangeTab} />
            );
            return hasRoutes ? (
              <Link
                key={label}
                to={{
                  pathname: `${startingRoute}${url}`,
                  state: { toTop: true },
                }}>
                {Component}
              </Link>
            ) : (
              Component
            );
          })}
        </div>
        <ScrollArrows
          ref={arrowsRef}
          arrowsProps={arrowsProps}
          setArrowsProps={setArrowsProps}
          onScroll={handleScrollChange}
          scrollContainer={tabsRef}
        />
      </div>
      <div css={tabsContentClass}>
        {children.map((child) => {
          const { label, url, component } = child.props;
          if (label !== activeTab) return undefined;

          if (hasRoutes) return <Route key={label} path={`${startingRoute}${url}`} render={component} />;

          return cloneElement(component, { key: label });
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  startingRoute: PropTypes.string,
  children: PropTypes.instanceOf(Array).isRequired,
  tabsContentClass: PropTypes.any,
};

export default Tabs;
