import { isFunction, isArray, mapValues, isUndefined, pick, isPlainObject } from 'lodash-es';
import { isStrNum } from '../../../utils';

export const performAction = (value, action) => {
  if (isFunction(action)) return isArray(value) ? value.map(action) : action(value);
  return null;
};

export const removeNodes = (options, displayKey = 'label', uniqueKey = 'value') => {
  const remove = element =>
    isStrNum(element) ? element : mapValues(element, val => pick(val, [displayKey, uniqueKey]));
  return performAction(options, remove);
};

export const prepareValue = (value, options, multiSelect, displayKey, uniqueKey) => {
  const setValue = val => {
    if (isStrNum(val)) {
      const match = (options ?? []).find(el =>
        [displayKey, uniqueKey].some(key => isPlainObject(el) && el[key] === val)
      );
      return match ?? { [displayKey]: val, simple: true };
    }
    return val;
  };
  return performAction(value, setValue) ?? (multiSelect ? [] : {});
};

export const outputValue = (value, displayKey, mappingFunction, shouldSimplify) => {
  if (!shouldSimplify) return value;

  const getSimplify = val => (val?.simple ? val[displayKey] : val);
  const simpleValue = performAction(value, getSimplify);

  return isFunction(mappingFunction) ? mappingFunction(simpleValue) : simpleValue;
};

export const prepareOptions = (options, displayKey) =>
  isUndefined(options)
    ? options
    : isArray(options) && options.map(el => (isStrNum(el) ? { [displayKey]: el, simple: true } : el));
