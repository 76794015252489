export const containerStyle = ({ zIndex, fullScreen, fixed }) => [
  {
    position: fixed ? 'fixed' : 'absolute',
    zIndex: zIndex || 200,
  },
  fullScreen && {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
];
