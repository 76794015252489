export const wrapper = (theme) => ({
  backgroundColor: theme.white,
  borderRadius: 4,
  height: '100%',
  minHeight: 340,
  padding: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const header = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
  '& .avatar': {
    width: 40,
    height: 40,
    borderRadius: 40,
    objectFit: 'cover',
    marginRight: 8,
  },
  '& .title': {
    fontSize: 14,
    lineHeight: 1.1,
    textTransform: 'uppercase',
  },
};

export const body = {
  height: '100%',
};

export const listItemStyle = (theme) => ({
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .item-left': {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: 12,
      fontWeight: 600,
    },
    '& .avatar': {
      width: 32,
      height: 32,
      borderRadius: 32,
      marginLeft: 8,
      marginRight: 8,
    },
    '& .name': {
      fontSize: 12,
    },
  },
  '& .item-right > span': {
    fontSize: 12,
    color: theme.highlightsBlue,
  },
  '& .item-skeleton': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .skeleton-avatar': {
      marginLeft: 8,
      marginRight: 8,
    },
  },
});

export const noData = {
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
};

export const footer = (theme) => ({
  marginTop: 8,
  textAlign: 'center',
  '& .link': {
    lineHeight: 1.5,
    fontSize: 16,
    fontWeight: 500,
    transition: 'color .3s linear',
    '&:hover': {
      color: theme.primary,
    },
  },
});
