import { useEffect, useState } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, showApiError, Skeleton } from '../../UI';
import CourseCard from '../../Shared/CourseCard';
import { useTranslations } from '../../UI/Theme';
import { useAuth } from '../../UI/Authenticate';
import { getMyCourses } from '../../../services/courses';
import { wrapper, heading, noData } from './styles';

const MyCourses = () => {
  const history = useHistory();
  const { translations } = useTranslations();
  const [myCourses, setMyCourses] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();

  useEffect(() => {
    fetchMyCourses();
  }, [currentAccount?.id]);

  const fetchMyCourses = async () => {
    const [res, err] = await getMyCourses({ accountId, includeDetails: true });
    if (err) return showApiError(err);

    setMyCourses(res?.data ?? []);
  };

  return (
    <div css={wrapper}>
      <Row horizontalGap={12} noWrap container>
        <Col xs={12}>
          <div css={heading}>
            <h1>{translations.myCourses}</h1>
          </div>
        </Col>
      </Row>
      {!isNull(myCourses) ? (
        <Row horizontalGap={12} noWrap container>
          {!isEmpty(myCourses) ? (
            myCourses?.map((el) => (
              <Col xs={12} sm={6} md={4} lg={3} key={el.id} className="bottomBuffer">
                <CourseCard data={el} />
              </Col>
            ))
          ) : (
            <div css={noData}>
              <div>
                <h3>No courses found!</h3>
                <Button onClick={() => history.push('/courses')}>See all courses</Button>
              </div>
            </div>
          )}
        </Row>
      ) : (
        <Row horizontalGap={12} noWrap container>
          {Array(4)
            .fill()
            .map((el, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={index} className="bottomBuffer">
                <Skeleton height="340px" width="100%" />
              </Col>
            ))}
        </Row>
      )}
    </div>
  );
};

export default MyCourses;
