export const footerContainer = (theme) => ({
  backgroundColor: theme.secondaryDark,
  color: theme.whiteOpacity04,
  paddingTop: 30,
  paddingBottom: 30,
  fontWeight: 'bold',
  lineHeight: 1.7,
});

export const footerColumnWrapper = {
  flexDirection: 'row',
};

export const footerDescription = {
  paddingRight: 45,
};

export const usernameBranchContainer = {
  display: 'flex',
  marginBottom: 20,
};

export const appAvatarIcon = {
  height: 36,
};

export const descriptionStyles = {
  fontSize: 12,
  lineHeight: 1.5,
  marginBottom: 32,
  alignItems: 'flex-start',
};

export const iconsContainer = (theme) => ({
  color: theme.highlightsBlue,
  marginBottom: 16,
  '& .links': {
    marginRight: 16,
  },
});

export const footerColumn = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 50,
};

export const columnTitle = (theme) => ({
  color: theme.highlightsBlue,
  textTransform: 'uppercase',
  marginBottom: 16,
  fontWeight: 500,
});

export const footerLink = (isActive) => (theme) => ({
  opacity: isActive ? 1 : 0.6,
  color: theme.secondaryLighter,
  textTransform: 'none',
  fontWeight: 300,
  marginBottom: 4,
  fontSize: 16,
});

export const termsStyles = (theme) => ({
  color: theme.whiteOpacity04,
  fontSize: 14,
  lineHeight: 1.4,
});
