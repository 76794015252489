import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Row } from '../Grid';
import { paginationContainer, shownResultsIndicator, paginationButton } from './styles';

const Pagination = ({ totalResults, pageIndex: pageProp, pageSize, onPageChange }) => {
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(totalResults / pageSize);
  const noResults = totalPages === 0;
  const needEllipses = totalPages > 5;

  useEffect(() => {
    setPage(pageProp);
  }, [pageProp]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const arrowsButton = (disabled, type) => (
    <span
      css={paginationButton(disabled, false)}
      {...(!disabled && {
        onClick: () => handlePageChange(page - (type === 'left' ? 1 : -1)),
      })}>
      <Icon material size={28} iconName={type === 'left' ? 'chevron_left' : 'chevron_right'} />
    </span>
  );

  const pageButton = (ind) => (
    <span
      key={ind}
      css={paginationButton(totalPages === 1, ind === page)}
      {...(totalPages !== 1 &&
        page !== ind && {
          onClick: () => handlePageChange(ind),
        })}>
      {ind + 1}
    </span>
  );

  const ellipsesElement = <span css={paginationButton(true, false)}>...</span>;

  const fromIndex = page * pageSize + 1;
  const toIndex = (page + 1) * pageSize;

  return (
    <Row justify="space-between">
      <div css={paginationContainer}>
        <div css={shownResultsIndicator}>
          {`${fromIndex} - ${toIndex > totalResults ? totalResults : toIndex} `}({totalResults})
        </div>
        {arrowsButton(page === 0 || noResults, 'left')}
        {needEllipses ? (
          <>
            {pageButton(0)}
            {page > 2 && ellipsesElement}
            {page > 1 && totalPages > 3 && pageButton(page - 1)}
            {page !== 0 && page !== totalPages - 1 && pageButton(page)}
            {page < totalPages - 2 && totalPages > 4 && pageButton(page + 1)}
            {totalPages - page > 3 && ellipsesElement}
            {totalPages !== 1 && pageButton(totalPages - 1)}
          </>
        ) : (
          Array(totalPages)
            .fill()
            .map((el, ind) => pageButton(ind))
        )}
        {arrowsButton(page === totalPages - 1 || noResults, 'right')}
      </div>
    </Row>
  );
};

Pagination.propTypes = {
  totalResults: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
