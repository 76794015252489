import queryString from 'query-string';
import { pick, isEmpty } from 'lodash-es';
import { getExpertModel } from './experts';
import { getCategoryModel } from './categories';

export const getCourseModel = response =>
  response && {
    ...response,
    ...(!isEmpty(response?.translations) && {
      ...pick(response?.translations[0], ['name', 'description', 'label']),
    }),
    category: response?.category ? getCategoryModel(response?.category) : null,
    expert: response?.expert ? getExpertModel(response?.expert) : null,
    ...(response?.steps &&
      !isEmpty(response?.steps) && {
        steps: response?.steps?.map(el => ({
          ...el,
          ...(!isEmpty(el?.translations) && {
            ...pick(el?.translations[0], ['title']),
          }),
        })),
      }),
  };

export const getMaterialModel = response =>
  response && {
    ...response,
    ...(!isEmpty(response?.translations) && {
      ...pick(response?.translations[0], ['name', 'sourceUrl']),
    }),
  };

export const getAllCoursesParams = options => {
  const defaultSort = {
    sortBy: 'courseOrder',
    sortDirection: 'asc',
  };

  const mapSort = sort => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.categoryId) {
    filterFields.push('categoryId');
    filterValues.push(options.categoryId);
  }

  if (options.notAccountId) {
    filterFields.push('notAccountId');
    filterValues.push(options.notAccountId);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(options.includeDetails && { includeDetails: options.includeDetails }),
      ...(options.randomize && { randomize: options.randomize }),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' }
  );
};

export const getMyCoursesParams = options => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'asc',
  };

  const mapSort = sort => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(options.accountId && { accountId: options.accountId }),
      ...(options.includeDetails && { includeDetails: options.includeDetails }),
    },
    { arrayFormat: 'index' }
  );
};

export const getAllCourseMaterialParams = options =>
  queryString.stringify(
    {
      ...(options.accountId && { accountId: options.accountId }),
      ...pick(options, ['pageIndex', 'pageSize']),
    },
    { arrayFormat: 'index' }
  );
