import { headerContainer } from '../../../components/UI/Paper';

export const contentContainer = {
  width: '100%',
  minHeight: '100%',
  height: 'calc(100vh - 80px)',
  marginTop: 80,
};

export const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
  height: '100%',
  width: '100%',
  minHeight: 'calc(100vh - 375px)',
};

export const formTitleContainer = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: 3,
  textAlign: 'center',
  boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)',
});

export const loginTitle = (theme) => [
  headerContainer(theme),
  {
    textAlign: 'left',
    backgroundColor: theme.whiteGrayLight,
  },
];

export const title = (theme) => ({
  color: theme.grayDark,
  fontWeight: 600,
  marginBottom: 10,
  fontSize: 28,
});

export const detailStylesLogin = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const loginSubtitle = (theme) => ({
  fontSize: 13,
  lineHeight: 1.71,
  color: theme.gray,
  fontWeight: 400,
});

export const forgotStyles = (theme) => ({
  marginLeft: 32,
  '& > a': {
    fontSize: 12,
    color: theme.info,
    fontWeight: 400,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.infoDark,
    },
  },
});

export const formContainer = {
  padding: 48,
};

export const formInputContainer = {
  marginBottom: 20,
};

export const titleForgot = (theme) => ({
  color: theme.grayDark,
  fontWeight: 600,
  marginBottom: 10,
  fontSize: 20,
});

export const backButton = (theme) => ({
  color: theme.infoDark,
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  border: `1px solid ${theme.infoDark}`,
  padding: '0 32px',

  '&: hover': {
    backgroundColor: theme.infoLight,
  },
});

export const submitButtonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};
