export const wrapper = (theme) => ({
  backgroundColor: theme.white,
  paddingTop: 16,
  paddingBottom: 16,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
});

export const tabContainer = {
  padding: '32px 0',
};

export const aboutTab = (theme) => ({
  '& p': {
    color: theme.grayLight,
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: 20,
  },
});
