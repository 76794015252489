export const icon = [
  {
    icon: 'fab fa-twitter',
    path: '/twitter',
  },
  {
    icon: 'fab fa-facebook-f',
    path: '/facebook',
  },
  {
    icon: 'fab fa-youtube',
    path: '/instagram',
  },
];

export const productElements = translations => [
  {
    title: translations.courses,
    path: '/courses',
  },
  // {
  //   title: translations.products,
  //   path: '/products',
  // },
  // {
  //   title: 'Free',
  //   path: '/free',
  // },
  // {
  //   title: 'Help',
  //   path: '/help',
  // },
];

export const oneAcademyElements = translations => [
  // {
  //   title: 'About',
  //   path: '/about',
  // },
  // {
  //   title: 'Team',
  //   path: '/team',
  // },
  {
    title: translations.experts,
    path: '/experts',
  },
  // {
  //   title: 'Contact',
  //   path: '/contact',
  // },
];
