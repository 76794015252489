import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import axios from 'axios';
import AuthProvider from './components/UI/Authenticate';
import ShopProvider from './components/UI/Shop';
import BaseApp from './screens/App/App';
import { TranslationsProvider, ScrollToTop } from './components/UI';
import RedirectCallback from './screens/Public/RedirectCallback';
import LogoutPage from './screens/Public/Logout';
import { trackGeoLocation } from './services/settings';
import initGlobalStyles from './styles/globalCSS';
import theme from './styles/theme.json';
import { deleteReq, getReq, patchReq, postReq, putReq } from './services/axios/makeRequest';

const clientId = 'OneLife';
setAuthConfig({
  identity: { domain: identityProvider.keycloak, clientId },
  oneLifeDomain: apiUrls.oneLife,
  requestAdapter: {
    get: getReq,
    post: postReq,
    put: putReq,
    patch: patchReq,
    delete: deleteReq,
  },
  [AUTH_EVENTS_TYPES.SET_TOKEN]: token => {
    if (token?.access_token) axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location = '/';
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

const App = () => (
  <Router>
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <AuthProvider renderRedirect={RedirectCallback} renderLogout={LogoutPage}>
          <ShopProvider>
            <TranslationsProvider>
              {initGlobalStyles()}
              <BaseApp />
            </TranslationsProvider>
          </ShopProvider>
        </AuthProvider>
      </ThemeProvider>
    </ScrollToTop>
  </Router>
);

const container = document.getElementById('one-academy-root');
const root = createRoot(container);
root.render(<App />);
