import { useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isString } from 'lodash-es';
import SearchBar from '../SearchBar';
import { Spinner } from '../Loader';
import useOptions from './useOptions';
import { dropdownSearch, infiniteScroll } from './styles';

const OptionsList = (props) => {
  const { withSearch, className, containerRef, isOpen } = props;

  const {
    renderList,
    filterResults,
    searchRef,
    needInfiniteScroll,
    allOptionsLength,
    optionsLength,
    fetchMoreResults,
  } = useOptions(props);

  useEffect(() => {
    isOpen ? containerRef.current.classList.add('opened') : containerRef.current.classList.remove('opened');
  }, [isOpen]);

  const search = withSearch && (
    <SearchBar inputRef={searchRef} placeholder="Search" onSubmit={filterResults} css={dropdownSearch} />
  );

  return (
    <div
      role="presentation"
      onClick={(event) => event.stopPropagation()}
      {...(isString(className) && { className })}
      ref={containerRef}>
      {isOpen && search}
      {/* Wait for container to be mounted into the DOM
      and after that mount infinite scroll */}
      {isOpen && containerRef.current?.isConnected && needInfiniteScroll && (
        <InfiniteScroll
          dataLength={optionsLength}
          next={fetchMoreResults}
          hasMore={!!optionsLength && allOptionsLength > optionsLength}
          scrollableTarget={containerRef.current}
          loader={
            <div css={infiniteScroll}>
              <Spinner size={65} loading />
            </div>
          }>
          {renderList}
        </InfiniteScroll>
      )}
      {isOpen && !needInfiniteScroll && renderList}
    </div>
  );
};

OptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  containerRef: PropTypes.object,
};

export default OptionsList;
