import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { tableHead, tableHeadCell, tableHeadIcon } from './styles';

const TableHead = (props) => {
  const { columns, getData } = props;
  const [sort, setSort] = useState(null);

  const changeSort = (column) => () => {
    const { value } = column;
    const isDesc = sort === value;
    const newSort = isDesc ? `${value}Descending` : sort === `${value}Descending` ? null : value;
    setSort(newSort);
    getData({
      sort: newSort && { sortField: value, sortOrder: isDesc ? 'desc' : 'asc' },
    });
  };

  return (
    <div css={tableHead}>
      {columns.map((column) => {
        const { sortable, name, value } = column;
        return sortable ? (
          <div key={name} css={tableHeadCell(column)} role="button" tabIndex="-1" onClick={changeSort(column)}>
            <b>{name}</b>
            <Icon
              material
              iconName="arrow_downward"
              css={tableHeadIcon(sort === value, sort === `${value}Descending`)}
            />
          </div>
        ) : (
          <div key={name} css={tableHeadCell(column)}>
            <b>{name}</b>
          </div>
        );
      })}
    </div>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
};

export default TableHead;
