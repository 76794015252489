import PropTypes from 'prop-types';
import CheckButton from './CheckButton';
import { checkGroup } from './styles';

const ButtonGroup = ({ value, options, onChange, multiSelect }) => {
  const selectOption = (isChecked, element) => {
    if (!multiSelect) return value !== element && onChange(element);
    return onChange(isChecked ? value.concat(element) : value.filter((el) => el !== element));
  };
  return (
    <div css={checkGroup}>
      {Object.keys(options).map((el, index) => {
        const isChecked = multiSelect ? value.indexOf(el) !== -1 : value === el;
        return (
          <CheckButton
            key={`OptionButton${index}`}
            radio={!multiSelect}
            checked={isChecked}
            onChange={(val) => selectOption(val, el)}>
            {options[el]}
          </CheckButton>
        );
      })}
    </div>
  );
};

ButtonGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  options: PropTypes.object,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ButtonGroup;
