export const teacherWrapper = (theme) => ({
  backgroundColor: theme.secondaryVeryLighter,
  padding: '32px 24px',
  textAlign: 'center',
  '& > h2': {
    marginBottom: 32,
  },
  '& .teacherAvatar': {
    width: 100,
    height: 100,
    borderRadius: 100,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 'auto',
    marginBottom: 8,
  },
  '& > h6': {
    marginBottom: 8,
  },
  '& > span': {
    display: 'block',
    color: theme.darkBlue,
    fontWeight: 300,
    marginBottom: 8,
  },
  '& > p': {
    color: theme.textLightDisabled,
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 16,
    lineHeight: 1.5,
  },
});

export const teacherLink = (theme) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.highlightsBlue,
  textTransform: 'uppercase',
  '&:hover': {
    marginBottom: 24,
  },
});
