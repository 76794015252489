import PropTypes from 'prop-types';
import moment from 'moment';
import { isFunction } from 'lodash-es';
import { getNestedProperty } from '../../../utils';
import { tableRow, tableCell } from './styles';

const TableBody = (props) => {
  const { data, columns } = props;

  const displayValue = (column, row) => {
    let value = getNestedProperty(row, column.value.split('.'));
    value = value && column.mapValue ? column.mapValue[value.toLowerCase()] : value;
    value = value && column.dateTimeFormat ? moment(value).format(column.dateTimeFormat) : value;

    return value ?? 'N/A';
  };

  const colRender = (row, column) => {
    if (isFunction(column.render)) {
      const ColRender = column.render;
      return <ColRender {...row} />;
    }
    return displayValue(column, row);
  };

  return data.map((row, i) => (
    <div key={`table-row-${i}`} css={tableRow}>
      {columns.map((column, j) => (
        <div key={`table-column${i}-${j}`} css={tableCell(column.width, !!column.render)}>
          {colRender(row, column)}
        </div>
      ))}
    </div>
  ));
};

TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default TableBody;
