import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { Row, Col, Tooltip, showApiError } from '../../../UI';
import { useAuth } from '../../../UI/Authenticate';
import { useTranslations } from '../../../UI/Theme';
import badgeImg from '../../../../assets/images/no-badge-thumb.png';
import { getAccountAccomplishments } from '../../../../services/accounts';
import {
  wrapper,
  avatarWrap,
  avatarImgWrap,
  avatarImgStyle,
  avatarNoImgWrap,
  badgesWrap,
  badgesTooltipWrap,
  badgesBoxWrap,
  badgeBox,
} from './styles';

const DashboardHeader = () => {
  const [accomplishments, setAccomplishments] = useState(null);
  const {
    user: {
      profile,
      academyAccount: { id: accountId },
    },
  } = useAuth();
  const { translations } = useTranslations();

  useEffect(() => {
    fetchАccomplishments();
  }, [accountId]);

  const fetchАccomplishments = async () => {
    const [res, err] = await getAccountAccomplishments(accountId);
    if (err) return showApiError(err);

    setAccomplishments(res?.badges ?? []);
  };

  return (
    <div css={wrapper}>
      <Row justify="space-between" align="flex-start" noWrap container>
        <Col sm="auto">
          <div css={avatarWrap}>
            {profile?.picThumbnailUrl && profile?.picThumbnailUrl?.length ? (
              <div css={avatarImgWrap}>
                <img src={profile?.picThumbnailUrl} css={avatarImgStyle} alt="avatar" />
              </div>
            ) : (
              <div css={avatarNoImgWrap}>
                <h2>{profile?.firstName?.substring(0, 1)}</h2>
                <h2>{profile?.lastName?.substring(0, 1)}</h2>
              </div>
            )}
            <div>
              <h2 className="userName">
                {profile?.firstName} {profile?.lastName}
              </h2>
            </div>
          </div>
        </Col>
        {!isEmpty(accomplishments) && (
          <Col sm="auto">
            <div css={badgesWrap}>
              <span className="label">{translations.earnedBadges}</span>
              <div css={badgesBoxWrap}>
                <>
                  {accomplishments.map(
                    (el, index) =>
                      (index <= 2 || accomplishments.length === 4) && (
                        <Tooltip position="bottom" content={<p>{el?.name}</p>} key={el.id}>
                          <div className="badge-wrap">
                            <div css={badgeBox(el?.pictureUrl && el?.pictureUrl?.length ? el.pictureUrl : badgeImg)} />
                          </div>
                        </Tooltip>
                      ),
                  )}
                  {accomplishments.length > 4 && (
                    <Tooltip
                      position="left"
                      content={
                        <div css={badgesTooltipWrap(Math.min(accomplishments.length - 2, 3))}>
                          {accomplishments.map(
                            (el, index) =>
                              index > 1 && (
                                <div className="badges-tooltip-wrap" key={el.id}>
                                  <div
                                    css={badgeBox(el?.pictureUrl && el?.pictureUrl?.length ? el.pictureUrl : badgeImg)}
                                  />
                                  <p>{el?.name}</p>
                                </div>
                              ),
                          )}
                        </div>
                      }>
                      <div className="badge-wrap">
                        <div css={badgeBox}>+{accomplishments.length - 3}</div>
                      </div>
                    </Tooltip>
                  )}
                </>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DashboardHeader;
