export default {
  en: {
    hello: 'Hello,',
    nextPage: 'Next page',
    previousPage: 'Previous page',
    next: 'Next',
    previous: 'Previous',
    yes: 'Yes',
    no: 'No',
    home: 'Home',
    about: 'About',
    courses: 'Courses',
    products: 'Products',
    getStarted: 'Get Started',
    startNow: 'Start Now',
    dashboard: 'Dashboard',
    product: 'Product',
    experts: 'Experts',
    language: 'Language',
    badges: 'Badges',
    earnedBadges: 'Earned Badges',
    editProfile: 'Edit Profile',
    profile: 'Profile',
    resources: 'Resources',
    aboutCourseTab: 'About',
    quizResultsCourseTab: 'My quiz results',
    continueLearning: 'Continue Learning',
    seeAll: 'See all',
    seeMore: 'See More',
    completed: 'Completed',
    friendsRankList: 'Friends rank list',
    recommendedToYou: 'Recommended to you',
    peopleWatching: 'People watching',
    lessons: 'Lessons',
    myCourses: 'My Courses',
    expertTeacher: 'Expert Teacher',
    viewProfile: 'View Profile',
    settings: 'Settings',
    paymentMethods: 'Payment Methods',
    signIn: 'Sign in',
    signInToOneAcademy: 'Sign in to OneAcademy',
    signOut: 'Sign Out',
    pleaseFillFetailsBelow: 'Please fill the details below',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot your password',
    shoppingCart: 'Shopping Cart',
    item: 'Item',
    price: 'Price',
    fee: 'fee',
    total: 'Total',
    viewFullCart: 'View Full Cart',
    personalInformation: 'Personal Information',
    contactInformation: 'Contact Information',
    idInformation: 'ID Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    countryOfBirth: 'Country of Birth',
    cityOfBirth: 'City of Birth',
    dateOfBirth: 'Date of Birth',
    idNumber: 'ID Number',
    idType: 'ID Type',
    idExpiration: 'Exp. Date',
    emailAddress: 'Email Address',
    confirmEmailAddress: 'Confirm your email address',
    phoneNumber: 'Phone Number',
    address: 'Address',
    country: 'Country',
    city: 'City',
    postalCode: 'Postal Code',
    updateProfile: 'Update profile',
    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    bankDetails: 'Bank Details',
    accountHolderName: 'Account Holder Name',
    bankName: 'Bank Name',
    bankCountry: 'Bank Country',
    ibanAccount: 'IBAN Account',
    bicSwiftCode: 'BIC / SWIFT Code',
    bank: 'Bank',
    bankProvince: 'Bank Province',
    bankCity: 'Bank City',
    bankBranch: 'Bank Branch',
    cardHolderName: 'Card Holder Name',
    cardNumber: 'Card Number',
    accountNumber: 'Account Number',
    all: 'All',
    meetТheExperts: 'Meet the Experts',
    accomplishments: 'Accomplishments',
    biography: 'Biography',
    ourProducts: 'Our Products',
    giftCode: 'Gift Code',
    redeem: 'Redeem',
    redeemGiftCode: 'Redeem Gift code',
    redeemed: 'Redeemed',
    redeemedBy: 'Redeemed By',
    notRedeemed: 'Not Redeemed',
    notPaid: 'Not Paid',
    buyModule: 'Buy Module',
    myModules: 'My Modules',
    moduleName: 'Module Name',
    purchaseHistory: 'Purchase History',
    educationalModules: 'Educational Modules',
    searchModules: 'Search Modules',
    sortByValue: 'Sort by: Value',
    sortByDate: 'Sort by: Date',
    sortByName: 'Sort by: Name',
    noModulesForThisSearch: 'There are no modules from this search',
    noForPurchaseHistoryThisSearch: 'No order history found for this search',
    withActivationFee: 'With activation fee',
    addToCard: 'Add to card',
    cartTotal: 'Cart Total',
    checkOutNow: 'Check out now',
    bankWireTransfer: 'Bank Wire Transfer',
    bankWireP1:
      'Following Your Bank Wire Request To Purchase The NewLife Package, The OneLife Team Would Like To Kindly Refer You To An Official OneLife Member For Your Region Who Will Handle The Payment For You.',
    bankWireP2:
      'This Procedure Is Necessary So That We Can Ensure That Your Payment Will Get Processed Effectively And On Time.',
    bankWireP3: 'You Can Use The Bank Wire Payment By Contacting Distributor:',
    bankWireP4: 'Please Don’t Forget To Include The Following Information:',
    bankWireP5: 'Alternatively, You Can UseOne Of The Other Payment Methods Available!',
    bankWireP6: 'Thank You For Your Understanding!',
    bankWireP7: 'Best Wishes,',
    bankWireP8: 'The OneLife Team',
    cashWallet: 'Cash Wallet',
    ballance: 'Ballance',
    purchaseWith: 'Purchase with',
    yourOrderCompletedSuccessfully: 'Your order was completed successfully!',
    orderFail: 'Order fail!',
    yourOrderCompletedSuccessfullyMsg:
      'An email including the details about your order has been sent to your email address. Please keep it for your records and check the instructions for further steps.',
    orderFailMsg: 'Please contact our support agent for more details at',
    backToShop: 'Back to Shop',
    businessVolume: 'Business Volume',
    whatBusinessVolume: 'What’s the Business volume',
    tokens: 'Tokens',
    howManyTokensGet: 'How many tokens you get?',
    splits: 'Splits',
    theAmountPeopleBelow: 'The amount of people below',
    difficultyReduction: 'Difficulty Reduction',
    immediateMining: 'Immediate Mining',
    isAllowed: 'is Allowed',
    isNotAllowed: 'is Not Allowed',
    bonusApplicableFor: 'Bonus Applicable for',
    network: 'Network',
    matching: 'Matching',
    level1st: '1st Level',
    level2nd: '2nd Level',
    level3rd: '3rd Level',
    level4th: '4th Level',
    bonusCap: 'Bonus Cap',
    newlifePrice: 'Newlife Price',
    success: 'Success',
    warningFail: 'Warning & Fail',
    warning: 'Warning',
    noticeUpdate: 'Notice & Update',
    successfullyRedeemPackage: 'You have successfully redeem module.',
    successfullyEditedProfile: 'You have successfully edited your profile.',
    successfullyUpdatedPaymentMethod: 'You have successfully updated payment method.',
    successfullyAddPaymentMethod: 'You have successfully add payment method.',
    successfullyAddModuleToCard: 'You have successfully added module to shopping cart.',
    moduleNotVisibleOrActive: 'This module is not visible or active!',
    failedLogin: 'Failed login',
    invalidUsernamePassword: 'Invalid username or password',
    successfullySwitchAccount: 'You have successfully switch account',
    copy: 'Copy',
    copyMessage: 'Copy to clipboard',
    quizCompleted: 'Quiz Compleat',
    activeQuestion: (current, total) => `Question ${current} of ${total}`,
    skipQuiz: 'Skip Quiz',
    retakeQuiz: 'Retake Quiz',
    score: 'Score',
    points: 'Points',
    youScoreIs: 'You score is',
    answerTo: (title) => `Answer to "${title}" is?`,
    yourAnswerTo: (title) => `Your answer to "${title}" is?`,
    loadingDocument: 'Loading document',
    downloadCertificate: 'Download Certificate',
    correct: 'Correct',
    wrong: 'Wrong',
    loadMore: 'Load More',
    noQuizResultMessage: 'There are no quiz results for this course.',
    footerText:
      'OneAcademy is an innovative e-learning platform covering a broad range of financial topics including trading, the stock exchange, cryptocurrency, financial analysis, asset management and more.',
    termsConditions: 'Terms and conditions',
    allRightReserved: 'All right reserved',
    changeAccount: 'Change Account',
    currentAccount: 'Current Account',
    certificate: 'Certificate',
    ofCompletion: 'of Completion',
    founderCeo: 'Founder & CEO',
    certificateSuccess: "Has successfully completed with distinction OneAcademy's Course",
    open: 'Open',
    theEnd: 'The End',
    upNext: 'Up Next',
    openPff: 'Click to open the PDF.',
    buyPackage: 'Buy Package',
    UpgradePackageText: 'Upgrade your package to unlock this course.',
  },

  es: {
    hello: 'Hola,',
    nextPage: 'Próxima página',
    previousPage: 'Página anterior',
    next: 'Próxima',
    previous: 'Anterior',
    yes: 'Sí',
    no: 'No',
    home: 'Hogar',
    about: 'Sobre',
    courses: 'Cursos',
    products: 'Productos',
    getStarted: 'Comenzar',
    startNow: 'Comenzar Ahora',
    dashboard: 'Panel',
    product: 'Producto',
    experts: 'Expertos',
    language: 'Idioma',
    badges: 'Distintivos',
    earnedBadges: 'Distintivos Obtenidos',
    editProfile: 'Editar Perfil',
    profile: 'Perfil',
    resources: 'Recursos',
    aboutCourseTab: 'Acerca de',
    quizResultsCourseTab: 'Mis resultados de la prueba',
    continueLearning: 'Aprendizaje Contínuo',
    seeAll: 'Ver Todo',
    seeMore: 'Ver Más',
    completed: 'Completado',
    friendsRankList: 'Lista de amigos por rango',
    recommendedToYou: 'Recomendado a usted',
    peopleWatching: 'Personas observando',
    lessons: 'Lecciones',
    myCourses: 'Mis Cursos',
    expertTeacher: 'Maestro Experto',
    viewProfile: 'Ver Perfil',
    settings: 'Configuraciones',
    paymentMethods: 'Métodos de Pago',
    signIn: 'Registrarse',
    signInToOneAcademy: 'Registrarse en OneAcademy',
    signOut: 'Registrarse al Salir',
    pleaseFillFetailsBelow: 'Favor llenar los detalles de abajo',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    forgotPassword: 'Olvidó su contraseña',
    shoppingCart: 'Carrito de Compras',
    item: 'Artículo',
    price: 'Precio',
    fee: 'cargo',
    total: 'Total',
    viewFullCart: 'Ver Carrito Lleno',
    personalInformation: 'Información Personal',
    contactInformation: 'Contacto',
    idInformation: 'Identificación de Información',
    firstName: 'Primer Nombre',
    lastName: 'Apellido',
    countryOfBirth: 'País Natal',
    cityOfBirth: 'Ciudad Natal',
    dateOfBirth: 'Fecha de Nacimiento',
    idNumber: 'Número de Información',
    idType: 'Tipo de Información',
    idExpiration: 'Fecha de Exp.',
    emailAddress: 'Dirección de Correo Electrónico',
    phoneNumber: 'Número de Teléfono',
    address: 'Dirección',
    country: 'País',
    city: 'Ciudad',
    postalCode: 'Código Postal',
    updateProfile: 'Actualizar perfil',
    submit: 'Entregar',
    cancel: 'Cancelar',
    save: 'Guardar',
    edit: 'Editar',
    bankDetails: 'Detalles Bancarios',
    accountHolderName: 'Nombre del Titular de la Cuenta',
    bankName: 'Nombre del Banco',
    bankCountry: 'País del Banco',
    ibanAccount: 'Cuenta IBAN',
    bicSwiftCode: 'Código BIC / SWIFT',
    bank: 'Banco',
    bankProvince: 'Provincia del Banco',
    bankCity: 'Ciudad del Banco',
    bankBranch: 'Sucursal del Banco',
    cardHolderName: 'Nombre del Tarjetahabiente',
    cardNumber: 'Número de Tarjeta',
    accountNumber: 'Número de Cuenta',
    all: 'Todo',
    meetТheExperts: 'Conozca a los Expertos',
    accomplishments: 'Logros',
    biography: 'Biografía',
    ourProducts: 'Nuestros Products',
    giftCode: 'Código de Regalo',
    redeem: 'Redimir',
    redeemGiftCode: 'Redimir Código de Regalo',
    redeemed: 'Redimido',
    redeemedBy: 'Redimido Por',
    notRedeemed: 'No Redimido',
    notPaid: 'No Pagado',
    buyModule: 'Comprar Módulo',
    myModules: 'Mis Módulos',
    moduleName: 'Nombre del Módulo',
    purchaseHistory: 'Historial de Compras',
    educationalModules: 'Módulos Educativos',
    searchModules: 'Buscar Módulos',
    sortByValue: 'Clasificar por: Valor',
    sortByDate: 'Clasificar por: Fecha',
    sortByName: 'Clasificar por: Nombre',
    noModulesForThisSearch: 'No hay módulos en esta busqueda',
    noForPurchaseHistoryThisSearch: 'No se encontró historial de órdenes en esta busqueda',
    withActivationFee: 'Con cargo por activación',
    addToCard: 'Agregar a la tarjeta',
    cartTotal: 'Total en el carrito',
    checkOutNow: 'Pagar ahora',
    bankWireTransfer: 'Transferencia Bancaria Electrónica',
    bankWireP1:
      'Luego de Efectuar su Solicitud de Transferencia Bancaria Electrónica para Comprar el Paquete NewLife, El Equipo de OneLife Desea Remitirlo Amablemente a un Miembro Oficial de OneLife en su Región, Que se Encargará de Efectuar el Pago por Usted.',
    bankWireP2:
      'Este Procedimiento Es Necesario Para que Podamos Garantizar que su Pago sea procesado de Manera Efectiva y a Tiempo.',
    bankWireP3: 'Usted Puede Usar el Pago Electrónico Bancario Contactando al Distribuidor:',
    bankWireP4: 'Por Favor, No Olvide Incluir la Siguiente Información:',
    bankWireP5: 'Alternativamente, Usted Puede Utilizar Uno de los Otros Métodos de Pago Disponibles',
    bankWireP6: 'Gracias por Su Comprensión!',
    bankWireP7: 'Saludos,',
    bankWireP8: 'El Equipo OneLife',
    cashWallet: 'Cartera de Efectivo',
    ballance: 'Balance',
    purchaseWith: 'Compre con',
    yourOrderCompletedSuccessfully: '¡Su pedido se completó con éxito!',
    orderFail: 'Pedido fallido!',
    yourOrderCompletedSuccessfullyMsg:
      'Se ha enviado un correo electrónico con los detalles de su pedido a su dirección de correo electrónico. Por favor, guárdelo para sus registros y consulte las instrucciones para conocer los pasos adicionales.',
    orderFailMsg: 'Por favor póngase en contacto con nuestro agente de servicio para obtener más detalles en',
    backToShop: 'Volver a Comprar',
    businessVolume: 'Volúmen de Negocios',
    whatBusinessVolume: 'Que es el Volúmen de Negocios',
    tokens: 'Fichas',
    howManyTokensGet: '¿Cuántas fichas tiene usted?',
    splits: 'Divididos',
    theAmountPeopleBelow: 'La cantidad de personas debajo',
    difficultyReduction: 'Reducción de Dificultad',
    immediateMining: 'Minería Immediata',
    isAllowed: 'es Permitido',
    isNotAllowed: 'no es Permitido',
    bonusApplicableFor: 'Bono Aplicable para',
    network: 'Red',
    matching: 'Correspondiente',
    level1st: '1er Nivel',
    level2nd: '2do Nivel',
    level3rd: '3er Nivel',
    level4th: '4to Nivel',
    bonusCap: 'Límite del Bono',
    newlifePrice: 'Precio de Newlife',
    success: 'Exito',
    warningFail: 'Advertencia & Fallo',
    warning: 'Advertencia',
    noticeUpdate: 'Aviso & Actualización',
    successfullyRedeemPackage: 'Usted ha redimido exitosamente el módulo,',
    successfullyEditedProfile: 'Usted ha editado exitosamente su perfil.',
    successfullyUpdatedPaymentMethod: 'Usted ha actualizado exitosamente el método de pago.',
    successfullyAddPaymentMethod: 'Usted ha agregado exitosamente el método de pago.',
    successfullyAddModuleToCard: 'Usted ha agregado exitosamente el módulo al carrito de compras.',
    moduleNotVisibleOrActive: 'Este módulo no está visible o activo!',
    failedLogin: 'Fallo en inicio de sesión',
    invalidUsernamePassword: 'Nombre de usuario o contraseña inválidos',
    successfullySwitchAccount: 'Usted ha cambiado exitosamente de cuenta',
    copy: 'Copiar',
    copyMessage: 'Copiar al portapapeles',
    quizCompleted: 'Prueba Completa',
    activeQuestion: (current, total) => `Pregunta ${current} de ${total}`,
    skipQuiz: 'Saltar Prueba',
    retakeQuiz: 'Retomar Prueba',
    score: 'Puntuación',
    points: 'Puntos',
    youScoreIs: 'Su puntuación es',
    answerTo: (title) => `¿Respuesta a "${title}" es?`,
    yourAnswerTo: (title) => `¿ Su respuesta a "${title}" es?`,
    loadingDocument: 'Cargando documento',
    downloadCertificate: 'Descargando Certificado',
    correct: 'Correcto',
    wrong: 'Equivocado',
    loadMore: 'Cargar Mas',
    noQuizResultMessage: 'No hay resultados de pruebas para este curso',
    footerText:
      'OneAcademy es una innovadora plataforma de aprendizaje electrónico que cubre una amplia gama de temas financieros, incluidos el comercio, la bolsa de valores, la criptomoneda, el análisis financiero, la gestión de activos y más.',
    termsConditions: 'Términos y condiciones',
    allRightReserved: 'Todos los derechos reservados',
    changeAccount: 'Cambiar Cuenta',
    currentAccount: 'Cuenta Corriente',
    certificate: 'Certificado',
    ofCompletion: 'de Finalización',
    founderCeo: 'Fundador & Presidente',
    certificateSuccess: 'Ha completado exitosamente con distinción el Curso OneAcademy',
    open: 'Abierto',
    theEnd: 'Fin',
    upNext: 'A continuación',
    openPff: 'Haga clic para abrir el PDF.',
    buyPackage: 'Comprar Paquete',
    UpgradePackageText: 'Actualice su paquete para desbloquear este curso.',
  },

  ru: {
    hello: 'Привет',
    nextPage: 'Следующая страница',
    previousPage: 'Предыдущая страница',
    next: 'Далее',
    previous: 'Назад',
    yes: 'Да',
    no: 'Нет',
    home: 'В начало',
    about: 'О программе',
    courses: 'Курсы',
    products: 'Продукты',
    getStarted: 'Приступить к работе',
    startNow: 'Начать прямо сейчас',
    dashboard: 'Панель управления',
    product: 'Продукт',
    experts: 'Эксперты',
    language: 'Язык',
    badges: 'Значки',
    earnedBadges: 'Заработанные значки',
    editProfile: 'Редактировать профиль',
    profile: 'Профиль',
    resources: 'Ресурсы',
    aboutCourseTab: 'О программе',
    quizResultsCourseTab: 'Мои результаты теста',
    continueLearning: 'Продолжить обучение',
    seeAll: 'Показать все',
    seeMore: 'Показать еще',
    completed: 'Завершено',
    friendsRankList: 'Список друзей',
    recommendedToYou: 'Рекомендуется вам',
    peopleWatching: 'Что смотрят люди',
    lessons: 'Уроки',
    myCourses: 'Мои курсы',
    expertTeacher: 'Опытный учитель',
    viewProfile: 'Просмотреть профиль',
    settings: 'Настройки',
    paymentMethods: 'Способы оплаты',
    signIn: 'Вход',
    signInToOneAcademy: 'Войти в OneAcademy',
    signOut: 'Выйти',
    pleaseFillFetailsBelow: 'Пожалуйста, заполните подробную информацию ниже',
    username: 'Имя пользователя',
    password: 'Пароль',
    forgotPassword: 'Забыли пароль',
    shoppingCart: 'Корзина',
    item: 'Товар',
    price: 'Цена',
    fee: 'сбор',
    total: 'Всего',
    viewFullCart: 'Просмотреть полную корзину',
    personalInformation: 'Личная информация',
    contactInformation: 'Контактные данные',
    idInformation: 'Идентификационная информация',
    firstName: 'Имя',
    lastName: 'Фамилия',
    countryOfBirth: 'Страна рождения',
    cityOfBirth: 'Город рождения',
    dateOfBirth: 'Дата рождения',
    idNumber: 'Идентификационный номер',
    idType: 'Тип идентификатора',
    idExpiration: 'Дата истечения срока действия',
    emailAddress: 'Адрес электронной почты',
    phoneNumber: 'Номер телефона',
    address: 'Адрес',
    country: 'Страна',
    city: 'Город',
    postalCode: 'Почтовый индекс',
    updateProfile: 'Обновить профиль',
    submit: 'Подавать',
    cancel: 'Отменить',
    save: 'Сохранить',
    edit: 'Редактировать',
    bankDetails: 'Банковские реквизиты',
    accountHolderName: 'Имя владельца учетной записи',
    bankName: 'Название банка',
    bankCountry: 'Страна банка',
    ibanAccount: 'Номер счета IBAN',
    bicSwiftCode: 'Код BIC / SWIFT',
    bank: 'Банк',
    bankProvince: 'Регион банка',
    bankCity: 'Город банка',
    bankBranch: 'Отделение банка',
    cardHolderName: 'Имя владельца карты',
    cardNumber: 'Номер карты',
    accountNumber: 'Номер счета',
    all: 'Все',
    meetТheExperts: 'Познакомьтесь с экспертами',
    accomplishments: 'Достижения',
    biography: 'Биография',
    ourProducts: 'Наши продукты',
    giftCode: 'Код подарочного сертификата',
    redeem: 'Активировать',
    redeemGiftCode: 'Активировать код подарочного сертификата',
    redeemed: 'Активировано',
    redeemedBy: 'Кем активировано',
    notRedeemed: 'Не активировано',
    notPaid: 'Не оплачено',
    buyModule: 'Купить модуль',
    myModules: 'Мои модули',
    moduleName: 'Название модуля',
    purchaseHistory: 'История покупок',
    educationalModules: 'Образовательные модули',
    searchModules: 'Поиск модулей',
    sortByValue: 'Сортировка по: значению',
    sortByDate: 'Сортировка по: дате',
    sortByName: 'Сортировка по: названию',
    noModulesForThisSearch: 'По этому запросу модулей не найдено',
    noForPurchaseHistoryThisSearch: 'История заказов по этому запросу не найдена',
    withActivationFee: 'С платой за активацию',
    addToCard: 'Добавить в корзину',
    cartTotal: 'Всего в корзине',
    checkOutNow: 'Оформить заказ сейчас',
    bankWireTransfer: 'Банковский перевод',
    bankWireP1:
      'В связи с вашим заявлением на банковский перевод для покупки пакета NewLife команда OneLife хотела бы любезно направить вас к официальному участнику OneLife в вашем регионе, который произведет оплату за вас.',
    bankWireP2:
      'Данная процедура необходима для того, чтобы мы могли обеспечить эффективную и своевременную обработку вашего платежа.',
    bankWireP3: 'Вы можете воспользоваться банковским переводом, связавшись с дистрибьютером:',
    bankWireP4: 'Пожалуйста, не забудьте указать следующую информацию:',
    bankWireP5: 'В качестве альтернативы вы можете использовать один из других доступных способов оплаты!',
    bankWireP6: 'Спасибо вам за ваше понимание!',
    bankWireP7: 'С наилучшими пожеланиями,',
    bankWireP8: 'Команда OneLife',
    cashWallet: 'Кошелек',
    ballance: 'Баланс',
    purchaseWith: 'Покупка с помощью',
    yourOrderCompletedSuccessfully: 'Ваш заказ успешно оформлен!',
    orderFail: 'Заказ не выполнен!',
    yourOrderCompletedSuccessfullyMsg:
      'На ваш адрес электронной почты было отправлено электронное письмо с подробной информацией о вашем заказе. Пожалуйста, сохраните его в своих записях и ознакомьтесь с инструкциями для дальнейших действий.',
    orderFailMsg: 'Пожалуйста, свяжитесь с нашим агентом службы поддержки для получения более подробной информации',
    backToShop: 'Вернуться в магазин',
    businessVolume: 'Объем бизнеса',
    whatBusinessVolume: 'Каков объем бизнеса',
    tokens: 'Токены',
    howManyTokensGet: 'Сколько токенов вы получите?',
    splits: 'Сплит',
    theAmountPeopleBelow: 'Количество людей ниже',
    difficultyReduction: 'Снижение сложности',
    immediateMining: 'Немедленный майнинг',
    isAllowed: 'Разрешено',
    isNotAllowed: 'Запрещено',
    bonusApplicableFor: 'Бонус, применимый к',
    network: 'Сеть',
    matching: 'Соответствие',
    level1st: '1-й уровень',
    level2nd: '2-й уровень',
    level3rd: '3-й уровень',
    level4th: '4-й уровень',
    bonusCap: 'Бонусный лимит',
    newlifePrice: 'Стоимость Newlife',
    success: 'Успех',
    warningFail: 'Предупреждение и ошибка',
    warning: 'Предупреждение',
    noticeUpdate: 'Уведомление и обновление',
    successfullyRedeemPackage: 'Вы успешно активировали модуль.',
    successfullyEditedProfile: 'Вы успешно отредактировали свой профиль.',
    successfullyUpdatedPaymentMethod: 'Вы успешно обновили способ оплаты.',
    successfullyAddPaymentMethod: 'Вы успешно добавили способ оплаты.',
    successfullyAddModuleToCard: 'Вы успешно добавили модуль в корзину.',
    moduleNotVisibleOrActive: 'Этот модуль не виден или не активен!',
    failedLogin: 'Ошибка входа в систему',
    invalidUsernamePassword: 'Неправильное имя пользователя или пароль',
    successfullySwitchAccount: 'Вы успешно сменили учетную запись',
    copy: 'Копировать',
    copyMessage: 'Копировать в буфер обмена',
    quizCompleted: 'Тест завершен',
    activeQuestion: (current, total) => `Вопрос ${current} из ${total}`,
    skipQuiz: 'Пропустить тест',
    retakeQuiz: 'Повторить тест',
    score: 'Рейтинг',
    points: 'Баллы',
    youScoreIs: 'Ваш рейтинг',
    answerTo: (title) => `Ответить на "${title}"?`,
    yourAnswerTo: (title) => `Ваш ответ на "${title}"?`,
    loadingDocument: 'Загрузить документ',
    downloadCertificate: 'Скачать сертификат',
    correct: 'Правильно',
    wrong: 'Неправильно',
    loadMore: 'Загрузить еще',
    noQuizResultMessage: 'Для этого курса нет результатов теста.',
    footerText:
      'OneAcademy – это инновационная платформа электронного обучения, охватывающая широкий спектр финансовых вопросов, включая темы, связанные с торговлей, фондовой биржей, криптовалютой, финансовым анализом, управлением активами, а также много другое.',
    termsConditions: 'Условия и положения',
    allRightReserved: 'Все права защищены',
    changeAccount: 'Изменить учетную запись',
    currentAccount: 'Текущая учетная запись',
    certificate: 'Сертификат',
    ofCompletion: 'о завершении',
    founderCeo: 'Основатель и генеральный директор',
    certificateSuccess: 'Успешно окончил с отличием курс OneAcademy',
    open: 'Открыть',
    theEnd: 'Конец',
    upNext: 'Далее',
    openPff: 'Нажмите, чтобы открыть файл PDF.',
    buyPackage: 'Купить пакет',
    UpgradePackageText: 'Обновите свой пакет, чтобы разблокировать этот курс.',
  },

  pt: {
    hello: 'Olá,',
    nextPage: 'Página seguinte',
    previousPage: 'Página anterior',
    next: 'Seguinte',
    previous: 'Anterior',
    yes: 'Sim',
    no: 'Não',
    home: 'Home',
    about: 'Acerca de',
    courses: 'Cursos',
    products: 'Produtos',
    getStarted: 'Iniciar',
    startNow: 'Iniciar Agora',
    dashboard: 'Painel',
    product: 'Produto',
    experts: 'Especialistas',
    language: 'Idioma',
    badges: 'Crachás',
    earnedBadges: 'Crachás Ganhos',
    editProfile: 'Editar Perfil',
    profile: 'Perfil',
    resources: 'Recursos',
    aboutCourseTab: 'Acerca de',
    quizResultsCourseTab: 'Os meus resultados do quiz',
    continueLearning: 'Continuar a Aprender',
    seeAll: 'Ver tudo',
    seeMore: 'Ver mais',
    completed: 'Completado',
    friendsRankList: 'Ranking de amigos',
    recommendedToYou: 'Recomendado para si',
    peopleWatching: 'Pessoas Assistindo',
    lessons: 'Lições',
    myCourses: 'Os meus Cursos',
    expertTeacher: 'Professor Especialista',
    viewProfile: 'Ver Perfil',
    settings: 'Configurações',
    paymentMethods: 'Métodos de Pagamento',
    signIn: 'Registe-se',
    signInToOneAcademy: 'Registe-se na OneAcademy',
    signOut: 'Sair',
    pleaseFillFetailsBelow: 'Por favor preencher detalhes abaixo',
    username: 'Nome de Utilizador',
    password: 'Password',
    forgotPassword: 'Esqueci-me da password',
    shoppingCart: 'Carrinho de Compras',
    item: 'Item',
    price: 'Preço',
    fee: 'taxa',
    total: 'Total',
    viewFullCart: 'Ver o Carrinho Completo',
    personalInformation: 'Informações Pessoais',
    contactInformation: 'Informação de Contacto',
    idInformation: 'Informação ID',
    firstName: 'Primeiro Nome',
    lastName: 'Último Nome',
    countryOfBirth: 'País Natal',
    cityOfBirth: 'Cidade Natal',
    dateOfBirth: 'Data de Nascimento',
    idNumber: 'Número de ID',
    idType: 'Tipo de ID',
    idExpiration: 'Data de Validade',
    emailAddress: 'Endereço de Email',
    phoneNumber: 'Número de Telefone',
    address: 'Endereço',
    country: 'País',
    city: 'Cidade',
    postalCode: 'Código Postal',
    updateProfile: 'Actualizar perfil',
    submit: 'Enviar',
    cancel: 'Cancelar',
    save: 'Guardar',
    edit: 'Editar',
    bankDetails: 'Detalhes Bancários',
    accountHolderName: 'Nome do Detentor da Conta',
    bankName: 'Nome do Banco',
    bankCountry: 'País do Banco',
    ibanAccount: 'Conta IBAN',
    bicSwiftCode: 'Código BIC / SWIFT',
    bank: 'Banco',
    bankProvince: 'Província do Banco',
    bankCity: 'Cidade do Banco',
    bankBranch: 'Ramo Bancário',
    cardHolderName: 'Nome do Detentor do Cartão',
    cardNumber: 'Número do Cartão',
    accountNumber: 'Número da Conta',
    all: 'Tudo',
    meetТheExperts: 'Conhece os Especialistas',
    accomplishments: 'Realizações',
    biography: 'Biografia',
    ourProducts: 'Os Nossos Produtos',
    giftCode: 'Código de Oferta',
    redeem: 'Resgatar',
    redeemGiftCode: 'Resgatar Código de Oferta',
    redeemed: 'Resgatado',
    redeemedBy: 'Resgatado Por',
    notRedeemed: 'Não Resgatado',
    notPaid: 'Não Pago',
    buyModule: 'Comprar Módulo',
    myModules: 'Os meus Módulos',
    moduleName: 'Nome do Módulo',
    purchaseHistory: 'Histórico de Compras',
    educationalModules: 'Módulos Educacionais',
    searchModules: 'Procurar Módulos',
    sortByValue: 'Ordenado por: Valor',
    sortByDate: 'Ordenado por: Data',
    sortByName: 'Ordenado por: Nome',
    noModulesForThisSearch: 'Não existem módulos para esta busca',
    noForPurchaseHistoryThisSearch: 'Não há histórico de encomendas para esta busca',
    withActivationFee: 'Com taxa de activação',
    addToCard: 'Adicionar ao cartão',
    cartTotal: 'Total do Carrinho',
    checkOutNow: 'Finalizar agora',
    bankWireTransfer: 'Transferência Bancária',
    bankWireP1:
      'No Seguimento Da Sua Transferência Bancária Para Compra Do NewLife Package, A Equipa OneLife Terá Muito Gosto Em Indicar-lhe Um Membro Oficial OneLife Local Que Tratará do Pagamento Consigo.',
    bankWireP2:
      'Este Procedimento É Necessário Para Garantir Que O Seu Pagamento Será Processado Eficazmente E Em Tempo Útil.',
    bankWireP3: 'Pode Usar O Pagamento Por Transferência Bancária Contactando o Distribuidor:',
    bankWireP4: 'Pode Usar O Pagamento Por Transferência Bancária Contactando o Distribuidor:',
    bankWireP5: 'Alternativamente Poderá Utilizar Um Dos Outros Métodos De Pagamento Disponíveis!',
    bankWireP6: 'Obrigado Pela Sua Compreensão!',
    bankWireP7: 'Melhores Cumprimentos,',
    bankWireP8: 'A Equipa OneLife',
    cashWallet: 'A Equipa OneLife',
    ballance: 'Balanço',
    purchaseWith: 'Compra com',
    yourOrderCompletedSuccessfully: 'A sua encomenda foi concluída com sucesso!',
    orderFail: 'Encomenda falhada!',
    yourOrderCompletedSuccessfullyMsg:
      'Um email com os detalhes da sua encomenda foi enviado para o seu endereço de email. Por favor conserve este email e siga as instruções para os próximos passos.',
    orderFailMsg: 'Por favor consulte o nosso apoio ao cliente para mais detalhes em',
    backToShop: 'De volta às Compras',
    businessVolume: 'Volume de Negócios',
    whatBusinessVolume: 'Qual é o volume de Negócios',
    tokens: 'Tokens',
    howManyTokensGet: 'Quantos tokens obtém?',
    splits: 'Divisões',
    theAmountPeopleBelow: 'A quantidade de pessoas abaixo',
    difficultyReduction: 'Redução da Dificuldade',
    immediateMining: 'Extracção Imediata',
    isAllowed: 'Permitido',
    isNotAllowed: 'Não Permitido',
    bonusApplicableFor: 'Bónus Aplicável a',
    network: 'Rede',
    matching: 'Compatibilidade',
    level1st: '1º Nível',
    level2nd: '2º Nível',
    level3rd: '3º Nível',
    level4th: '4º Nível',
    bonusCap: 'Cap de Bónus',
    newlifePrice: 'Preço Newlife',
    success: 'Sucesso',
    warningFail: 'Aviso & Falha',
    warning: 'Aviso',
    noticeUpdate: 'Notícia & Actualização',
    successfullyRedeemPackage: 'Resgatou o módulo com sucesso.',
    successfullyEditedProfile: 'O seu perfil foi editado com sucesso.',
    successfullyUpdatedPaymentMethod: 'Actualizou o método de pagamento com sucesso.',
    successfullyAddPaymentMethod: 'Adicionou um método de pagamento com sucesso.',
    successfullyAddModuleToCard: 'Adicionou um módulo ao carrinho de compras com sucesso.',
    moduleNotVisibleOrActive: 'Este módulo não está visível ou activo!',
    failedLogin: 'Falha no login',
    invalidUsernamePassword: 'Nome de utilizador ou password inválidos',
    successfullySwitchAccount: 'Alterou a conta com sucesso',
    copy: 'Copiar',
    copyMessage: 'Copiar para a área de transferência',
    quizCompleted: 'Quiz Completado',
    activeQuestion: (current, total) => `Questão ${current} of ${total}`,
    skipQuiz: 'Saltar o Quiz',
    retakeQuiz: 'Repetir o Quiz',
    score: 'Pontuação',
    points: 'Pontos',
    youScoreIs: 'A sua pontuação é',
    answerTo: (title) => `A resposta a "${title}" é?`,
    yourAnswerTo: (title) => `A sua resposta a "${title}" é?`,
    loadingDocument: 'A carregar documento',
    downloadCertificate: 'Descarregar o Certificado',
    correct: 'Correcto',
    wrong: 'Errado',
    loadMore: 'Carregar Mais',
    noQuizResultMessage: 'Não há resultados de quiz para este curso.',
    footerText:
      'A OneAcademy é uma plataforma inovadora de e-learning que cobre uma ampla gama de tópicos financeiros incluindo trading, bolsa de acções, cripto moeda, análise financeira, gestão de activos, e outros.',
    termsConditions: 'Termos e Condições',
    allRightReserved: 'Todos os direitos reservados',
    changeAccount: 'Mudar Conta',
    currentAccount: 'Conta Actual',
    certificate: 'Certificado',
    ofCompletion: 'de Conclusão',
    founderCeo: 'Fundador & CEO',
    certificateSuccess: 'Completou com sucesso e distinção o Curso OneAcademy',
    open: 'Aberto',
    theEnd: 'Fim',
    upNext: 'Próximo',
    openPff: 'Clique para abrir o PDF.',
    buyPackage: 'Comprar Pacote',
    UpgradePackageText: 'Faça um upgrade ao seu pacote para desbloquear este curso.',
  },

  de: {
    hello: 'Hallo,',
    nextPage: 'Nächste Seite',
    previousPage: 'Vorherige Seite',
    next: 'Weiter',
    previous: 'Vorherige',
    yes: 'Ja',
    no: 'Nein',
    home: 'Home',
    about: 'Über',
    courses: 'Kurse',
    products: 'Produkte',
    getStarted: 'Los legen',
    startNow: 'Jetzt beginnen',
    dashboard: 'Dashboard',
    product: 'Produkt',
    experts: 'Experten',
    language: 'Sprache',
    badges: 'Abzeichen',
    earnedBadges: 'Verdiente Abzeichen',
    editProfile: 'Profil bearbeiten',
    profile: 'Profil',
    resources: 'Ressourcen',
    aboutCourseTab: 'Über',
    quizResultsCourseTab: 'Meine Quiz-Ergebnisse',
    continueLearning: 'Weiterhin lernen',
    seeAll: 'Alles sehen',
    seeMore: 'Mehr sehen',
    completed: 'Abgeschlossen',
    friendsRankList: 'Freunde Rangliste',
    recommendedToYou: 'Für Sie empfohlen',
    peopleWatching: 'Leute beobachten',
    lessons: 'Leute beobachten',
    myCourses: 'Meine Kurse',
    expertTeacher: 'Expertenlehrer',
    viewProfile: 'Profil ansehen',
    settings: 'Einstellungen',
    paymentMethods: 'Zahlungsmethoden',
    signIn: 'Anmelden',
    signInToOneAcademy: 'Bei OneAcademy anmelden',
    signOut: 'Abmelden',
    pleaseFillFetailsBelow: 'Bitte die Details unten ausfüllen',
    username: 'Benutzername',
    password: 'Passwort',
    forgotPassword: 'Ihr Passwort vergessen',
    shoppingCart: 'Warenkorb',
    item: 'Artikel',
    price: 'Preis',
    fee: 'Gebühr',
    total: 'Summe',
    viewFullCart: 'Vollständiger Warenkorb ansehen',
    personalInformation: 'Persönliche Information',
    contactInformation: 'Kontaktinformation',
    idInformation: 'Ausweis-Information',
    firstName: 'Vorname',
    lastName: 'Nachname',
    countryOfBirth: 'Geburtsland',
    cityOfBirth: 'Geburtsland',
    dateOfBirth: 'Geburtsdatum',
    idNumber: 'Ausweisnummer',
    idType: 'Art des Ausweises',
    idExpiration: 'Verfallsdatum',
    emailAddress: 'E-Mail Adresse',
    phoneNumber: 'Telefonnummer',
    address: 'Adresse',
    country: 'Land',
    city: 'Stadt',
    postalCode: 'Postleitzahl',
    updateProfile: 'Profil aktualisieren',
    submit: 'Einreichen',
    cancel: 'Beenden',
    save: 'Beenden',
    edit: 'Bearbeiten',
    bankDetails: 'Bankdetails',
    accountHolderName: 'Kontobesitzer-Name',
    bankName: 'Bankname',
    bankCountry: 'Land der Bank',
    ibanAccount: 'IBAN Konto',
    bicSwiftCode: 'BIC / SWIFT Code',
    bank: 'Bank',
    bankProvince: 'Bank-Provinz',
    bankCity: 'Bank-Stadt',
    bankBranch: 'Bankstelle',
    cardHolderName: 'Kartenhaltername',
    cardNumber: 'Kartennummer',
    accountNumber: 'Kontonummer',
    all: 'Alles',
    meetТheExperts: 'Die Experten treffen',
    accomplishments: 'Errungenschaften',
    biography: 'Biografie',
    ourProducts: 'Unsere Produkte',
    giftCode: 'Geschenkcode',
    redeem: 'Einlösen',
    redeemGiftCode: 'Geschenkecode einlösen',
    redeemed: 'eingelöst',
    redeemedBy: 'Eingelöst von',
    notRedeemed: 'Nicht eingelöst',
    notPaid: 'Nicht bezahlt',
    buyModule: 'Modul kaufen',
    myModules: 'Meine Module',
    moduleName: 'Modulname',
    purchaseHistory: 'Kaufverlauf',
    educationalModules: 'Bildende Module',
    searchModules: 'Module suchen',
    sortByValue: 'Module suchen',
    sortByDate: 'Sortiert nach: Datum',
    sortByName: 'Sortiert nach: Name',
    noModulesForThisSearch: 'Es gibt keine Module entsprechend dieser Suche',
    noForPurchaseHistoryThisSearch: 'Kein Bestellverlauf entsprechend dieser Suche gefunden',
    withActivationFee: 'Mit Aktivierungsgebühr',
    addToCard: 'Zum Warenkorb hinzufügen',
    cartTotal: 'Summe im Warenkorb',
    checkOutNow: 'Jetzt bezahlen',
    bankWireTransfer: 'Banküberweisung',
    bankWireP1:
      'Nach Ihrer Banküberweisung zum Kauf des NewLife-Pakets möchte das OneLife-Team Sie freundlicherweise an ein offizielles OneLife-Mitglied in Ihrer Region verweisen, das die Zahlung für Sie abwickeln wird.',
    bankWireP2:
      'Dieses Verfahren ist notwendig, damit wir sicherstellen können, dass Ihre Zahlung effektiv und pünktlich bearbeitet wird.',
    bankWireP3: 'Sie können die Banküberweisungszahlung verwenden indem Sie den Händler kontaktieren.',
    bankWireP4: 'Bitte vergessen Sie nicht, die folgenden Informationen anzugeben:',
    bankWireP5: 'Alternativ können Sie auch eine der anderen verfügbaren Zahlungsarten verwenden!',
    bankWireP6: 'Vielen Dank für Ihr Verständnis!',
    bankWireP7: 'Mit freundlichen Grüßen,',
    bankWireP8: 'Das OneLife Team',
    cashWallet: 'Bargeld Geldbörse',
    ballance: 'Bilanz',
    purchaseWith: 'Bilanz',
    yourOrderCompletedSuccessfully: 'Ihre Bestellung war erfolgreich!',
    orderFail: 'Bestellung fehlgeschlagen!',
    yourOrderCompletedSuccessfullyMsg:
      'Eine E-Mail mit den Details zu Ihrer Bestellung wurde an Ihre E-Mail-Adresse gesendet. Bitte bewahren Sie diese für Ihre Unterlagen auf und lesen Sie die Anweisungen für die weiteren Schritte.',
    orderFailMsg: 'Für weitere Einzelheiten wenden Sie sich bitte an unseren Kundendienst unter',
    backToShop: 'Zurück zum Shop',
    businessVolume: 'Business Volumem',
    whatBusinessVolume: 'Was ist das Business Volumen',
    tokens: 'Tokens',
    howManyTokensGet: 'Wie viele Tokens erhalten Sie?',
    splits: 'Teilen',
    theAmountPeopleBelow: 'Die Menge der Leute unten',
    difficultyReduction: 'Schwierigkeitsreduzierung',
    immediateMining: 'Umgehendes Mining',
    isAllowed: 'ist erlaubt',
    isNotAllowed: 'ist nicht erlaubt',
    bonusApplicableFor: 'Bonus anwendbar für',
    network: 'Netzwerk',
    matching: 'Passend',
    level1st: '1. Level',
    level2nd: '2. Level',
    level3rd: '3. Level',
    level4th: '4. Level',
    bonusCap: 'Bonus Cap',
    newlifePrice: 'Newlife Preis',
    success: 'Erfolg',
    warningFail: 'Warnung & Fehlgeschlagen',
    warning: 'Warnung',
    noticeUpdate: 'Hinweis & Update',
    successfullyRedeemPackage: 'Sie haben das Modul erfolgreich eingelöst.',
    successfullyEditedProfile: 'Sie haben erfolgreich Ihr Profil bearbeitet.',
    successfullyUpdatedPaymentMethod: 'Sie haben erfolgreich Ihre Zahlungsmethode aktualisiert.',
    successfullyAddPaymentMethod: 'Sie haben erfolgreich eine Zahlungsmethode hinzugefügt.',
    successfullyAddModuleToCard: 'Sie haben erfolgreich ein Modul zum Warenkorb hinzugefügt.',
    moduleNotVisibleOrActive: 'Dieses Modul ist nicht sichtbar oder aktiv!',
    failedLogin: 'Anmeldung fehlgeschlagen',
    invalidUsernamePassword: 'Ungültiger Benutzername oder Passwort',
    successfullySwitchAccount: 'Sie haben erfolgreich das Konto gewechselt',
    copy: 'Kopieren',
    copyMessage: 'Zum Klemmbrett kopieren',
    quizCompleted: 'Quiz abgeschlossen',
    activeQuestion: (current, total) => `Fage ${current} von ${total}`,
    skipQuiz: 'Ihre Punktezahl ist',
    retakeQuiz: 'Quiz erneut durchführen',
    score: 'Punktezahl',
    points: 'Punkte',
    youScoreIs: 'Ihre Punktezahl ist',
    answerTo: (title) => `Antwort auf "${title}" ist?`,
    yourAnswerTo: (title) => `Ihre Antwort auf "${title}" ist?`,
    loadingDocument: 'Dokument laden',
    downloadCertificate: 'Zertifikat herunterladen',
    correct: 'Richtig',
    wrong: 'Falsch',
    loadMore: 'Mehr laden',
    noQuizResultMessage: 'Es gibt keine Quiz-Ergebnisse für diesesn Kurs.',
    footerText:
      'OneAcademy ist eine innovative E-Learning-Plattform, die eine breite Palette von Finanzthemen abdeckt, darunter Handel, Börse, Kryptowährung, Finanzanalyse, Vermögensverwaltung und vieles mehr.',
    termsConditions: 'Bedingungen und Konditionen',
    allRightReserved: 'Alle Reche vorbehalten',
    changeAccount: 'Konto wechseln',
    currentAccount: 'Aktuelles Konto',
    certificate: 'Zertifikat',
    ofCompletion: 'der Vollendung',
    founderCeo: 'Gründer & CEO',
    certificateSuccess: 'Hat den Kurs von OneAcademy erfolgreich mit Auszeichnung abgeschlossen',
    open: 'Öffnen',
    theEnd: 'Das Ende',
    upNext: 'Das Nächste',
    openPff: 'Klicken um PDF zu öffnen.',
    buyPackage: 'Paket kaufen',
    UpgradePackageText: 'Ihr Paket aktualisieren um diesen Kurs freizuschalten.',
  },

  ro: {
    hello: 'Bună,',
    nextPage: 'Pagina următoare',
    previousPage: 'Pagina anterioară',
    next: 'Următorul',
    previous: 'Anterior',
    yes: 'Da',
    no: 'Nu',
    home: 'Acasă',
    about: 'Despre',
    courses: 'Cursuri',
    products: 'Produse',
    getStarted: 'Începeți',
    startNow: 'Începeți acum',
    dashboard: 'Tablou de bord',
    product: 'Produs',
    experts: 'Experți',
    language: 'Limba',
    badges: 'Insigne',
    earnedBadges: 'Insigne câștigate',
    editProfile: 'Editează profilul',
    profile: 'Profil',
    resources: 'Resurse',
    aboutCourseTab: 'Despre',
    quizResultsCourseTab: 'Rezultatele testului meu',
    continueLearning: 'Continuă să înveți',
    seeAll: 'Vezi toate',
    seeMore: 'Vezi mai multe',
    completed: 'Completat',
    friendsRankList: 'Lista de clasament a prietenilor',
    recommendedToYou: 'Recomandat pentru tine',
    peopleWatching: 'Ce se vizionează',
    lessons: 'Lecții',
    myCourses: 'Cursurile mele',
    expertTeacher: 'Profesor expert',
    viewProfile: 'Vizualizează profilul',
    settings: 'Setări',
    paymentMethods: 'Modalități de plată',
    signIn: 'Autentificare',
    signInToOneAcademy: 'Conectați-vă la OneAcademy',
    signOut: 'Deconectați-vă',
    pleaseFillFetailsBelow: 'Vă rugăm să completați datele de mai jos',
    username: 'Nume de utilizator',
    password: 'Parola',
    forgotPassword: 'Ați uitat parola',
    shoppingCart: 'Coș de cumpărături',
    item: 'Articol',
    price: 'Preț',
    fee: 'taxă',
    total: 'Total',
    viewFullCart: 'Vizualizează coșul plin',
    personalInformation: 'Informații personale',
    contactInformation: 'Informații de contact',
    idInformation: 'Informații de identificare',
    firstName: 'Prenume',
    lastName: 'Nume de familie',
    countryOfBirth: 'Țara de naștere',
    cityOfBirth: 'Localitate de naștere',
    dateOfBirth: 'Data de naștere',
    idNumber: 'Număr și serie document de identitate',
    idType: 'Tip document de identitate',
    idExpiration: 'Data de expirare',
    emailAddress: 'Adresă de email',
    phoneNumber: 'Număr de telefon',
    address: 'Adresă',
    country: 'Țară',
    city: 'Localitate',
    postalCode: 'Cod poștal',
    updateProfile: 'Actualizează profilul',
    submit: 'Trimite',
    cancel: 'Anulare',
    save: 'Salvare',
    edit: 'Editare',
    bankDetails: 'Detalii bancare',
    accountHolderName: 'Nume titular',
    bankName: 'Numele băncii',
    bankCountry: 'Țară de proveniență a băncii',
    ibanAccount: 'IBAN',
    bicSwiftCode: 'BIC / SWIFT',
    bank: 'Banca',
    bankProvince: 'Județul băncii',
    bankCity: 'Localitatea băncii',
    bankBranch: 'Sucursală bancă',
    cardHolderName: 'Numele titularului de card',
    cardNumber: 'Numărul cardului',
    accountNumber: 'Număr de cont',
    all: 'Toate',
    meetТheExperts: 'Faceți cunoștință cu experții',
    accomplishments: 'Realizări',
    biography: 'Biografie',
    ourProducts: 'Produsele noastre',
    giftCode: 'Cod cadou',
    redeem: 'Răscumpără',
    redeemGiftCode: 'Răscumpără codul cadou',
    redeemed: 'Răscumpărat',
    redeemedBy: 'Răscumpărat de',
    notRedeemed: 'Nerăscumpărat',
    notPaid: 'Neplătit',
    buyModule: 'Cumpără Module',
    myModules: 'Modulele mele',
    moduleName: 'Denumirea modulului',
    purchaseHistory: 'Istorie achiziții',
    educationalModules: 'Module educative',
    searchModules: 'Caută module',
    sortByValue: 'Sortează după: Valoare',
    sortByDate: 'Sortează după: Dată',
    sortByName: 'Sortează după: Nume',
    noModulesForThisSearch: 'Nu au rezultat module din această căutare',
    noForPurchaseHistoryThisSearch: 'Nu s-a găsit niciun istoric al comenzilor pentru această căutare',
    withActivationFee: 'Cu taxă de activare',
    addToCard: 'Adaugă pe card',
    cartTotal: 'Total coș',
    checkOutNow: 'Check out acum',
    bankWireTransfer: 'Transfer Bancar',
    bankWireP1:
      'În Urma Solicitării Dvs. De Transfer Bancar Pentru Achiziționarea Pachetului Newlife, Echipa Onelife Dorește Să Vă Trimită Cu Amabilitate La Un Membru Oficial Onelife Din Regiunea Dvs. Care Se Va Ocupa De Plată Pentru Dvs.',
    bankWireP2:
      'Această Procedură Este Necesară Pentru A Ne Putea Asigura Că Plata Dvs. Va Fi Procesată Eficient Și La Timp.',
    bankWireP3: 'Puteți Utiliza Plata Prin Transfer Bancar Contactând Distribuitorul:',
    bankWireP4: 'Vă Rugăm Să Nu Uitați Să Includeți Următoarele Informații:',
    bankWireP5: 'Alternativ, Puteți Utiliza Una Dintre Celelalte Metode De Plată Disponibile!',
    bankWireP6: 'Vă Mulțumim pentru Înțelegere!',
    bankWireP7: 'Cu stimă,',
    bankWireP8: 'Echipa OneLife',
    cashWallet: 'Echipa OneLife',
    ballance: 'Bilanț',
    purchaseWith: 'Achiziționează cu',
    yourOrderCompletedSuccessfully: 'Comanda dvs. a fost finalizată cu succes!',
    orderFail: 'Comandă eșuată!',
    yourOrderCompletedSuccessfullyMsg:
      'Un e-mail cu detalii despre comanda dvs. v-a fost trimis la adresa de e-mail. Vă rugăm să îl păstrați pentru arhiva dvs. și să verificați instrucțiunile în legătură cu pașii următori.',
    orderFailMsg: 'Vă rugăm să contactați agentul nostru de asistență pentru mai multe detalii la',
    backToShop: 'Înapoi la magazin',
    businessVolume: 'Volum de afaceri',
    whatBusinessVolume: 'Care este volumul de afaceri',
    tokens: 'Jetoane',
    howManyTokensGet: 'Câte jetoane ai obținut?',
    splits: 'Separări',
    theAmountPeopleBelow: 'Numărul de persoane de mai jos',
    difficultyReduction: 'Reducerea Dificultății',
    immediateMining: 'Mineritul imediat',
    isAllowed: 'este Permis',
    isNotAllowed: 'nu este Permis',
    bonusApplicableFor: 'Bonus Aplicabil pentru',
    network: 'Rețea',
    matching: 'Potrivire',
    level1st: 'Primul nivel',
    level2nd: 'Al doilea nivel',
    level3rd: 'Al treilea nivel',
    level4th: 'Al patrulea nivel',
    bonusCap: 'Bonus Cap',
    newlifePrice: 'Preț Newlife',
    success: 'Succes',
    warningFail: 'Avertisment & Eșec',
    warning: 'Avertisment',
    noticeUpdate: 'Notificare & Actualizare',
    successfullyRedeemPackage: 'Ați reușit să răscumpărați cu succes modulul.',
    successfullyEditedProfile: 'Ați reușit să vă editați cu succes profilul.',
    successfullyUpdatedPaymentMethod: 'Ați actualizat cu succes metoda de plată.',
    successfullyAddPaymentMethod: 'Ați adăugat cu succes metoda de plată.',
    successfullyAddModuleToCard: 'Ați adăugat cu succes modulul în coșul de cumpărături.',
    moduleNotVisibleOrActive: 'Acest modul nu este vizibil sau activ!',
    failedLogin: 'Autentificare eșuată',
    invalidUsernamePassword: 'Nume de utilizator sau parolă invalide',
    successfullySwitchAccount: 'Ați schimbat contul cu succes',
    copy: 'Copiază',
    copyMessage: 'Copiază în clipboard',
    quizCompleted: 'Test Completat',
    activeQuestion: (current, total) => `Întrebare ${current} de ${total}`,
    skipQuiz: 'Omiteți Testul',
    retakeQuiz: 'Refaceți Testul',
    score: 'Punctaj',
    points: 'Puncte',
    youScoreIs: 'Punctajul tău este',
    answerTo: (title) => `Răspunsul la ‘${title}’ este?`,
    yourAnswerTo: (title) => `Răspunsul tău la ‘${title}’ este?`,
    loadingDocument: 'Documentul se încarcă',
    downloadCertificate: 'Descarcă Certificatul',
    correct: 'Corect',
    wrong: 'Greșit',
    loadMore: 'Încarcă mai multe',
    noQuizResultMessage: 'Nu există rezultate ale testelor pentru acest curs',
    footerText:
      'OneAcademy este o platformă inovatoare de e-learning care acoperă o gamă largă de subiecte financiare, inclusiv tranzacționarea, bursa de valori, criptomonedele, analiza financiară, gestionarea activelor și multe altele.',
    termsConditions: 'Termeni și condiții',
    allRightReserved: 'Toate drepturile rezervate',
    changeAccount: 'Schimbă Contul',
    currentAccount: 'Contul curent',
    certificate: 'Certificate',
    ofCompletion: 'de Finalizare',
    founderCeo: 'Fondator & CEO',
    certificateSuccess: 'A absolvit cu succes și cu distincție cursul OneAcademy',
    open: 'Deschide',
    theEnd: 'Sfârșit',
    upNext: 'Up Next',
    openPff: 'Faceți clic pentru a deschide PDF-ul.',
    buyPackage: 'Achiziționează Pachet',
    UpgradePackageText: 'Upgradează-ți pachetul pentru a debloca acest curs.',
  },

  it: {
    hello: 'Ciao,',
    nextPage: 'Pagina successiva',
    previousPage: 'Pagina precedente',
    next: 'Successivo',
    previous: 'Precedente',
    yes: 'Si',
    no: 'No',
    home: 'Pagina iniziale',
    about: 'Chi siamo',
    courses: 'Corsi',
    products: 'Prodotti',
    getStarted: 'Iniziamo',
    startNow: 'Inizia ora',
    dashboard: 'Schermata iniziale',
    product: 'Prodotto',
    experts: 'Esperto',
    language: 'Linguaggio',
    badges: 'Tesserini',
    earnedBadges: 'Tesserini guadagnati',
    editProfile: 'Modifica il profilo',
    profile: 'Profilo',
    resources: 'Risorse',
    aboutCourseTab: 'Scheda corso',
    quizResultsCourseTab: 'Risultati quiz',
    continueLearning: 'Continua l’apprendimento',
    seeAll: 'Vedi tutti',
    seeMore: 'Vedi di più',
    completed: 'Completato',
    friendsRankList: 'Classifica amici',
    recommendedToYou: 'Consigliato per te',
    peopleWatching: 'Persone che guardano',
    lessons: 'Lezioni',
    myCourses: 'I miei corsi',
    expertTeacher: 'Insegnante esperto',
    viewProfile: 'Visualizza il profilo',
    settings: 'Impostazioni',
    paymentMethods: 'Metodi di pagamento',
    signIn: 'Accedere',
    signInToOneAcademy: 'Accedere a OneAcademy',
    signOut: 'Uscire',
    pleaseFillFetailsBelow: 'Si prega di compilare i seguenti dettagli',
    username: 'Nome utente',
    password: 'Password',
    forgotPassword: 'Password dimenticata',
    shoppingCart: 'Carrello della spesa',
    item: 'Articolo',
    price: 'Prezzo',
    fee: 'commissione',
    total: 'Totale',
    viewFullCart: 'Visualizza carrello completo',
    personalInformation: 'Informazioni personali',
    contactInformation: 'Informazioni contatto',
    idInformation: 'Informazioni ID',
    firstName: 'Nome',
    lastName: 'Cognome',
    countryOfBirth: 'Paese di nascita',
    cityOfBirth: 'Città di Nascita',
    dateOfBirth: 'Data di Nascita',
    idNumber: 'Numero ID',
    idType: 'Tipo ID',
    idExpiration: 'Data scadenza',
    emailAddress: 'Indirizzo email',
    phoneNumber: 'Numero telefono',
    address: 'Indirizzo',
    country: 'Paese',
    city: 'Città',
    postalCode: 'Codice postale',
    updateProfile: 'Aggiorna profilo',
    submit: 'Invia',
    cancel: 'Cancella',
    save: 'Salva',
    edit: 'Modifica',
    bankDetails: 'Dettagli bancari',
    accountHolderName: 'Nome titolare conto',
    bankName: 'Nome banca',
    bankCountry: 'Paese banca',
    ibanAccount: 'Conto IBAN',
    bicSwiftCode: 'BIC / codice SWIFT',
    bank: 'Banca',
    bankProvince: 'Provincia Banca',
    bankCity: 'Città Banca',
    bankBranch: 'Filiale Banca',
    cardHolderName: 'Nome Titolare Carta',
    cardNumber: 'Numero Carta',
    accountNumber: 'Numero Conto',
    all: 'Tutti',
    meetТheExperts: 'Incontra gli esperti',
    accomplishments: 'Risulta',
    biography: 'Biografia',
    ourProducts: 'I nostri prodotti',
    giftCode: 'Codice Regalo',
    redeem: 'Riscuotere',
    redeemGiftCode: 'Riscuotere codice regalo',
    redeemed: 'Riscosso',
    redeemedBy: 'Riscosso Da',
    notRedeemed: 'Non Riscosso',
    notPaid: 'Non Pagato',
    buyModule: 'Comprare il Modulo',
    myModules: 'I Miei Moduli',
    moduleName: 'Nome Modulo',
    purchaseHistory: 'Storia Acquisti',
    educationalModules: 'Moduli Educativi',
    searchModules: 'Cerca Moduli',
    sortByValue: 'Ordina per: Valore',
    sortByDate: 'Ordina per: Data',
    sortByName: 'Ordina per: Nome',
    noModulesForThisSearch: 'Non ci sono moduli da questa ricerca',
    noForPurchaseHistoryThisSearch: 'Nessuna cronologia di ordine trovata in questa ricerca',
    withActivationFee: 'Con quota di attivazione',
    addToCard: 'Aggiungi al carrello',
    cartTotal: 'Totale Carrello',
    checkOutNow: 'Controlla adesso',
    bankWireTransfer: 'Bonifico Bancario',
    bankWireP1:
      'A seguito della richiesta di bonifico bancario per l’acquisto del pacchetto NewLife, il team OneLife vorrebbe gentilmente indirizzarti a un loro membro ufficiale nella tua regione che gestirà il pagamento per te',
    bankWireP2:
      'Questa procedura è necessaria per assicurarsi che il pagamento venga elaborato in modo efficiente e puntale.',
    bankWireP3: 'Puoi usare il pagamento tramite bonifico bancario contattando il distributore:',
    bankWireP4: 'Si prega di non dimenticare di includere le seguenti informazioni:',
    bankWireP5: 'In alternativa, puoi utilizzare uno degli altri metodi di pagamento disponibili!',
    bankWireP6: 'Grazie per la tua comprensione!',
    bankWireP7: 'Cordiali Saluti,',
    bankWireP8: 'Il Team OneLife',
    cashWallet: 'Portafoglio contanti',
    ballance: 'Saldo',
    purchaseWith: 'Acquisto con',
    yourOrderCompletedSuccessfully: 'Il tuo ordine è stato completato con successo!',
    orderFail: 'Ordine fallito!',
    yourOrderCompletedSuccessfullyMsg:
      'E’ stata mandata una email con i dettagli dell’ordine al tuo indirizzo email. Sei pregato di conservarla e controllare le istruzioni per ulteriori passaggi.',
    orderFailMsg: 'Si prega di contattare il nostro agente di supporto per maggiori dettagli a',
    backToShop: 'Ritorna al negozio',
    businessVolume: 'Volume d’affari',
    whatBusinessVolume: 'Qual è il volume d’affari',
    tokens: 'Gettoni',
    howManyTokensGet: 'Quanti gettoni hai guadagnato?',
    splits: 'Frazionamenti',
    theAmountPeopleBelow: 'Il numero di persone',
    difficultyReduction: 'Riduzione Difficoltà',
    immediateMining: 'Estrazione immediata',
    isAllowed: 'Permesso',
    isNotAllowed: 'non Permesso',
    bonusApplicableFor: 'Bonus Applicabile per',
    network: 'Network',
    matching: 'Corrispondente',
    level1st: 'Primo Livello',
    level2nd: 'Secondo Livello',
    level3rd: 'Terzo Livello',
    level4th: 'Quarto Livello',
    bonusCap: 'Tetto bonus',
    newlifePrice: 'Prezzo Newlife',
    success: 'Successo',
    warningFail: 'Avvertimento e fallimento',
    warning: 'Avvertimento',
    noticeUpdate: 'Avviso e aggiornamento',
    successfullyRedeemPackage: 'Hai riscattato il modulo con successo.',
    successfullyEditedProfile: 'Hai modificato il profilo con successo.',
    successfullyUpdatedPaymentMethod: 'Hai aggiornato il metodo di pagamento con successo.',
    successfullyAddPaymentMethod: 'Ha aggiunto il metodo di pagamento con successo.',
    successfullyAddModuleToCard: 'Hai aggiunto con successo il modulo al carrello della spesa.',
    moduleNotVisibleOrActive: 'Questo modulo non è visibile o attivo!',
    failedLogin: 'Accesso fallito',
    invalidUsernamePassword: 'Nome utente o password errati',
    successfullySwitchAccount: 'Hai cambiato contro con successo',
    copy: 'Copia',
    copyMessage: 'Copia negli appunti',
    quizCompleted: 'Quiz Completo',
    activeQuestion: (current, total) => `Questione ${current} di ${total}`,
    skipQuiz: 'Salta il Quiz',
    retakeQuiz: 'Rifai il quiz',
    score: 'Punteggio',
    points: 'Punti',
    youScoreIs: 'Il tuo punteggio è',
    answerTo: (title) => `La risposta a "${title}" è?`,
    yourAnswerTo: (title) => `La tua risposta a "${title}" è?`,
    loadingDocument: 'Caricamento documento',
    downloadCertificate: 'Scaricare il certificato',
    correct: 'Corretto',
    wrong: 'Sbagliato',
    loadMore: 'Carica di più',
    noQuizResultMessage: 'Non ci sono risultati quiz per questo corso.',
    footerText:
      'OneAcademy è una piattaforma e-learning innovativa che copre un’ampia gamma di argomenti finanziari tra cui il trading, la borsa valori, le criptovalute, l’analisi finanziaria, la gestione patrimoniale e molto altro ancora.',
    termsConditions: 'Termini e condizioni',
    allRightReserved: 'Tutti i diritti riservati',
    changeAccount: 'Cambia conto',
    currentAccount: 'Conto corrente',
    certificate: 'Certificato',
    ofCompletion: 'di Completamento',
    founderCeo: 'Fondatore & Amministratore Delegato',
    certificateSuccess: 'Ha completato con successo il corso di OneAcademy con il massimo dei voti',
    open: 'Aperto',
    theEnd: 'Fine',
    upNext: 'Prossimo',
    openPff: 'Clicca per aprire il PDF.',
    buyPackage: 'Compra il Pacchetto',
    UpgradePackageText: 'Aggiorna il tuo pacchetto per sbloccare questo corso.',
  },

  ja: {
    hello: 'こんにちは,',
    nextPage: '次のページ',
    previousPage: '前のページ',
    next: '次',
    previous: '前',
    yes: 'はい',
    no: 'いいえ',
    home: 'ホーム',
    about: 'について',
    courses: 'コース',
    products: '製品',
    getStarted: 'はじめに',
    startNow: '今すぐ始める',
    dashboard: 'ダッシュボード',
    product: '製品',
    experts: '専門家',
    language: '言語',
    badges: 'バッジ',
    earnedBadges: '獲得したバッジ',
    editProfile: 'プロフィール編集',
    profile: 'プロフィール',
    resources: '資源',
    aboutCourseTab: 'について',
    quizResultsCourseTab: '私のクイズ結果',
    continueLearning: '学習を継続する',
    seeAll: 'すべて見る',
    seeMore: 'もっと見る',
    completed: '完了した',
    friendsRankList: '友達ランクリスト',
    recommendedToYou: 'あなたにおすすめ',
    peopleWatching: '見ている人',
    lessons: 'レッスン',
    myCourses: '私のコース',
    expertTeacher: '専門家教員',
    viewProfile: 'プロフィールを見る',
    settings: '設定',
    paymentMethods: '支払い方法',
    signIn: 'サインイン',
    signInToOneAcademy: 'OneAcademyにサインインする',
    signOut: 'サインアウト',
    pleaseFillFetailsBelow: '下記に詳細をご記入ください',
    username: 'ユーザー名',
    password: 'パスワード',
    forgotPassword: 'パスワードを忘れか？',
    shoppingCart: 'ショッピングカート',
    item: '項目',
    price: '価格',
    fee: '料金',
    total: '合計',
    viewFullCart: 'カート全体を表示する',
    personalInformation: '個人情報',
    contactInformation: 'お問い合わせ先',
    idInformation: 'ID情報',
    firstName: '名前',
    lastName: '苗字',
    countryOfBirth: '出生国',
    cityOfBirth: '出生市',
    dateOfBirth: '生年月日',
    idNumber: 'ID番号',
    idType: 'IDタイプ',
    idExpiration: '有効期限',
    emailAddress: 'Eメールアドレス',
    phoneNumber: '電話番号',
    address: 'アドレス',
    country: '国',
    city: '市',
    postalCode: '郵便番号',
    updateProfile: 'プロファイルを更新する',
    submit: '提出する',
    cancel: 'キャンセル',
    save: '保存',
    edit: '編集',
    bankDetails: '銀行の詳細',
    accountHolderName: '口座名義人名',
    bankName: '銀行名',
    bankCountry: '銀行の国',
    ibanAccount: 'IBAN 口座',
    bicSwiftCode: 'BIC / SWIFTコード',
    bank: '銀行',
    bankProvince: '銀行県',
    bankCity: '銀行都市',
    bankBranch: '銀行支店',
    cardHolderName: 'カード名義人氏名',
    cardNumber: 'カード番号',
    accountNumber: '口座番号',
    all: '全て',
    meetТheExperts: '専門家に会う',
    accomplishments: '実績',
    biography: 'バイオグラフィー',
    ourProducts: '当社の製品',
    giftCode: 'ギフトコード',
    redeem: '償還',
    redeemGiftCode: 'ギフトコードを償還する',
    redeemed: '償還した',
    redeemedBy: 'によって償還された',
    notRedeemed: '未償還',
    notPaid: '未払い',
    buyModule: 'モジュールを購入する',
    myModules: '私のモジュール',
    moduleName: 'モジュール名',
    purchaseHistory: '購入履歴',
    educationalModules: '教育用モジュール',
    searchModules: 'モジュールを検索する',
    sortByValue: '並び替え： 値',
    sortByDate: '並び替え：日付',
    sortByName: '並び替え：名前',
    noModulesForThisSearch: 'この検索結果からは、モジュールは見つからなかった',
    noForPurchaseHistoryThisSearch: 'この検索に該当する注文履歴はない',
    withActivationFee: 'アクティベーション料金付き',
    addToCard: 'カードに追加する',
    cartTotal: 'カート合計',
    checkOutNow: '今すぐ確認する',
    bankWireTransfer: '銀行振込',
    bankWireP1:
      '銀行振込によるNewLifeパッケージの購入リクエストに続いて、OneLifeチームは、お住まいの地域の正式なOneLifeメンバーを紹介し、支払いを処理する。',
    bankWireP2: 'この手続きは、お客様のお支払いが効果的かつ期限内に処理されることを保証するために必要である。',
    bankWireP3: '銀行の電信送金は、販売代理店に連絡する：',
    bankWireP4: '以下の情報を忘れずに記載してください：',
    bankWireP5: 'または、他の支払い方法を利用することもできる！',
    bankWireP6: 'ご理解いただき、ありがとうございます！',
    bankWireP7: 'よろしくお願いします,',
    bankWireP8: 'OneLifeチーム',
    cashWallet: 'キャッシュウォレット',
    ballance: 'バランス',
    purchaseWith: 'で購入する',
    yourOrderCompletedSuccessfully: '注文は正常に完了した！',
    orderFail: '注文失敗！',
    yourOrderCompletedSuccessfullyMsg:
      '注文の詳細を記載したメールをあなたのメールアドレスに送信した。記録として保管し、さらなるステップのための指示を確認してください。',
    orderFailMsg: '詳細については、サポートエージェントにお問い合わせ',
    backToShop: 'ショップに戻る',
    businessVolume: '事業量',
    whatBusinessVolume: '事業量とは？',
    tokens: 'トークン',
    howManyTokensGet: 'トークンは何枚もらう？',
    splits: '分割',
    theAmountPeopleBelow: '以下の人数',
    difficultyReduction: '難易度低減',
    immediateMining: '即時採掘',
    isAllowed: '許可されている',
    isNotAllowed: '禁止されている',
    bonusApplicableFor: 'ボーナス適用対象',
    network: 'ネットワーク',
    matching: 'マッチング',
    level1st: '第1レベル',
    level2nd: '第2レベル',
    level3rd: '第3レベル',
    level4th: '第4レベル',
    bonusCap: 'ボーナスキャップ',
    newlifePrice: 'Newlife価格',
    success: '成功',
    warningFail: '警告と失敗',
    warning: '警告',
    noticeUpdate: '通知と更新',
    successfullyRedeemPackage: 'モジュールの償還に成功した。',
    successfullyEditedProfile: 'プロフィールの編集に成功した。',
    successfullyUpdatedPaymentMethod: 'お支払い方法の変更に成功した。',
    successfullyAddPaymentMethod: '支払い方法の追加に成功した。',
    successfullyAddModuleToCard: 'ショッピングカートにモジュールが追加された。',
    moduleNotVisibleOrActive: 'このモジュールは表示されていないか、アクティブではない !',
    failedLogin: 'ログインに失敗した',
    invalidUsernamePassword: 'ユーザー名またはパスワードが無効',
    successfullySwitchAccount: '口座の切り替えに成功した',
    copy: 'コピー',
    copyMessage: 'クリップボードにコピーする',
    quizCompleted: 'クイズを完了する',
    activeQuestion: (current, total) => `質問 ${total} の ${current}`,
    skipQuiz: 'クイズをスキップする',
    retakeQuiz: 'クイズをやり直す',
    score: 'スコア',
    points: 'ポイント',
    youScoreIs: 'あなたのスコアは',
    answerTo: (title) => `${title}への答えは？`,
    yourAnswerTo: (title) => `${title}に対するあなたの答えは？`,
    loadingDocument: 'ドキュメントを読み込んでいる',
    downloadCertificate: '証明書をダウンロードする',
    correct: '正しい',
    wrong: '間違い',
    loadMore: 'もっと読み込む',
    noQuizResultMessage: 'このコースのクイズ結果はない。',
    footerText:
      'OneAcademyは、トレーディング、証券取引所、暗号通貨、財務分析、資産運用など、金融に関する幅広いトピックをカバーする革新的なeラーニング・プラットフォームである。',
    termsConditions: '規約と条件',
    allRightReserved: '無断転載禁止',
    changeAccount: '口座を変更する',
    currentAccount: '当座預金',
    certificate: '証明書',
    ofCompletion: '修了の',
    founderCeo: '創業者兼CEO',
    certificateSuccess: 'OneAcademyのコースを優秀的に修了した。',
    open: '開ける',
    theEnd: '終了',
    upNext: '次へ',
    openPff: 'クリックするとPDFが開く。',
    buyPackage: 'パッケージを購入する',
    UpgradePackageText: 'パッケージをアップグレードして、このコースが解除される。',
  },

  zh: {
    hello: '您好，',
    nextPage: '下一页',
    previousPage: '上一页',
    next: '下一项',
    previous: '上一项',
    yes: '是',
    no: '否',
    home: '首页',
    about: '关于',
    courses: '课程',
    products: '产品',
    getStarted: '开始',
    startNow: '立即开始',
    dashboard: '控制面板',
    product: '产品',
    experts: '专家',
    language: '语言',
    badges: '徽章',
    earnedBadges: '获得的徽章',
    editProfile: '编辑个人资料',
    profile: '个人资料',
    resources: '资源',
    aboutCourseTab: '关于',
    quizResultsCourseTab: '我的测验结果',
    continueLearning: '继续学习',
    seeAll: '查看全部',
    seeMore: '查看更多',
    completed: '已完成',
    friendsRankList: '好友排名',
    recommendedToYou: '向您推荐',
    peopleWatching: '大家都在看',
    lessons: '课程',
    myCourses: '我的课程',
    expertTeacher: '专家教师',
    viewProfile: '查看个人资料',
    settings: '设置',
    paymentMethods: '支付方式',
    signIn: '登录',
    signInToOneAcademy: '登录OneAcademy',
    signOut: '注销',
    pleaseFillFetailsBelow: '请填写下方信息',
    username: '用户名',
    password: '密码',
    forgotPassword: '忘记密码',
    shoppingCart: '购物车',
    item: '项目',
    price: '价格',
    fee: '费用',
    total: '总计',
    viewFullCart: '查看购物车',
    personalInformation: '个人信息',
    contactInformation: '联系信息',
    idInformation: '身份信息',
    firstName: '名字',
    lastName: '姓氏',
    countryOfBirth: '出生国家∕地区',
    cityOfBirth: '出生城市',
    dateOfBirth: '出生日期',
    idNumber: '身份证件号码',
    idType: '身份证件类型',
    idExpiration: '到期日期',
    emailAddress: '电子邮件地址',
    phoneNumber: '电话号码',
    address: '地址',
    country: '国家∕地区',
    city: '城市',
    postalCode: '邮编',
    updateProfile: '更新个人资料',
    submit: '提交',
    cancel: '取消',
    save: '保存',
    edit: '编辑',
    bankDetails: '银行资料',
    accountHolderName: '账户持有人姓名',
    bankName: '银行名称',
    bankCountry: '银行国家∕地区',
    ibanAccount: 'IBAN账户',
    bicSwiftCode: 'BIC∕SWIFT代码',
    bank: '银行',
    bankProvince: '银行省份',
    bankCity: '银行城市',
    bankBranch: '银行分行',
    cardHolderName: '持卡人姓名',
    cardNumber: '卡号',
    accountNumber: '账户号码',
    all: '全部',
    meetТheExperts: '与专家会面',
    accomplishments: '成就',
    biography: '个人简介',
    ourProducts: '我们的产品',
    giftCode: '礼品代码',
    redeem: '兑换',
    redeemGiftCode: '兑换礼品代码',
    redeemed: '已兑换',
    redeemedBy: '兑换者：',
    notRedeemed: '未兑换',
    notPaid: '未支付',
    buyModule: '购买模块',
    myModules: '我的模块',
    moduleName: '模块名称',
    purchaseHistory: '购买记录',
    educationalModules: '教育模块',
    searchModules: 'S搜索模块',
    sortByValue: '排序方式：值',
    sortByDate: '排序方式：日期',
    sortByName: '排序方式：名称',
    noModulesForThisSearch: '在此搜索中没有模块',
    noForPurchaseHistoryThisSearch: '此搜索未找到订单记录',
    withActivationFee: '有启动费',
    addToCard: '添加至卡',
    cartTotal: '购物车总计',
    checkOutNow: '立刻结账',
    bankWireTransfer: '银行电汇',
    bankWireP1:
      '根据您购买NewLife套餐的银行电汇请求，OneLife团队希望将您介绍给您所在地区的OneLife成员，他们将为您处理付款事宜。',
    bankWireP2: '这一程序是必要的，以便我们能够确保您的付款将得到有效、及时的处理。',
    bankWireP3: '您可以联系分销商，使用银行电汇支付：',
    bankWireP4: '请不要忘记包括以下信息：',
    bankWireP5: '或者，您也可以使用其他的支付方式。',
    bankWireP6: '感谢您的理解！',
    bankWireP7: '诚挚的问候。',
    bankWireP8: 'OneLife团队',
    cashWallet: '现金钱包',
    ballance: '余额',
    purchaseWith: '购买方式：',
    yourOrderCompletedSuccessfully: '您的订单已成功完成。',
    orderFail: '订单失败！',
    yourOrderCompletedSuccessfullyMsg:
      '已向您的电子邮件地址发送了一封包括订单信息的电子邮件。请进行保留以作为您的记录，并查看进一步的步骤说明。',
    orderFailMsg: '请与我们的支持人员联系，了解更多信息：',
    backToShop: '返回商店',
    businessVolume: '业务量',
    whatBusinessVolume: '什么是业务量',
    tokens: '令牌',
    howManyTokensGet: '您获得了多少令牌？',
    splits: '分开',
    theAmountPeopleBelow: '以下人员数量',
    difficultyReduction: '难度降低',
    immediateMining: '立即挖掘',
    isAllowed: '是允许的',
    isNotAllowed: '是不允许的',
    bonusApplicableFor: '奖励适用于',
    network: '网络',
    matching: '匹配',
    level1st: '第1级',
    level2nd: '第2级',
    level3rd: '第3级',
    level4th: '第4级',
    bonusCap: '奖励上限',
    newlifePrice: 'Newlife价格',
    success: '成功',
    warningFail: '警告与失败',
    warning: '警告',
    noticeUpdate: '通知与更新',
    successfullyRedeemPackage: '您已成功兑换模块。',
    successfullyEditedProfile: '您已成功编辑您的个人资料。',
    successfullyUpdatedPaymentMethod: '您已成功更新支付方式。',
    successfullyAddPaymentMethod: '您已成功添加支付方式。',
    successfullyAddModuleToCard: '您已成功将模块添加到购物车。',
    moduleNotVisibleOrActive: '此模块不可见或不活动。!',
    failedLogin: '登录失败',
    invalidUsernamePassword: '无效的用户名或密码',
    successfullySwitchAccount: '您已成功切换账户。',
    copy: '复制',
    copyMessage: '复制到剪贴板',
    quizCompleted: '测验完成',
    activeQuestion: (current, total) => `问题 ${current}∕${total}`,
    skipQuiz: '跳过测验',
    retakeQuiz: '重新测验',
    score: '得分',
    points: '分数',
    youScoreIs: '您的分数是',
    answerTo: (title) => `"${title}"的回答是？`,
    yourAnswerTo: (title) => `您对"${title}"的回答是？`,
    loadingDocument: '正在加载文档',
    downloadCertificate: '下载证书',
    correct: '正确',
    wrong: '错误',
    loadMore: '加载更多',
    noQuizResultMessage: '本课程没有测验结果。',
    footerText:
      'OneAcademy是一个创新的在线学习平台，覆盖了广泛的金融主题，包括交易、证券交易、加密货币、金融分析、资产管理等。',
    termsConditions: '条款和条件',
    allRightReserved: '保留所有权利',
    changeAccount: '更改账户',
    currentAccount: '当前账户',
    certificate: '证书',
    ofCompletion: '完成',
    founderCeo: '创始人兼CEO',
    certificateSuccess: '已成功地以优异的成绩完成了OneAcademy的课程',
    open: '打开',
    theEnd: '结束',
    upNext: '接下来',
    openPff: '点击打开PDF。',
    buyPackage: '购买套餐',
    UpgradePackageText: '升级您的套餐以解锁此课程。',
  },

  vn: {
    hello: 'Xin chào,',
    nextPage: "Trang tiếp theo'",
    previousPage: "Trang trước'",
    next: "Kế tiếp'",
    previous: 'Trước',
    yes: 'Có',
    no: 'Không',
    home: "Trang chủ'",
    about: 'Về',
    courses: "Các khóa học'",
    products: "Các sản phẩm'",
    getStarted: 'Bắt đầu',
    startNow: 'Bắt đầu bây giờ',
    dashboard: 'Bảng điều khiển',
    product: 'Sản phẩm',
    experts: 'Các chuyên gia',
    language: 'Ngôn ngữ',
    badges: "Huy hiệu'",
    earnedBadges: 'Huy hiệu đã kiếm được',
    editProfile: 'Chỉnh sửa Hồ sơ',
    profile: 'Hồ sơ',
    resources: 'Tài nguyên',
    aboutCourseTab: 'Về',
    quizResultsCourseTab: 'Kết quả bài kiểm tra của tôi',
    continueLearning: 'Tiếp tục Học tập',
    seeAll: 'Nhìn thấy tất cả',
    seeMore: 'Xem thêm',
    completed: 'Đã hoàn thành',
    friendsRankList: 'Danh sách xếp hạng bạn bè',
    recommendedToYou: 'Được đề xuất cho bạn',
    peopleWatching: 'Mọi người đang xem',
    lessons: 'Những bài học',
    myCourses: 'Các khóa học của tôi',
    expertTeacher: 'Giáo viên là chuyên gia',
    viewProfile: 'Xem Hồ sơ',
    settings: 'Cài đặt',
    paymentMethods: 'Phương thức Thanh toán',
    signIn: 'Đăng nhập',
    signInToOneAcademy: 'Đăng nhập vào OneAcademy',
    signOut: 'Đăng xuất',
    pleaseFillFetailsBelow: 'Vui lòng điền thông tin chi tiết bên dưới',
    username: 'Tên tài khoản',
    password: 'Mật khẩu',
    forgotPassword: 'Quên mật khẩu',
    shoppingCart: 'Giỏ hàng',
    item: 'Mục',
    price: 'Giá',
    fee: "học phí'",
    total: 'Toàn bộ',
    viewFullCart: 'Xem toàn bộ giỏ hàng',
    personalInformation: 'Thông tin cá nhân',
    contactInformation: 'Thông tin liên lạc',
    idInformation: 'Thông tin ID',
    firstName: 'Tên',
    lastName: 'Họ',
    countryOfBirth: 'Quê hương',
    cityOfBirth: 'Thành phố khai sinh',
    dateOfBirth: 'Ngày sinh',
    idNumber: 'Số ID',
    idType: 'Loại ID',
    idExpiration: 'Ngày hết hạn',
    emailAddress: 'Địa chỉ email',
    phoneNumber: 'Số điện thoại',
    address: 'Địa chỉ nhà',
    country: 'Quốc gia',
    city: 'Thành phố',
    postalCode: 'Mã bưu điện',
    updateProfile: 'Cập nhật hồ sơ',
    submit: 'Nộp',
    cancel: 'Hủy bỏ',
    save: 'Cứu',
    edit: 'Chỉnh sửa',
    bankDetails: 'Thông tin chi tiết ngân hàng',
    accountHolderName: 'Tên chủ tài khoản',
    bankName: 'Tên ngân hàng',
    bankCountry: 'Ngân hàng quốc gia',
    ibanAccount: 'Tài khoản IBAN',
    bicSwiftCode: 'Mã BIC / SWIFT',
    bank: 'Ngân hàng',
    bankProvince: 'Ngân hàng ở tỉnh',
    bankCity: 'Ngân hàng ở thành phố',
    bankBranch: 'Chi nhánh ngân hàng',
    cardHolderName: 'Tên chủ thẻ',
    cardNumber: 'Số thẻ',
    accountNumber: 'Số tài khoản',
    all: 'Tất cả các',
    meetТheExperts: 'Gặp gỡ các chuyên gia',
    accomplishments: 'Thành tích',
    biography: 'Tiểu sử',
    ourProducts: 'Sản phẩm của chúng tôi',
    giftCode: 'Mã quà tặng',
    redeem: 'Đổi',
    redeemGiftCode: 'Đổi mã quà tặng',
    redeemed: 'Đã đổi',
    redeemedBy: 'Được đổi bởi',
    notRedeemed: 'Chưa được đổi',
    notPaid: 'Không trả tiền',
    buyModule: 'Mua mô-đun',
    myModules: 'Mô-đun của tôi',
    moduleName: 'Tên mô-đun',
    purchaseHistory: 'Lịch sử thanh toán',
    educationalModules: 'Mô-đun giáo dục',
    searchModules: 'Mô-đun Tìm kiếm',
    sortByValue: 'Sắp xếp theo: Giá trị',
    sortByDate: 'Sắp xếp theo: Ngày',
    sortByName: 'Sắp xếp theo: Tên',
    noModulesForThisSearch: 'Không có mô-đun nào từ tìm kiếm này',
    noForPurchaseHistoryThisSearch: 'Không tìm thấy lịch sử đặt hàng cho tìm kiếm này',
    withActivationFee: 'Với phí kích hoạt',
    addToCard: 'Thêm vào thẻ',
    cartTotal: 'Tổng số trong giỏ hàng',
    checkOutNow: 'Kiểm tra ngay bây giờ',
    bankWireTransfer: 'Chuyển khoản Ngân hàng',
    bankWireP1:
      'Theo Yêu cầu chuyển khoản ngân hàng của bạn để mua gói NewLife, Nhóm OneLife muốn giới thiệu bạn với thành viên OneLife chính thức cho khu vực của bạn, người sẽ xử lý khoản thanh toán cho bạn.',
    bankWireP2:
      'Thủ tục này là cần thiết để chúng tôi có thể đảm bảo rằng khoản thanh toán của bạn sẽ được xử lý hiệu quả và đúng hạn.',
    bankWireP3: 'Bạn có thể Sử dụng thanh toán chuyển khoản ngân hàng bằng cách liên hệ với nhà phân phối:',
    bankWireP4: 'Xin đừng quên bao gồm thông tin sau:',
    bankWireP5: 'Ngoài ra, bạn có thể sử dụng một trong các phương thức thanh toán khác có sẵn!',
    bankWireP6: 'Cảm ơn bạn đã hiểu cho chúng tôi!',
    bankWireP7: 'Trân trọng cám ơn,',
    bankWireP8: 'Nhóm OneLife',
    cashWallet: 'Ví tiền mặt',
    ballance: 'Số dư',
    purchaseWith: 'Mua hàng với',
    yourOrderCompletedSuccessfully: 'Đơn hàng của bạn đã được hoàn tất thành công!',
    orderFail: 'Đặt hàng không thành công!',
    yourOrderCompletedSuccessfullyMsg:
      'Một email bao gồm các chi tiết về đơn đặt hàng của bạn đã được gửi đến địa chỉ email của bạn. Vui lòng giữ nó trong hồ sơ của bạn và kiểm tra hướng dẫn để biết các bước tiếp theo.',
    orderFailMsg: 'Vui lòng liên hệ với đại lý hỗ trợ của chúng tôi để biết thêm chi tiết tại',
    backToShop: 'Quay trở lại cửa hàng',
    businessVolume: 'Khối lượng kinh doanh',
    whatBusinessVolume: 'Khối lượng kinh doanh là gì',
    tokens: 'Mã thông báo',
    howManyTokensGet: 'Bạn nhận được bao nhiêu mã thông báo?',
    splits: 'Tách',
    theAmountPeopleBelow: 'Lượng người bên dưới',
    difficultyReduction: 'Giảm khó khăn',
    immediateMining: 'Khai thác ngay lập tức',
    isAllowed: 'được đồng ý',
    isNotAllowed: 'không được đồng ý',
    bonusApplicableFor: 'Tiền thưởng áp dụng cho',
    network: 'Mạng',
    matching: 'Phù hợp',
    level1st: 'Cấp độ 1',
    level2nd: 'Cấp độ 2',
    level3rd: 'Cấp độ 3',
    level4th: 'Cấp độ 4',
    bonusCap: 'Giới hạn tiền thưởng',
    newlifePrice: 'Giá Newlife',
    success: 'Sự thành công',
    warningFail: 'Cảnh báo & Không thành công',
    warning: 'Cảnh báo',
    noticeUpdate: 'Thông báo & Cập nhật',
    successfullyRedeemPackage: 'Bạn đã đổi thành công mô-đun.',
    successfullyEditedProfile: 'Bạn đã chỉnh sửa thành công hồ sơ của mình.',
    successfullyUpdatedPaymentMethod: 'Bạn đã cập nhật thành công phương thức thanh toán.',
    successfullyAddPaymentMethod: 'Bạn đã thêm thành công phương thức thanh toán.',
    successfullyAddModuleToCard: 'Bạn đã thêm thành công mô-đun vào giỏ hàng.',
    moduleNotVisibleOrActive: 'Mô-đun này không hiển thị hoặc không hoạt động!',
    failedLogin: 'Đăng nhập không thành công',
    invalidUsernamePassword: 'Sai tên người dùng hoặc mật khẩu',
    successfullySwitchAccount: 'Bạn đã chuyển đổi tài khoản thành công',
    copy: 'Sao chép',
    copyMessage: 'Sao chép vào clipboard',
    quizCompleted: 'Hoàn thành câu đố',
    activeQuestion: (current, total) => `Câu hỏi $ ${current} / ${total}`,
    skipQuiz: 'Bỏ qua Bài kiểm tra',
    retakeQuiz: 'Làm lại Bài kiểm tra',
    score: 'Điểm',
    points: 'Điểm',
    youScoreIs: 'Số đểm của bạn là',
    answerTo: (title) => `Câu trả lời cho "${title}"là?`,
    yourAnswerTo: (title) => `Câu trả lời của bạn cho "${title}" là?`,
    loadingDocument: 'Đang tải tài liệu',
    downloadCertificate: 'Tải xuống chứng chỉ',
    correct: 'Chính xác',
    wrong: 'Sai',
    loadMore: 'Tải thêm',
    noQuizResultMessage: 'Không có kết quả bài kiểm tra nào cho khóa học này.',
    footerText:
      'OneAcademy là một nền tảng học tập điện tử sáng tạo bao gồm nhiều chủ đề tài chính bao gồm giao dịch, sàn chứng khoán, tiền điện tử, phân tích tài chính, quản lý tài sản và hơn thế nữa.',
    termsConditions: 'Các điều khoản và điều kiện',
    allRightReserved: 'Bảo lưu tất cả các quyền',
    changeAccount: 'Thay đổi tài khoản',
    currentAccount: 'Tài khoản hiện tại',
    certificate: 'Giấy chứng nhận',
    ofCompletion: 'hoàn thành',
    founderCeo: 'Nhà sáng lập và Giám đốc điều hành',
    certificateSuccess: 'Đã hoàn thành Khóa học của OneAcademy với xếp loại xuất sắc',
    open: 'Mở',
    theEnd: 'Kết thúc',
    upNext: 'Tiếp theo',
    openPff: 'Nhấp để mở PDF.',
    buyPackage: 'Mua gói',
    UpgradePackageText: 'Nâng cấp gói của bạn để mở khóa khóa học này.',
  },

  bg: {
    hello: 'Здравей,',
    nextPage: 'Предишна страница',
    previousPage: 'Следваща страница',
    next: 'Напред',
    previous: 'Назад',
    yes: 'Да',
    no: 'Не',
    home: 'Начало',
    about: 'За нас',
    courses: 'Курсове',
    products: 'Продукти',
    getStarted: 'Започни',
    startNow: 'Стартирай',
    dashboard: 'Начало',
    product: 'Продукт',
    experts: 'Експерти',
    language: 'Език',
    badges: 'Значки',
    earnedBadges: 'Спечелени Значки',
    editProfile: 'Редактирай профил',
    profile: 'Профил',
    resources: 'Ресурси',
    aboutCourseTab: 'Описание',
    quizResultsCourseTab: 'Резултати от тестове',
    continueLearning: 'Продължи обучението',
    seeAll: 'Виж всички',
    seeMore: 'Виж повече',
    completed: 'Завършен',
    friendsRankList: 'Приятелски ранг списък',
    recommendedToYou: 'Препоръчано за вас',
    peopleWatching: 'Наблюдава се от',
    lessons: 'Уроци',
    myCourses: 'Мойте Kурсове',
    expertTeacher: 'Учител Експерт',
    viewProfile: 'Виж Профила',
    settings: 'Настройки',
    paymentMethods: 'Начини за Плащане',
    signIn: 'Влез',
    signInToOneAcademy: 'Влез в OneAcademy',
    signOut: 'Излез',
    pleaseFillFetailsBelow: 'Моля, попълнете данните по-долу',
    username: 'Потребителско име',
    password: 'Парола',
    forgotPassword: 'Забравена парола',
    shoppingCart: 'Количка',
    item: 'Артикул',
    price: 'Цена',
    fee: 'такса',
    total: 'Общо',
    viewFullCart: 'Вижте количката',
    personalInformation: 'Лична информация',
    contactInformation: 'Информация за връзка',
    idInformation: 'Информация за самоличност',
    firstName: 'Име',
    lastName: 'Фамилия',
    countryOfBirth: 'Държава на раждане',
    cityOfBirth: 'Град на раждане',
    dateOfBirth: 'Дата на раждане',
    idNumber: 'Номер на документ',
    idType: 'Тип на документ',
    idExpiration: 'Дата на изтичане',
    emailAddress: 'Имейл адрес',
    phoneNumber: 'Телефонен номер',
    address: 'Адрес',
    country: 'Държава',
    city: 'Град',
    postalCode: 'Пощенски код',
    updateProfile: 'Актуализирайте профила',
    submit: 'Изпрати',
    cancel: 'Затвори',
    save: 'Запази',
    edit: 'Редактиране',
    bankDetails: 'Банкова информация',
    accountHolderName: 'Име на притежателя на акаунт',
    bankName: 'Име на банка',
    bankCountry: 'Държава на банка',
    ibanAccount: 'IBAN Сметка',
    bicSwiftCode: 'BIC / SWIFT Код',
    bank: 'Банка',
    bankProvince: 'Провинция на банката',
    bankCity: 'Град на банката',
    bankBranch: 'Клон на банката',
    cardHolderName: 'Име на картодържателя',
    cardNumber: 'Номер на карта',
    accountNumber: 'Номер на сметка',
    all: 'Всички',
    meetТheExperts: 'Запознайте се с експертите',
    accomplishments: 'Постижения',
    biography: 'Биография',
    ourProducts: 'Нашите продукти',
    giftCode: 'Подаръчен код',
    redeem: 'Осребрете',
    redeemGiftCode: 'Осребрете подаръчен код',
    redeemed: 'Осребрен',
    redeemedBy: 'Осребрен от',
    notRedeemed: 'Не Осребрени',
    notPaid: 'Не Палтени',
    buyModule: 'Купете модул',
    myModules: 'Моите модули',
    moduleName: 'Име на модула',
    purchaseHistory: 'История на поръчките',
    educationalModules: 'Образователни модули',
    searchModules: 'Търси модули',
    sortByValue: 'Сортиране по: Цена',
    sortByDate: 'Сортиране по: Дата',
    sortByName: 'Сортиране по: Име',
    noModulesForThisSearch: 'Няма намерени модули от това търсене',
    noForPurchaseHistoryThisSearch: 'Няма намеренa история на поръчките от това търсене',
    withActivationFee: 'С такса за активиране',
    addToCard: 'Добави в количката',
    cartTotal: 'Общо',
    checkOutNow: 'Завърши поръчката',
    bankWireTransfer: 'Банков превод',
    bankWireP1:
      'След вашата банкова заявка за закупуване на пакета NewLife, екипът на OneLife би искал да Ви насочи към официален член на OneLife за вашия регион, който ще поеме плащането вместо вас.',
    bankWireP2:
      'Тази процедура е необходима, за да можем да гарантираме, че плащането ви ще бъде обработено ефективно и навреме.',
    bankWireP3: 'Можете да използвате банковото плащане, като се свържете с дистрибутора:',
    bankWireP4: 'Моля, не забравяйте да включите следната информация:',
    bankWireP5: 'Като алтернатива можете да използвате един от другите налични методи на плащане!',
    bankWireP6: 'Благодаря за разбирането!',
    bankWireP7: 'Най-добри пожелания,',
    bankWireP8: 'Екипът на OneLife',
    cashWallet: 'Паричен портфейл',
    ballance: 'Баланс',
    purchaseWith: 'Купете с',
    yourOrderCompletedSuccessfully: 'Вашата поръчка беше завършена успешно!',
    orderFail: 'Неуспешна поръчка!',
    yourOrderCompletedSuccessfullyMsg:
      'На вашия имейл адрес е изпратен имейл, включващ подробности за вашата поръчка. Моля, запазете го за своя архив и проверете инструкциите за по-нататъшни стъпки.',
    orderFailMsg: 'Моля, свържете се с нашия агент за поддръжка за повече подробности на',
    backToShop: 'Обратно към магазина',
    businessVolume: 'Обем на бизнеса',
    whatBusinessVolume: 'Какъв е обемът на бизнеса',
    tokens: 'Толкени',
    howManyTokensGet: 'Колко толкени, ще получите?',
    splits: 'Разделяния',
    theAmountPeopleBelow: 'Броят на хората под вас',
    difficultyReduction: 'Намаляване на трудностите',
    immediateMining: 'Незабавно копаене',
    isAllowed: 'е позволено',
    isNotAllowed: 'не е позволено',
    bonusApplicableFor: 'Бонус, приложим за',
    network: 'Мрежа',
    matching: 'Съвпадащо',
    level1st: '1-во Ниво',
    level2nd: '2-ро Ниво',
    level3rd: '3-то Ниво',
    level4th: '4-то Ниво',
    bonusCap: 'Бонус',
    newlifePrice: 'Newlife цени',
    notificationSuccess: '',
    success: 'Успех',
    warningFail: 'Предупреждение и отказ',
    warning: 'Внимание',
    noticeUpdate: 'Известие и актуализиране',
    successfullyRedeemPackage: 'Успешно осребрихте модул.',
    successfullyEditedProfile: 'Успешно редактирахте своят профил.',
    successfullyUpdatedPaymentMethod: 'Успешно актуализирахте начина на плащане.',
    successfullyAddPaymentMethod: 'Успешно добавихте начин на плащане.',
    successfullyAddModuleToCard: 'Успешно добавихте модул в количката.',
    moduleNotVisibleOrActive: 'Този модул не е видим или активен!',
    failedLogin: 'Неуспешно влизане',
    invalidUsernamePassword: 'Невалидно потребителско име или парола.',
    successfullySwitchAccount: 'Успешно превключване на акаунт.',
    copy: 'Копирай',
    copyMessage: 'Копирано на клипборда',
    quizCompleted: 'Завършихте теста',
    activeQuestion: (current, total) => `Въпроси ${current} от ${total}`,
    skipQuiz: 'Пропуснете теста',
    retakeQuiz: 'Повтори теста',
    score: 'Резултат',
    points: 'Точки',
    youScoreIs: 'Вашият резултат е',
    answerTo: (title) => `Верният отговор на въпроса "${title}" е?`,
    yourAnswerTo: (title) => `Вашият отговор на въпроса "${title}" е?`,
    loadingDocument: 'Зареждане на документ',
    downloadCertificate: 'Свали сертификат',
    correct: 'Вярно',
    wrong: 'Грешно',
    loadMore: 'Зареди още',
    noQuizResultMessage: 'Нама намерени резултати от тестове.',
    footerText:
      'OneAcademy е иновативна платформа за електронно обучение, обхващаща широк спектър от финансови теми, включително търговия, фондова борса, криптовалута, финансов анализ, управление на активи и др.',
    termsConditions: 'Правила и Условия',
    allRightReserved: 'Всички права са запазени',
    changeAccount: 'Смяна на акаунт',
    currentAccount: 'Активен акаунт',
    certificate: 'Сертификат',
    ofCompletion: 'за завършване',
    founderCeo: 'Основател и главен изпълнителен директор',
    certificateSuccess: 'Завърши успешно с отличие курса на OneAcademy',
    open: 'Отвори',
    theEnd: 'Край',
    upNext: 'Следва',
    openPff: 'Кликни за да отвориш PDF-а.',
    buyPackage: 'Kупи пакет',
    UpgradePackageText: 'Купете пакет за да отключите курса.',
  },

  ko: {
    hello: '안녕하세요,',
    nextPage: '다음 페이지',
    previousPage: '이전 페이지',
    next: '다음',
    previous: '이전의',
    yes: '예',
    no: '아니요',
    home: '홈',
    about: '내용',
    courses: '코스',
    products: '제품',
    getStarted: '시작하기',
    startNow: '지금 시작',
    dashboard: '대시보드',
    product: '제품',
    experts: '전문가',
    language: '언어',
    badges: '배지',
    earnedBadges: '획득한 배지',
    editProfile: '프로필 수정',
    profile: '프로필',
    resources: '리소스',
    aboutCourseTab: '내용',
    quizResultsCourseTab: '내 퀴즈 결과',
    continueLearning: '계속 학습',
    seeAll: '모두 보기',
    seeMore: '더 보기',
    completed: '완료',
    friendsRankList: '친구 랭크 목록',
    recommendedToYou: '귀하에게 추천',
    peopleWatching: '시청하는 사람',
    lessons: '레슨',
    myCourses: '내 코스',
    expertTeacher: '전문가 교사',
    viewProfile: '프로필 보기',
    settings: '설정',
    paymentMethods: '결제 방법',
    signIn: '로그인',
    signInToOneAcademy: '원아카데미  로그인',
    signOut: '로그아웃',
    pleaseFillFetailsBelow: '아래에 세부정보를 입력하세요',
    username: '유저네임',
    password: '비밀번호',
    forgotPassword: '비밀번호 분실',
    shoppingCart: '장바구니',
    item: '아이템',
    price: '가격',
    fee: '수수료',
    total: '총액',
    viewFullCart: '전체 카트 보기',
    personalInformation: '개인 정보',
    contactInformation: '연락처 정보',
    idInformation: '아이디 정보',
    firstName: '이름',
    lastName: '성',
    countryOfBirth: '출생 국가',
    cityOfBirth: '출생 도시',
    dateOfBirth: '생년월일',
    idNumber: '아이디 번호',
    idType: '아이디 유형',
    idExpiration: '유효 기간',
    emailAddress: '이메일 주소',
    confirmEmailAddress: '이메일 주소 확인',
    phoneNumber: '전화 번호',
    address: '주소',
    country: '국가',
    city: '도시',
    postalCode: '우편 번호',
    updateProfile: '프로필 업데이트',
    submit: '제출',
    cancel: '취소',
    save: '저장',
    edit: '편집하다',
    bankDetails: '은행 세부 정보',
    accountHolderName: '계좌 소유자 이름',
    bankName: '은행명',
    bankCountry: '은행 국가',
    ibanAccount: 'IBAN 계좌',
    bicSwiftCode: 'BIC/스위프트 코드',
    bank: '은행',
    bankProvince: '은행  도',
    bankCity: '은행 도시',
    bankBranch: '은행 지점',
    cardHolderName: '카드 소지자 이름',
    cardNumber: '카드  번호',
    accountNumber: '계좌 번호',
    all: '모두',
    meetТheExperts: '전문가 만나다',
    accomplishments: '성취',
    biography: '약력',
    ourProducts: '우리 제품',
    giftCode: '기프트 코드',
    redeem: '상환',
    redeemGiftCode: '기프트 코드 사용',
    redeemed: '사용 완료',
    redeemedBy: '사용한 사람',
    notRedeemed: '사용되지 않음',
    notPaid: '결제 안 됨',
    buyModule: '모듈 구매',
    myModules: '내 모듈',
    moduleName: '모듈 이름',
    purchaseHistory: '구매 내역',
    educationalModules: '교육 모듈',
    searchModules: '검색 모듈',
    sortByValue: '가격 기준 정렬',
    sortByDate: '날짜 기준 정렬',
    sortByName: '이름 기준 정렬',
    noModulesForThisSearch: '해당 검색 결과에 모듈이 없습니다',
    noForPurchaseHistoryThisSearch: '해당 검색에 대한 주문 내역이 없습니다',
    withActivationFee: '활성화 수수료 포함',
    addToCard: '장바구니에 추가',
    cartTotal: '장바구니 총액',
    checkOutNow: '지금 체크아웃',
    bankWireTransfer: '은행 계좌 이체',
    bankWireP1:
      '귀하의 뉴라이프 패키지 구매를 위한 은행 송금 요청에 따라, 원라이프 팀은 귀하를 대신하여 결제를 처리할 해당 지역의 공식 원라이프 회원을 소개해 드리고자 합니다',
    bankWireP2: '이 절차는 귀하의 결제가 제시간에 효과적으로 처리될 수 있도록 하기 위해 필요합니다',
    bankWireP3: '판매자에게 연락하여 은행송금 결제를 사용할 수 있습니다',
    bankWireP4: '다음 정보를 포함하는 것을 잊지 마세요',
    bankWireP5: '대안으로, 사용 가능한 다른 결제수단 중 하나를 이용할 수 있습니다',
    bankWireP6: '양해해 주셔서 감사합니다',
    bankWireP7: '행운을 빕니다',
    bankWireP8: '원라이프 팀',
    cashWallet: '현금 지갑',
    ballance: '잔고',
    purchaseWith: ' -로 구매',
    yourOrderCompletedSuccessfully: '주문이 성공적으로 완료되었습니다',
    orderFail: '주문 실패',
    yourOrderCompletedSuccessfullyMsg:
      '주문에 대한 세부 정보가 포함된 이메일이 회원님의 이메일 주소로 전송되었습니다. 기록을 위해 보관하고 추가 단계에 대한 지침을 확인하세요',
    orderFailMsg: '자세한 내용은 지원 상담원에게 문의하세요',
    backToShop: '숍으로 돌아가기',
    businessVolume: '비즈니스 볼륨',
    whatBusinessVolume: '비즈니스 볼륨은',
    tokens: '토큰',
    howManyTokensGet: '획득 토큰 수량',
    splits: '분할',
    theAmountPeopleBelow: '아래 사람 수 ',
    difficultyReduction: '난이도 감소',
    immediateMining: '즉시 채굴',
    isAllowed: '허용됨',
    isNotAllowed: '허용되지 않음',
    bonusApplicableFor: '보너스 적용 대상',
    network: '네트워크',
    matching: '매칭',
    level1st: '1 레벨',
    level2nd: '2 레벨',
    level3rd: '3 레벨',
    level4th: '4 레벨',
    bonusCap: '보너스 한도',
    newlifePrice: '뉴라이프 가격',
    success: '성공',
    warningFail: '경고 및 실패',
    warning: '경고',
    noticeUpdate: '공지  및 업데이트',
    successfullyRedeemPackage: '모듈을 성공적으로 사용했습니다',
    successfullyEditedProfile: '프로필을 성공적으로 수정했습니다',
    successfullyUpdatedPaymentMethod: '결제 방법을 성공적으로 업데이트했습니다',
    successfullyAddPaymentMethod: '결제 방법을 성공적으로 추가했습니다',
    successfullyAddModuleToCard: '장바구니에 모듈을 성공적으로 추가했습니다',
    moduleNotVisibleOrActive: '이 모듈은 표시되지 않거나 활성화되지 않았습니다',
    failedLogin: '로그인 실패',
    invalidUsernamePassword: '잘못된 유저네임 또는 비밀번호',
    successfullySwitchAccount: '성공적으로 계정을 전환했습니다',
    copy: '복사',
    copyMessage: '클립보드에 복사',
    quizCompleted: '퀴즈 완료',
    activeQuestion: (current, total) => '질문 ｛전체｝의 ｛현재｝',
    skipQuiz: '퀴즈 건너뛰기',
    retakeQuiz: '퀴즈 재응시',
    score: '점수',
    points: '점',
    youScoreIs: '귀하의 점수는 ',
    answerTo: (title) => ' ｛title｝에 대한 답은 ?',
    yourAnswerTo: (title) => '｛title｝에 대한 귀하의 답변은 ?',
    loadingDocument: '문서 로딩 중',
    downloadCertificate: '인증서 다운로드',
    correct: '정답',
    wrong: '오답',
    loadMore: '더 보기 로드',
    noQuizResultMessage: '이 코스에 대한 퀴즈 결과가 없습니다',
    footerText:
      '원아카데미는 트레이딩, 증권거래소, 암호화폐, 재무 분석, 자산 관리 등 다양한 금융 주제를 다루는 혁신적인 e - 러닝 플렛폼입니다',
    termsConditions: '이용약관',
    allRightReserved: '모든 권리 보유',
    changeAccount: '계정 변경',
    currentAccount: '현재 계정',
    certificate: '인증서',
    ofCompletion: '완료',
    founderCeo: '설립자 및 CEO',
    certificateSuccess: '원아카데미 과정을 성공적으로 수료했습니다',
    open: '오픈',
    theEnd: '끝',
    upNext: '다음 ',
    openPff: 'PDF를 열려면 클릭하세요',
    buyPackage: '패키지 구매',
    UpgradePackageText: '이 코스를 잠금해제하려면 패키지를 업그레이드 하세요',
  },
};
