import PropTypes from 'prop-types';
import { tabStyle } from './styles';

const Tab = (props) => {
  const { label, active, onClick } = props;

  const handleClick = () => onClick(label);

  return (
    <div role="presentation" css={tabStyle(active)} onClick={handleClick}>
      {label}
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tab;
