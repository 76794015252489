export const wrapper = (theme) => ({
  backgroundColor: theme.secondaryDark,
  padding: '16px 0 32px',
});

export const pdfIcon = (theme) => ({
  '& > i': {
    fontSize: 40,
    color: theme.white,
  },
});

export const playerWrap = (theme) => ({
  position: 'relative',
  paddingTop: '56.25%',
  height: '100%',
  backgroundColor: theme.black,
  '& > div': {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
});

export const embedResponsive = (theme) => ({
  position: 'relative',
  display: 'block',
  height: 0,
  padding: 0,
  overflow: 'hidden',
  paddingBottom: '56.25%',
  backgroundColor: theme.black,
  '& .embedResponsiveItem': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
    '& .upNext': {
      '& h4': {
        color: theme.white,
        marginTop: 16,
        marginBottom: 8,
      },
      '& svg': {
        maxWidth: 30,
      },
    },
    '& .noVideo': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center',
      padding: 32,
      '& svg': {
        marginBottom: 8,
      },
      '& h1': {
        color: theme.white,
      },
      '& h2': {
        color: theme.white,
        marginBottom: 16,
      },
      '& p': {
        color: theme.secondaryLighter,
        marginBottom: 40,
        opacity: 0.7,
      },
    },
  },
});

export const heading = (theme) => ({
  '& > h1': {
    color: theme.white,
    marginBottom: 8,
    opacity: 0.8,
  },
  '& > p': {
    color: theme.grayLight,
    marginBottom: 16,
  },
});

export const listWrapper = (theme) => ({
  backgroundColor: theme.secondaryDark,
  height: '100%',
  maxHeight: 427,
  overflowY: 'auto',
  boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
  '& .embedResponsiveItem': {
    color: theme.whiteGrayDark,
  },
});

export const videoItemWrap = (active, isFree) => (theme) => ({
  color: theme.white,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 24px',
  opacity: isFree ? 1 : 0.7,
  backgroundColor: active ? theme.secondaryLighter01 : 'transparent',
  '& .icon': {
    marginRight: 24,
    opacity: active ? 1 : 0.8,
    width: 16,
    '& > i': {
      fontSize: 16,
    },
  },
  '& p': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: active ? 1 : 0.8,
  },
});
