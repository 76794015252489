import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '../../../UI/Theme';
import avatarImg from '../../../../assets/images/default-avatar.png';
import { formatShortText } from '../../../../utils/textFormat';
import { teacherWrapper, teacherLink } from './styles';

const AuthorBox = (props) => {
  const { author } = props;
  const { translations } = useTranslations();

  return (
    <div css={teacherWrapper}>
      <h2>{translations.expertTeacher}</h2>
      <div
        className="teacherAvatar"
        style={{
          backgroundImage: `url(${author?.picUrl ?? avatarImg})`,
        }}
      />
      <h6>
        {author?.firstName} {author?.lastName}
      </h6>
      <span>{author?.position}</span>
      <p>{formatShortText(author?.description, 100)}</p>
      <Link to={`/expert/${author?.id}`} css={teacherLink}>
        {translations.viewProfile}
      </Link>
    </div>
  );
};

AuthorBox.propTypes = {
  author: PropTypes.object,
};

export default AuthorBox;
