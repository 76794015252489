export const loader =
  (size, { color, fullScreen }) =>
  (theme) =>
    [
      {
        width: size,
        height: size,
        position: 'absolute',
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(50% - ${size / 2}px)`,
        zIndex: 10,
      },
      fullScreen && {
        position: 'fixed',
      },
      color && {
        '& .path': {
          stroke: theme[color],
        },
      },
    ];
