import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isUndefined, omit, isString, isArray } from 'lodash-es';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { Skeleton } from '../Loader';
import { NoResults, Error } from './NoResults';
import { tableContainer, containerBody } from './styles';

const Table = (props) => {
  const {
    getDataMethod,
    getDataKey,
    columns,
    pageSize = 20,
    emptyMessage,
    filterBar,
    footerBar,
    className,
    updateOn,
  } = props;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const tableOptions = useRef({
    pageSize,
    pageIndex: 0,
    totalPages: 1,
    sort: null,
  });

  useEffect(
    () => {
      data && setData(null);
      getData();
    },
    isArray(updateOn) ? updateOn : [updateOn],
  );

  const getData = async (options = {}) => {
    tableOptions.current = {
      ...tableOptions.current,
      ...options,
      ...(isUndefined(options.pageIndex) && {
        pageIndex: 0,
        totalPages: 1,
      }),
    };

    const [res, err] = await getDataMethod(omit(tableOptions.current, ['totalPages', 'totalResults']));

    if (err) {
      setError(err);
      return;
    }
    error && setError(null);

    tableOptions.current.totalPages = Math.ceil(res.total / tableOptions.current.pageSize);
    tableOptions.current.totalResults = res.total;
    setData(res[getDataKey]);
  };

  const tableDataProps = {
    tableOptions,
    getData,
  };

  return (
    <div css={tableContainer} {...(isString(className) && { className })}>
      {filterBar && filterBar(tableDataProps)}
      <TableHead columns={columns} {...tableDataProps} />
      {error ? (
        <Error />
      ) : !data ? (
        <Skeleton count={tableOptions.current.pageSize} height={30} marginBottom={12} />
      ) : !data.length ? (
        <NoResults emptyMessage={emptyMessage} />
      ) : (
        <div css={containerBody}>
          <TableBody data={data} columns={columns} {...tableDataProps} />
        </div>
      )}
      {footerBar && footerBar(tableDataProps)}
    </div>
  );
};
Table.propTypes = {
  getDataMethod: PropTypes.func,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  emptyMessage: PropTypes.string,
  filterBar: PropTypes.func,
  footerBar: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getDataKey: PropTypes.string,
  updateOn: PropTypes.any,
};
export default Table;
