import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  wrap: {
    position: 'relative',
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '0 0 500px',
    maxWidth: 500,
    height: 595,
    padding: '70px 15px 70px 85px',
    justifyContent: 'space-between',
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '0 0 250px',
    maxWidth: 250,
    height: 380,
    padding: '70px 15px 0 15px',
    alignItems: 'stretch',
  },
  logo: {
    width: '100%',
    height: 'auto',
    maxWidth: 300,
  },
  name: {
    fontFamily: 'Public Sans-Regular',
    fontSize: 26,
    color: '#c54c44',
    marginBottom: 12,
  },
  leftColSeparator: {
    width: 330,
    height: 1,
    backgroundColor: '#c54c44',
    marginBottom: 18,
  },
  successText: {
    fontSize: 12,
    marginBottom: 18,
  },
  courseName: {
    fontFamily: 'Public Sans-Bold',
    color: '#6e6e6f',
    fontSize: 24,
  },
  signature: {
    width: '100%',
    height: 'auto',
    maxWidth: 170,
    marginBottom: 6,
  },
  signatureName: {
    fontSize: 16,
    marginBottom: 4,
  },
  signaturePosition: {
    fontFamily: 'Public Sans-Regular',
    color: '#6e6e6f',
    fontSize: 16,
  },
  certificateTextWrap: {
    textAlign: 'center',
  },
  certificateText: {
    fontFamily: 'Oswald',
    color: '#ffffff',
    fontSize: 45,
    lineHeight: 1.1,
    marginBottom: 12,
  },
  completionText: {
    fontFamily: 'Oswald',
    color: '#ffffff',
    fontSize: 24,
    lineHeight: 1.1,
    marginBottom: 24,
  },
  dateWrap: {
    textAlign: 'center',
  },
  date: {
    fontSize: 11,
    color: '#ffffff',
  },
});
