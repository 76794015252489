import { useEffect, useState, useRef } from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { useAuth } from '../Authenticate';
import { useTranslations } from '../Theme';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import AccountsList from './AccountsList';
import { ReactComponent as UserIcn } from '../../../assets/images/icn-user.svg';
import { ReactComponent as ChevronDownIcn } from '../../../assets/images/icn-chevron-down.svg';
import { ReactComponent as SignOutIcn } from '../../../assets/images/icn-signout.svg';
import { profileIconStyles, profileDropdownBtn, viewProfileStyle, viewProfileDropdown, signOutStyle } from './styles';

const UserDropdown = () => {
  const { translations } = useTranslations();
  const [showList, seShowList] = useState(false);
  const {
    user: { profile, currentAccount },
  } = useAuth();

  const switchRef = useRef();

  useEffect(() => {
    !showList && switchRef.current.focus();
  }, [showList]);

  const handleBack = () => {
    seShowList(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    softLogout();
  };

  return (
    <Tooltip
      position="bottom"
      align="right"
      onClick
      content={
        <div css={viewProfileDropdown}>
          {showList ? (
            <AccountsList onBack={handleBack} />
          ) : (
            <>
              <div css={viewProfileStyle}>
                <div className="wrapper">
                  <h3 className="title">
                    {profile?.firstName} {profile?.lastName}
                  </h3>
                  <span className="label">#{currentAccount?.nickName}</span>
                </div>
                <div ref={switchRef} className="swap-icon" onClick={() => seShowList(true)} role="button" tabIndex={0}>
                  <Icon iconName="la la-exchange" />
                </div>
              </div>
              <div role='button' tabIndex={0} onClick={handleLogout} css={signOutStyle}>
                <SignOutIcn className="icon" />
                <h4 className="title">{translations.signOut}</h4>
              </div>
            </>
          )}
        </div>
      }>
      <div css={profileDropdownBtn}>
        <UserIcn css={profileIconStyles} />
        <ChevronDownIcn />
      </div>
    </Tooltip>
  );
};

export default UserDropdown;
