import { getReq } from './axios/makeRequest';
import { getAllExpertsParams, getExpertModel } from './models/experts';

const baseUrl = `${apiUrls.oneAcademy}/experts`;

export const getAllExperts = async (options = {}) => {
  const params = getAllExpertsParams(options);

  const [res, err] = await getReq(`${baseUrl}/list?${params}`);

  const data = res?.data?.map((el) => getExpertModel(el));

  return [
    {
      ...res,
      data,
    },
    err,
  ];
};

export const getExpertById = async (id) => {
  const [response, error] = await getReq(`${baseUrl}/${id}`);
  return [getExpertModel(response), error];
};
