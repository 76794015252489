import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import Checkbox from '../Button/CheckButton';
import { containerCheckBox } from './styles';

const CheckBoxForm = (props) => {
  const { id, label, value, onChange, className } = props;
  return (
    <Checkbox
      checked={value}
      onChange={(newValue) => onChange(id, newValue)}
      {...(isString(className) && { className })}
      css={containerCheckBox}>
      {label}
    </Checkbox>
  );
};

CheckBoxForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CheckBoxForm;
