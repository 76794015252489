import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash-es';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTheme } from '@emotion/react';
import Certificate from '../Certificate';
import { useTranslations, useAuth, showApiError } from '../../../UI';
import { postNewQuizHistory } from '../../../../services/quizHistory';
import { getQuestionAnswersCheck } from '../../../../services/quizzes';
import { ReactComponent as SuccessIcn } from '../../../../assets/images/checked.svg';
import { ReactComponent as FailIcn } from '../../../../assets/images/cancel.svg';
import {
  styles,
  questionMainContainer,
  tooltipsContainer,
  questionIndex,
  skipQuizButton,
  questionTitle,
  questionAnswersContainer,
  questionAnswer,
  questionAnswerRadio,
  questionFooterContainer,
  questionNextButton,
  answerPreviewBox,
  finalPreviewBox,
} from './styles';

const Quiz = forwardRef((props, ref) => {
  const {
    course,
    quiz: { questions, id: quizId },
    onSkip,
    skipVisible,
  } = props;
  const theme = useTheme();
  const { translations } = useTranslations();
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [activeAnswer, setActiveAnswer] = useState([]);
  const [answerPreview, setAnswerPreview] = useState(false);
  const [finalPreview, setFinalPreview] = useState();
  const questionsAnswers = useRef([]);
  const { user } = useAuth();
  const history = useHistory();
  const fullName = `${user?.profile?.firstName} ${user?.profile?.lastName}`;

  if (!isArray(questions)) return 'Invalid Quiz';

  useImperativeHandle(ref, () => ({
    resetQuiz: () => {
      setActiveQuestion(0);
      setActiveAnswer([]);
      setAnswerPreview(false);
      setFinalPreview();
      questionsAnswers.current = [];
    },
  }));

  const currentQuestion = questions[activeQuestion];

  const handleCheckQuestion = (answer) => {
    if (currentQuestion?.answersType === 'single') setActiveAnswer([answer.id]);
    else {
      setActiveAnswer((prev) =>
        activeAnswer.includes(answer.id) ? prev.filter((el) => el !== answer.id) : [...prev, answer.id],
      );
    }
  };

  const handleNextClick = () => {
    if (finalPreview) return;
    if (!isEmpty(activeAnswer) && !answerPreview) checkAnswer();

    if (answerPreview) {
      questionsAnswers.current = [...questionsAnswers.current, { id: currentQuestion.id, answerIds: activeAnswer }];

      activeQuestion !== questions.length - 1 ? setActiveQuestion((prev) => prev + 1) : finishQuiz();

      setActiveAnswer([]);
      setAnswerPreview(false);
    }
  };

  const checkAnswer = async () => {
    const [res, err] = await getQuestionAnswersCheck(currentQuestion.id, activeAnswer);

    if (err) return showApiError(err);

    setAnswerPreview(res);
  };

  const finishQuiz = async () => {
    const [res, err] = await postNewQuizHistory({
      accountId: user?.academyAccount?.id,
      accountCourseId: course?.accountCourse?.id,
      quiz: {
        id: quizId,
        questions: questionsAnswers.current,
      },
    });

    if (err) return showApiError(err);

    setFinalPreview(res);
    history.push(`/course/${course?.id}/about`);
    history.push(`/course/${course?.id}/my-quiz`);
  };

  const handleRetake = () => {
    questionsAnswers.current = [];
    setFinalPreview(false);
    setActiveQuestion(0);
  };

  return (
    <>
      <div css={questionMainContainer}>
        {finalPreview ? (
          <div css={finalPreviewBox}>
            <SuccessIcn />
            <h1>{translations.quizCompleted}</h1>
            <p>{`${translations.youScoreIs} ${finalPreview?.scorePercentage}%.`}</p>
            <span role="button" tabIndex={-1} onClick={handleRetake} css={skipQuizButton(true)}>
              {translations.retakeQuiz}
            </span>
          </div>
        ) : (
          <>
            <div css={tooltipsContainer}>
              <span css={questionIndex}>{translations.activeQuestion(activeQuestion + 1, questions.length)}</span>
              {skipVisible && (
                <span role="button" tabIndex={-1} onClick={onSkip} css={skipQuizButton(false)}>
                  {translations.skipQuiz}
                </span>
              )}
            </div>
            {answerPreview ? (
              <>
                <h1 css={questionTitle}>{translations.yourAnswerTo(currentQuestion?.title)}</h1>
                <div css={answerPreviewBox}>
                  {answerPreview.map((el) => (
                    <div key={el.id}>
                      <p>{el.text}</p>
                      {el.isCorrect ? (
                        <>
                          <SuccessIcn /> <h4>{`${translations.correct}!`}</h4>
                        </>
                      ) : (
                        <>
                          <FailIcn /> <h4>{`${translations.wrong}!`}</h4>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h1 css={questionTitle}>{currentQuestion?.title}</h1>
                <ul css={questionAnswersContainer}>
                  {currentQuestion?.answers?.map((answer, index) => (
                    <li
                      css={questionAnswer}
                      role="presentation"
                      key={index}
                      tabIndex={-1}
                      onClick={() => handleCheckQuestion(answer)}>
                      <div css={questionAnswerRadio(activeAnswer.includes(answer.id))} />
                      <span>{answer?.text}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>

      {(!finalPreview || finalPreview?.scorePercentage > 70) && (
        <div css={questionFooterContainer(finalPreview)}>
          {!finalPreview && (
            <div
              css={questionNextButton(isEmpty(activeAnswer))}
              tabIndex="0"
              role="button"
              onClick={!isEmpty(activeAnswer) ? handleNextClick : null}>
              {translations.next}
            </div>
          )}
          <div className="downloadBtnWrap">
            <PDFDownloadLink
              style={styles.downloadBtn(theme)}
              document={<Certificate userName={fullName} courseName={course?.name} />}
              fileName={`${course?.name} Certificate.pdf`}>
              {({ loading }) => (loading ? `${translations.loadingDocument}...` : translations.downloadCertificate)}
            </PDFDownloadLink>
          </div>
        </div>
      )}
    </>
  );
});

Quiz.propTypes = {
  quiz: PropTypes.object,
  course: PropTypes.object,
  onSkip: PropTypes.func,
  skipVisible: PropTypes.bool,
};

export default Quiz;
