import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { container, content } from './styles';

const Collapse = (props) => {
  const { header, children } = props;
  const childsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={container}>
      <div>{header}</div>
      <div ref={childsRef} css={content(isOpen, childsRef.current?.scrollHeight)}>
        {children}
      </div>
      <Button secondary small onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen ? 'Hide' : 'See More'}
      </Button>
    </div>
  );
};

Collapse.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Collapse;
