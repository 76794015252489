import queryString from 'query-string';
import { pick } from 'lodash-es';

export const addAccountModel = data => ({
  externalAccountIds: [...data],
});

export const getAllFriendsParams = options => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'asc',
  };

  const mapSort = sort => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
    },
    { arrayFormat: 'index' }
  );
};
