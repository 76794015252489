import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  switchButtonContainer,
  switchButtonInput,
  switchButtonLabel,
  switchButtonInner,
  switchButtonCircle,
} from './styles';

const SwitchButton = (props) => {
  const { id, style = {}, value, onChange = () => {}, width, activeText, inactiveText } = props;
  const [isChecked, setIsChecked] = useState(!!value);
  const inputId = id || Math.random();

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = () =>
    setIsChecked((prev) => {
      onChange(!prev);
      return !prev;
    });

  return (
    <div css={switchButtonContainer(width)}>
      <input
        css={switchButtonInput}
        type="checkbox"
        name="onOffSwitch"
        onChange={handleChange}
        id={inputId}
        checked={isChecked}
      />
      <label css={switchButtonLabel} htmlFor={inputId}>
        <span css={switchButtonInner(isChecked, activeText, inactiveText, style)} />
        <span css={switchButtonCircle(isChecked, width, style)} />
      </label>
    </div>
  );
};
SwitchButton.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string,
};
export default SwitchButton;
