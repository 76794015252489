export const wrapper = {
  marginBottom: 40,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const headerWrap = (theme) => ({
  backgroundColor: theme.secondaryDark,
  marginBottom: 50,
});

export const heading = (theme) => ({
  paddingTop: 75,
  paddingBottom: 75,
  color: theme.white,
  fontSize: 48,
  fontWeight: 500,
});

export const categoryBox = (theme) => ({
  backgroundColor: theme.white,
  padding: 40,
  borderRadius: 3,
  marginBottom: 40,
  '& .title': {
    marginBottom: 16,
    fontSize: 32,
  },
  '& .text': {
    fontSize: 16,
    color: theme.grayLight,
    lineHeight: 1.5,
    marginBottom: 16,
    wordBreak: 'break-all',
  },
  '& .category-image': {
    width: 300,
    height: 200,
    backgroundColor: theme.secondaryLighter,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});

export const categoryBoxRow = {
  alignItems: 'center',
};

export const buttonStyle = {
  textTransform: 'uppercase',
};

export const skeletonStyle = {
  marginBottom: 40,
};
