import { useState, useRef } from 'react';
import { isNil } from 'lodash-es';

export default (initValue, getMatches, localStorageKey) => {
  // Get the history for this searchBar from the localStorage
  // If there is nothing saved to provided key then save an empty array
  const previouslySearched = useRef(JSON.parse(localStorage.getItem(localStorageKey)) || []);
  const hasSuggestions = !isNil(localStorageKey);

  if (isNil(previouslySearched.current) && hasSuggestions) {
    previouslySearched.current = [];
    localStorage.setItem(localStorageKey, JSON.stringify([]));
  }

  const [value, changeValue] = useState(initValue || '');
  const [history, setHistory] = useState(previouslySearched.current);
  const [isOpen, setIsOpen] = useState(false);
  const lastSearch = useRef(null);

  const handleChange = ({ target: { value: input } }) => {
    changeValue(input);
    if (!hasSuggestions) return getMatches(input.trim());

    !isOpen && setIsOpen(true);

    if (!input.length) return setHistory(previouslySearched.current);

    // Filter the previously searched items so we can show the user something relevant
    // There is no reason to show Gosho as suggestion when the input is Pesho, so we
    // search for something that includes Gosho inside of it
    const matches = previouslySearched.current.filter(
      item => item.toLowerCase().indexOf(input.toLowerCase().trim()) !== -1 && item.length >= input.length
    );
    return setHistory(matches);
  };

  const handleOnBlur = ({ currentTarget }) =>
    // Here the timeout is used only to make this function async and to be putted inside callback queue
    // We need at least one render to be done from the browser so document.activeElement will be changed
    // to the actual element which is clicked, if this was synchronous document.activeElement will be currentTarget
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement) && hasSuggestions) {
        performSearch();
        setIsOpen(false);
      }
    });

  const callSubmitMethod = data => {
    setIsOpen(false);

    // If the user press Enter without change on the input there is no reason to fetch the same results
    if (lastSearch.current === data) return;

    lastSearch.current = data;
    getMatches(data.trim());
  };

  const handleSelect = data => {
    changeValue(data);
    callSubmitMethod(data);
  };

  const handleKeyPressed = e => {
    if (e.which === 13 || e.charCode === 13) performSearch();
  };

  const performSearch = () => {
    if (!hasSuggestions) return;

    if (previouslySearched.current.indexOf(value) === -1 && value.length) {
      previouslySearched.current = [value, ...previouslySearched.current];
      localStorage.setItem(localStorageKey, JSON.stringify(previouslySearched.current));
    }
    setHistory(previouslySearched.current);
    callSubmitMethod(value);
  };

  const handleDelete = () => {
    changeValue('');
    if (!hasSuggestions) return getMatches('');
    setHistory(previouslySearched.current);
    return callSubmitMethod('');
  };

  return {
    input: {
      value,
      onChange: handleChange,
      onFocus: () => setIsOpen(!!history.length),
      onKeyPress: handleKeyPressed,
    },
    container: {
      onBlur: handleOnBlur,
      onSelect: handleSelect,
      onDelete: handleDelete,
    },
    isOpen,
    history,
  };
};
