import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { backgroundPicture, imageContainer, child } from './styles';

const Image = (props) => {
  const { children, src, alt, width, height, img, className } = props;

  return img ? (
    <>
      <img
        src={src}
        alt={alt}
        width={width || '100%'}
        height={height || 'auto'}
        css={imageContainer(props)}
        {...(isString(className) && { className })}
      />
      {children && <div css={child(props)}>{children}</div>}
    </>
  ) : (
    <div title={alt} css={backgroundPicture(props)} {...(isString(className) && { className })}>
      {children}
    </div>
  );
};

Image.propTypes = {
  children: PropTypes.any,
  img: PropTypes.bool,
  size: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
  repeat: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Image;
