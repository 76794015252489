import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hash from 'object-hash';
import { isNull } from 'lodash-es';
import { useAuth, showApiError } from '../../../components/UI';
import Recommended from '../../../components/Shared/Recommended';
import VideoPlayer from '../../../components/Pages/Course/VideoPlayer';
import InfoTabs from '../../../components/Pages/Course/InfoTabs';
import { getCourseById } from '../../../services/courses';
import { postNewAccountCourse } from '../../../services/accountCourse';
import { loader } from './style';

const Course = () => {
  const { id: courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getCourse();
  }, [courseId, hash({ user })]);

  const getCourse = async () => {
    const [res, err] = await getCourseById(courseId, user?.academyAccount?.id);
    if (err) return showApiError(err);

    let accountCourse = null;

    if (!res?.accountCourse) {
      const [resNewAccount, errNewAccount] = await postNewAccountCourse({
        courseId,
        accountId: user?.academyAccount?.id,
      });

      if (resNewAccount) accountCourse = resNewAccount;
      setIsAvailable(!(!resNewAccount || errNewAccount));
    } else setIsAvailable(true);

    setCourse({ ...res, accountCourse: accountCourse ?? res.accountCourse });
  };

  return !isNull(course) ? (
    <>
      <VideoPlayer course={course} isAvailable={isAvailable} />
      <InfoTabs course={course} isAvailable={isAvailable} />
      <Recommended categoryId={course?.category?.id} />
    </>
  ) : (
    <div css={loader}>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Course;
