import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isString } from 'lodash-es';
import { containerClass, linkItem, separator, text } from './styles';

const Breadcrumbs = (props) => {
  const { items, className } = props;

  if (!items.every((el) => el.label)) return null;

  return (
    <ul css={containerClass(className)} {...(isString(className) && { className })}>
      {items.map((el, index, arr) => {
        const isNotLastElement = index !== arr.length - 1;

        return (
          <li key={index}>
            {el.url && isNotLastElement ? (
              <Link to={el.url} css={linkItem(isNotLastElement)}>
                {el.label}
              </Link>
            ) : (
              <span css={text}>{el.label}</span>
            )}
            {isNotLastElement ? <span css={separator}>/</span> : ''}
          </li>
        );
      })}
    </ul>
  );
};
Breadcrumbs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Breadcrumbs;
