import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../Authenticate';
import logo from '../../../assets/images/logo.svg';
import { Row, Col } from '../Grid';
import Icon from '../Icon';
import LanguageDropdown from '../LanguageDropdown';
import { useTranslations } from '../Theme';
import { icon, productElements, oneAcademyElements } from './definitions';
import {
  footerContainer,
  footerColumnWrapper,
  footerDescription,
  usernameBranchContainer,
  appAvatarIcon,
  descriptionStyles,
  iconsContainer,
  footerColumn,
  columnTitle,
  footerLink,
  termsStyles,
} from './styles';

const Footer = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { translations } = useTranslations();

  const currentYear = new Date().getFullYear();

  return (
    <footer css={footerContainer}>
      <Row justify="space-between" align="flex-start" noWrap padding={8} container css={footerColumnWrapper}>
        <Col sm={4} css={footerDescription}>
          <Link to="/" css={usernameBranchContainer}>
            <img src={logo} css={appAvatarIcon} alt="Logo" />
          </Link>
          <p css={descriptionStyles}>{translations.footerText}</p>

          <div css={iconsContainer}>
            {icon.map((el) => (
              <Link to={el.path} key={el.path} className="links">
                <Icon iconName={el.icon} size={20} />{' '}
              </Link>
            ))}
          </div>
        </Col>
        {isAuthenticated && (
          <>
            <Col sm={2} css={footerColumn}>
              <h5 css={columnTitle}>{translations.product}</h5>
              {productElements(translations).map((el) => (
                <Link key={el.path} to={el.path} css={footerLink(el.path === location.pathname)}>
                  {el.title}
                </Link>
              ))}
            </Col>

            <Col sm={2} css={footerColumn}>
              <h5 css={columnTitle}>One Academy</h5>
              {oneAcademyElements(translations).map((el) => (
                <Link key={el.path} to={el.path} css={footerLink(el.path === location.pathname)}>
                  {el.title}
                </Link>
              ))}
            </Col>
          </>
        )}

        <Col sm={3} css={footerColumn}>
          <h5 css={columnTitle}>{translations.language}</h5>
          <LanguageDropdown />
        </Col>
      </Row>
      <Row justify="space-between" align="center" container noWrap padding={8} css={termsStyles}>
        <p>{translations.termsConditions}</p>
        <p>
          © {currentYear} - One Academy | {translations.allRightReserved}
        </p>
      </Row>
    </footer>
  );
};

export default Footer;
