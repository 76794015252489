import { keyframes } from '@emotion/react';

const defaultBaseColor = '#eee';

const defaultHighlightColor = '#f5f5f5';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const skeletonClass = ({ count, duration, height, width, circle, ...restStyles }) => [
  {
    display: 'block',
    backgroundColor: defaultBaseColor,
    backgroundImage: `linear-gradient(
        90deg,
      ${defaultBaseColor},
      ${defaultHighlightColor},
      ${defaultBaseColor}
      )`,
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
    lineHeight: 1,
    width: width || '100%',
    animation: `${skeletonKeyframes} ${duration}s ease-in-out infinite`,
    margin: count > 1 ? '8px 0' : 0,
  },
  height && {
    height,
  },
  width &&
    height &&
    circle && {
      borderRadius: '50%',
    },
  { ...restStyles },
];
