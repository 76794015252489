export const resourceList = {
  display: 'inline-flex',
  flexDirection: 'column',
};

export const resourceLink = (theme) => ({
  cursor: 'pointer',
  color: theme.highlightsBlue,
  opacity: 0.9,
  fontSize: 18,
  marginBottom: 8,
  paddingLeft: 32,
  '& i': {
    marginRight: 10,
    verticalAlign: 'middle',
  },
  '&:hover': {
    opacity: 1,
  },
});

export const noData = {
  textAlign: 'center',
};

export const skeletonStyle = {
  marginBottom: 8,
};
