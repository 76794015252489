import { getReq } from './axios/makeRequest';
import { getQuizStepModel, getAnswerModel, getQuizParams } from './models/quizzes';

const baseUrl = `${apiUrls.oneAcademy}/quizzes`;

const answersIdsQueryString = (answersIds) => answersIds.map((el, index) => `&answersIds[${index}]=${el}`).join('');

const getRandomAnswers = (answers) => {
  let currentIndex = answers.length,
    randomIndex;
  const randomized = [...answers];

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [randomized[currentIndex], randomized[randomIndex]] = [randomized[randomIndex], randomized[currentIndex]];
  }

  return randomized;
};

export const getQuizByStepId = async (options) => {
  const params = getQuizParams(options);
  const [res, err] = await getReq(`${baseUrl}?${params}`);

  const questions = res?.questions?.map((el) => {
    const question = getQuizStepModel(el);
    question.answers = getRandomAnswers(question.answers);
    return question;
  });
  return [
    {
      ...res,
      questions,
    },
    err,
  ];
};

export const getQuestionAnswersCheck = async (questionId, answersIds) => {
  const [response, error] = await getReq(
    `${baseUrl}/checked-answers?questionId=${questionId}${answersIdsQueryString(answersIds)}`,
  );
  return [getAnswerModel(response), error];
};
