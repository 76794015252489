import queryString from 'query-string';
import { pick, isEmpty } from 'lodash-es';

export const getCategoryModel = response =>
  response && {
    ...response,
    ...(!isEmpty(response?.translations) && {
      ...pick(response?.translations[0], ['name']),
    }),
  };

export const getAllCategoriesParams = options => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'asc',
  };

  const mapSort = sort => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
    },
    { arrayFormat: 'index' }
  );
};
