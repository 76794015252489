import { useState, useRef } from 'react';

export default (containerRef, arrowsRef, onScroll) => {
  const [isDown, setIsDown] = useState(false);
  const options = useRef();

  const handleMouseDown = (e) => {
    setIsDown(true);
    options.current = {
      startX: e.pageX - containerRef.current.offsetLeft,
      scrollLeft: containerRef.current.scrollLeft,
    };
  };

  const handleMouseUp = () => setIsDown(false);

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();

    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - options.current.startX) * 0.1;
    onScroll(-walk);

    const scrollStart = arrowsRef.current.getStart();
    const scrollEnd = arrowsRef.current.getEnd();
    const { scrollWidth, scrollLeft, offsetWidth } = containerRef.current;
    const { changeStart, changeEnd } = arrowsRef.current;

    if (scrollLeft !== 0 && scrollStart) changeStart(false);
    if (scrollLeft === 0 && !scrollStart) changeStart(true);

    if (scrollWidth - scrollLeft === offsetWidth && !scrollEnd) changeEnd(true);
    if (scrollWidth - scrollLeft !== offsetWidth && scrollEnd) changeEnd(false);
  };

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseUp,
    onMouseMove: handleMouseMove,
    isDragging: isDown,
  };
};
