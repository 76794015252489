import noDataBgImage from '../../../assets/images/no-courses-img.jpg';

export const wrapper = (theme) => ({
  backgroundColor: theme.secondaryDark,
  paddingTop: 75,
  paddingBottom: 56,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
});

export const heading = (theme) => ({
  marginBottom: 70,
  '& > h1': {
    color: theme.white,
    fontSize: 48,
    fontWeight: 500,
  },
});

export const noData = (theme) => ({
  backgroundImage: `url(${noDataBgImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: 340,
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > div': {
    position: 'relative',
    zIndex: 1,
    textAlign: 'center',
  },
  '& h3': {
    color: theme.white,
    fontSize: 32,
    marginBottom: 16,
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.secondaryDark,
    opacity: 0.7,
  },
});
