import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Skeleton, showApiError } from '../../../UI';
import { useTranslations } from '../../../UI/Theme';
import { getAllCourses } from '../../../../services/courses';
import testCourseImg from '../../../../assets/images/test-course-image.jpg';
import { formatShortText } from '../../../../utils/textFormat';
import { courseBox, buttonStyle, skeletonStyle } from './styles';

const CourseCategoryCard = (props) => {
  const { category } = props;
  const [courses, setCourses] = useState(null);
  const { translations } = useTranslations();
  const history = useHistory();

  useEffect(() => {
    fetchCourses();
  }, [category?.id]);

  const fetchCourses = async () => {
    const [res, err] = await getAllCourses({ categoryId: category?.id });
    if (err) return showApiError(err);

    setCourses(res?.data ?? []);
  };

  const navigateToCourse = (data) => {
    history.push(`/course/${data?.id}`);
  };

  return !isNull(courses) ? (
    <>
      {courses?.map((el) => (
        <div css={courseBox} key={el.id}>
          <Row horizontalGap={0} noWrap>
            <Col sm={6}>
              <div
                className="course-image"
                style={{
                  backgroundImage: `url(${el?.pictureUrl ?? testCourseImg})`,
                }}
              />
            </Col>
            <Col sm={6}>
              <div className="course-text-box">
                <h2 className="title">{el.name}</h2>
                {el?.description && <p className="text">{formatShortText(el?.description)}</p>}
                <Button onClick={() => navigateToCourse(el)} css={buttonStyle}>
                  {translations.startNow}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </>
  ) : (
    Array(5)
      .fill()
      .map((el, index) => (
        <div key={index} css={skeletonStyle}>
          <Skeleton height="280px" width="100%" />
        </div>
      ))
  );
};

CourseCategoryCard.propTypes = {
  category: PropTypes.object,
};

export default CourseCategoryCard;
