import { Link } from 'react-router-dom';
import { useTranslations } from '../../../components/UI/Theme';
import { Row, Col } from '../../../components/UI';
import DashboardHeader from '../../../components/Pages/Dashboard/DashboardHeader';
import ContinueLearning from '../../../components/Pages/Dashboard/ContinueLearning';
import Recommended from '../../../components/Shared/Recommended';
import FriendsRankList from '../../../components/Pages/Dashboard/FriendsRankList';
import { wrapper, heading, headingLink } from './styles';

const Dashboard = () => {
  const { translations } = useTranslations();

  return (
    <>
      <DashboardHeader />
      <div css={wrapper}>
        <Row horizontalGap={12} noWrap container>
          <Col sm={9}>
            <div css={heading}>
              <h1>{translations.continueLearning}</h1>
              <Link to="/my-courses" css={headingLink}>
                {translations.seeAll}
              </Link>
            </div>
          </Col>
          <Col sm={9}>
            <ContinueLearning />
          </Col>
          <Col sm={3} className="bottomBuffer">
            <FriendsRankList />
          </Col>
        </Row>
      </div>
      <Recommended />
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
