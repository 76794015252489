export const pagesCounter = (theme) => ({
  color: theme.white,
  margin: 16,
  fontSize: 18,
});

export const buttonStyle = {
  textTransform: 'uppercase',
  minWidth: 150,
  justifyContent: 'center',
  margin: 8,
};

export const pdfViewWrap = {
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > section': {
    maxWidth: '100%',
  },
};
