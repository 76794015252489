import PropTypes from 'prop-types';
import { isString } from 'lodash-es';

const FormParagraph = (props) => {
  const { children, className } = props;
  return <div {...(isString(className) && { className })}>{children}</div>;
};

FormParagraph.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
};

FormParagraph.displayName = 'FormParagraph';

export default FormParagraph;
