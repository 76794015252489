import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  downloadBtn: (theme) => ({
    fontSize: 14,
    borderRadius: 4,
    padding: '10px 20px',
    color: 'white',
    backgroundColor: theme.primaryLight,
  }),
});

export const collapseStyle = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.grayLightest}`,
  marginTop: '-1px',
});

export const collapseRow = {
  alignItems: 'center',
};

export const collapseHeader = (isOpen) => (theme) => ({
  padding: 25,
  transition: 'all 0.3s ease',
  borderBottom: isOpen ? `1px solid ${theme.grayLighter}` : 'none',
  '& .section-wrap': {
    display: 'flex',
    alignItems: 'center',
    '& .section-heading': {
      color: theme.textBlueDark,
      marginRight: 8,
    },
  },
});

export const collapseArrow = (isOpen) => (theme) => ({
  cursor: 'pointer',
  width: '24px',
  height: '24px',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isOpen ? 'transparent' : theme.grayLighter,
  transition: 'all 0.3s ease',
  '& svg': {
    transition: 'all 0.3s ease',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    opacity: isOpen ? 1 : 0.3,
    marginTop: isOpen ? 0 : 3,
  },
});

export const collapseContent = (isOpen) => ({
  transition: `transform ${isOpen ? 0.3 : 0}s ease-in-out, height ${isOpen ? 0.5 : 0}s ease-in-out`,
  transform: `scaleY(${isOpen ? 100 : 0}%)`,
  transformOrigin: '0 0',
  height: isOpen ? '100%' : 0,
});

export const collapseBody = {
  padding: '16px 16px 16px 24px',
  '& h2': {
    marginBottom: 24,
  },
  '& .downloadBtnWrap': {
    marginBottom: 32,
  },
};

export const quizResultsStyle = (theme) => ({
  marginTop: 12,
  borderBottom: `1px solid ${theme.whiteGray}`,
  paddingBottom: 12,
});

export const answerPreviewBox = {
  marginTop: 8,
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: 8,
    },
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  },
};

export const questionTitle = (theme) => ({
  color: theme.textBlueDark,
});

export const noData = {
  textAlign: 'center',
};

export const skeletonStyle = {
  marginBottom: 8,
};

export const paginationStyle = {
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > div': {
    marginLeft: 8,
    marginRight: 8,
  },
};

export const answersList = {
  height: 300,
  overflowY: 'auto',
};
