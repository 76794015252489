import PropTypes from 'prop-types';
import Icon from '../Icon';
import { noResultsContainer, noResultsTitle, noResultsIcon, noResultsSubtitle } from './styles';

export const NoResults = ({ emptyMessage }) => {
  return (
    <section css={noResultsContainer}>
      <div css={noResultsIcon}>
        <Icon material iconName="search" color="white" size={48} />
      </div>
      <p>{emptyMessage}</p>
    </section>
  );
};

NoResults.propTypes = {
  emptyMessage: PropTypes.string,
};

export const Error = () => {
  return (
    <section css={noResultsContainer}>
      <div css={noResultsIcon}>
        <Icon material iconName="report" size={48} color="white" />
      </div>
      <h2 css={noResultsTitle}>Request failure.</h2>
      <p css={noResultsSubtitle}>
        There was problem getting the results.
        <br />
        Please try again later.
      </p>
    </section>
  );
};
