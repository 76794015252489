import { getReq } from './axios/makeRequest';
import { getAllCategoriesParams, getCategoryModel } from './models/categories';

const baseUrl = `${apiUrls.oneAcademy}/categories`;

export const getAllCategories = async (options = {}) => {
  const params = getAllCategoriesParams(options);

  const [res, err] = await getReq(`${baseUrl}/list?${params}`);

  const data = res?.data?.map((el) => getCategoryModel(el));

  return [
    {
      ...res,
      data,
    },
    err,
  ];
};
