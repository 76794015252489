export const wrapper = (theme) => ({
  backgroundColor: theme.whiteGray,
  paddingTop: 35,
  paddingBottom: 56,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
});

export const heading = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 35,
};

export const headingLink = (theme) => ({
  color: theme.secondaryDark,
  fontSize: 16,
  lineHeight: 1.5,
  fontWeight: 500,
  transition: 'color .3s linear',
  '&:hover': {
    color: theme.primary,
  },
});
