import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNaN } from 'lodash-es';
import { useTranslations } from '../../UI/Theme';
import { ReactComponent as CourseCardIcn } from '../../../assets/images/icn-course-card.svg';

import { wrapper, header, courseThumb, body, percentWrap, percentLabel } from './styles';

const CourseCard = (props) => {
  const { translations } = useTranslations();
  const {
    data: { id, name, picThumbUrl, accountCourse },
  } = props;
  const completedLessonProgress =
    accountCourse?.completedOn ? 100 : Math.floor(Number(accountCourse.completedLessonProgress));

  return (
    <Link to={`/course/${id}`}>
      <div css={wrapper}>
        <div css={header}>
          {picThumbUrl ? <img src={picThumbUrl} css={courseThumb} alt={`course-card-${id}`} /> : <CourseCardIcn />}
        </div>
        <div css={body}>
          <h4>{name}</h4>
          <div>
            <div css={percentWrap}>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${isNaN(completedLessonProgress) ? 0 : completedLessonProgress}%` }}
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <span css={percentLabel}>{`${isNaN(completedLessonProgress) ? 0 : completedLessonProgress}% ${translations.completed}`}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

CourseCard.propTypes = {
  data: PropTypes.any,
};

export default CourseCard;
