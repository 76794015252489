/* eslint-disable camelcase */
export const countryList = [
  {
    name: 'Afghanistan',
    id: 1,
    phone: '+93',
    symbol: '؋',
    capital: 'Kabul',
    currency: 'AFN',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'AF',
    alpha_3: 'AFG',
    flag: '🇦🇫',
    official_name: 'Islamic Republic of Afghanistan',
  },
  {
    name: 'Aland Islands',
    id: 2,
    phone: '+358',
    symbol: '€',
    capital: 'Mariehamn',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'AX',
    alpha_3: 'ALA',
    flag: '🇦🇽',
  },
  {
    name: 'Albania',
    id: 3,
    phone: '+355',
    symbol: 'Lek',
    capital: 'Tirana',
    currency: 'ALL',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'AL',
    alpha_3: 'ALB',
    flag: '🇦🇱',
    official_name: 'Republic of Albania',
  },
  {
    name: 'Algeria',
    id: 4,
    phone: '+213',
    symbol: 'دج',
    capital: 'Algiers',
    currency: 'DZD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'DZ',
    alpha_3: 'DZA',
    flag: '🇩🇿',
    official_name: "People's Democratic Republic of Algeria",
  },
  {
    name: 'American Samoa',
    id: 5,
    phone: '+1684',
    symbol: '$',
    capital: 'Pago Pago',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'AS',
    alpha_3: 'ASM',
    flag: '🇦🇸',
  },
  {
    name: 'Andorra',
    id: 6,
    phone: '+376',
    symbol: '€',
    capital: 'Andorra la Vella',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'AD',
    alpha_3: 'AND',
    flag: '🇦🇩',
    official_name: 'Principality of Andorra',
  },
  {
    name: 'Angola',
    id: 7,
    phone: '+244',
    symbol: 'Kz',
    capital: 'Luanda',
    currency: 'AOA',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'AO',
    alpha_3: 'AGO',
    flag: '🇦🇴',
    official_name: 'Republic of Angola',
  },
  {
    name: 'Anguilla',
    id: 8,
    phone: '+1264',
    symbol: '$',
    capital: 'The Valley',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'AI',
    alpha_3: 'AIA',
    flag: '🇦🇮',
  },
  {
    name: 'Antarctica',
    id: 9,
    phone: '+672',
    symbol: '$',
    capital: 'Antarctica',
    currency: 'AAD',
    continent: 'Antarctica',
    continent_code: 'AN',
    alpha_2: 'AQ',
    alpha_3: 'ATA',
    flag: '🇦🇶',
  },
  {
    name: 'Antigua and Barbuda',
    id: 10,
    phone: '+1268',
    symbol: '$',
    capital: "St. John's",
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'AG',
    alpha_3: 'ATG',
    flag: '🇦🇬',
  },
  {
    name: 'Argentina',
    id: 11,
    phone: '+54',
    symbol: '$',
    capital: 'Buenos Aires',
    currency: 'ARS',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'AR',
    alpha_3: 'ARG',
    flag: '🇦🇷',
    official_name: 'Argentine Republic',
  },
  {
    name: 'Armenia',
    id: 12,
    phone: '+374',
    symbol: '֏',
    capital: 'Yerevan',
    currency: 'AMD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'AM',
    alpha_3: 'ARM',
    flag: '🇦🇲',
    official_name: 'Republic of Armenia',
  },
  {
    name: 'Aruba',
    id: 13,
    phone: '+297',
    symbol: 'ƒ',
    capital: 'Oranjestad',
    currency: 'AWG',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'AW',
    alpha_3: 'ABW',
    flag: '🇦🇼',
  },
  {
    name: 'Australia',
    id: 14,
    phone: '+61',
    symbol: '$',
    capital: 'Canberra',
    currency: 'AUD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'AU',
    alpha_3: 'AUS',
    flag: '🇦🇺',
  },
  {
    name: 'Austria',
    id: 15,
    phone: '+43',
    symbol: '€',
    capital: 'Vienna',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'AT',
    alpha_3: 'AUT',
    flag: '🇦🇹',
    official_name: 'Republic of Austria',
  },
  {
    name: 'Azerbaijan',
    id: 16,
    phone: '+994',
    symbol: 'm',
    capital: 'Baku',
    currency: 'AZN',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'AZ',
    alpha_3: 'AZE',
    flag: '🇦🇿',
    official_name: 'Republic of Azerbaijan',
  },
  {
    name: 'Bahamas',
    id: 17,
    phone: '+1242',
    symbol: 'B$',
    capital: 'Nassau',
    currency: 'BSD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'BS',
    alpha_3: 'BHS',
    flag: '🇧🇸',
    official_name: 'Commonwealth of the Bahamas',
  },
  {
    name: 'Bahrain',
    id: 18,
    phone: '+973',
    symbol: '.د.ب',
    capital: 'Manama',
    currency: 'BHD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'BH',
    alpha_3: 'BHR',
    flag: '🇧🇭',
    official_name: 'Kingdom of Bahrain',
  },
  {
    name: 'Bangladesh',
    id: 19,
    phone: '+880',
    symbol: '৳',
    capital: 'Dhaka',
    currency: 'BDT',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'BD',
    alpha_3: 'BGD',
    flag: '🇧🇩',
    official_name: "People's Republic of Bangladesh",
  },
  {
    name: 'Barbados',
    id: 20,
    phone: '+1246',
    symbol: 'Bds$',
    capital: 'Bridgetown',
    currency: 'BBD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'BB',
    alpha_3: 'BRB',
    flag: '🇧🇧',
  },
  {
    name: 'Belarus',
    id: 21,
    phone: '+375',
    symbol: 'Br',
    capital: 'Minsk',
    currency: 'BYN',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'BY',
    alpha_3: 'BLR',
    flag: '🇧🇾',
    official_name: 'Republic of Belarus',
  },
  {
    name: 'Belgium',
    id: 22,
    phone: '+32',
    symbol: '€',
    capital: 'Brussels',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'BE',
    alpha_3: 'BEL',
    flag: '🇧🇪',
    official_name: 'Kingdom of Belgium',
  },
  {
    name: 'Belize',
    id: 23,
    phone: '+501',
    symbol: '$',
    capital: 'Belmopan',
    currency: 'BZD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'BZ',
    alpha_3: 'BLZ',
    flag: '🇧🇿',
  },
  {
    name: 'Benin',
    id: 24,
    phone: '+229',
    symbol: 'CFA',
    capital: 'Porto-Novo',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'BJ',
    alpha_3: 'BEN',
    flag: '🇧🇯',
    official_name: 'Republic of Benin',
  },
  {
    name: 'Bermuda',
    id: 25,
    phone: '+1441',
    symbol: '$',
    capital: 'Hamilton',
    currency: 'BMD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'BM',
    alpha_3: 'BMU',
    flag: '🇧🇲',
  },
  {
    name: 'Bhutan',
    id: 26,
    phone: '+975',
    symbol: 'Nu.',
    capital: 'Thimphu',
    currency: 'BTN',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'BT',
    alpha_3: 'BTN',
    flag: '🇧🇹',
    official_name: 'Kingdom of Bhutan',
  },
  {
    name: 'Bolivia',
    id: 27,
    phone: '+591',
    symbol: 'Bs.',
    capital: 'Sucre',
    currency: 'BOB',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'BO',
    alpha_3: 'BOL',
    flag: '🇧🇴',
    official_name: 'Plurinational State of Bolivia',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    id: 28,
    phone: '+599',
    symbol: '$',
    capital: 'Kralendijk',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'AFG',
    alpha_3: 'BES',
    flag: '🇧🇶',
    official_name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 29,
    phone: '+387',
    symbol: 'KM',
    capital: 'Sarajevo',
    currency: 'BAM',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'BA',
    alpha_3: 'BIH',
    flag: '🇧🇦',
    official_name: 'Republic of Bosnia and Herzegovina',
  },
  {
    name: 'Botswana',
    id: 30,
    phone: '+267',
    symbol: 'P',
    capital: 'Gaborone',
    currency: 'BWP',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'BW',
    alpha_3: 'BWA',
    flag: '🇧🇼',
    official_name: 'Republic of Botswana',
  },
  {
    name: 'Bouvet Island',
    id: 31,
    phone: '+55',
    symbol: 'kr',
    capital: '',
    currency: 'NOK',
    continent: 'Antarctica',
    continent_code: 'AN',
    alpha_2: 'BV',
    alpha_3: 'BVT',
    flag: '🇧🇻',
  },
  {
    name: 'Brazil',
    id: 32,
    phone: '+55',
    symbol: 'R$',
    capital: 'Brasilia',
    currency: 'BRL',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'BR',
    alpha_3: 'BRA',
    flag: '🇧🇷',
    official_name: 'Federative Republic of Brazil',
  },
  {
    name: 'British Indian Ocean Territory',
    id: 33,
    phone: '+246',
    symbol: '$',
    capital: 'Diego Garcia',
    currency: 'USD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'IO',
    alpha_3: 'IOT',
    flag: '🇮🇴',
  },
  {
    name: 'Brunei Darussalam',
    id: 34,
    phone: '+673',
    symbol: 'B$',
    capital: 'Bandar Seri Begawan',
    currency: 'BND',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'BN',
    alpha_3: 'BRN',
    flag: '🇧🇳',
  },
  {
    name: 'Bulgaria',
    id: 35,
    phone: '+359',
    symbol: 'Лв.',
    capital: 'Sofia',
    currency: 'BGN',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'BG',
    alpha_3: 'BGR',
    flag: '🇧🇬',
    official_name: 'Republic of Bulgaria',
  },
  {
    name: 'Burkina Faso',
    id: 36,
    phone: '+226',
    symbol: 'CFA',
    capital: 'Ouagadougou',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'BF',
    alpha_3: 'BFA',
    flag: '🇧🇫',
  },
  {
    name: 'Burundi',
    id: 37,
    phone: '+257',
    symbol: 'FBu',
    capital: 'Bujumbura',
    currency: 'BIF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'BI',
    alpha_3: 'BDI',
    flag: '🇧🇮',
    official_name: 'Republic of Burundi',
  },
  {
    name: 'Cambodia',
    id: 38,
    phone: '+855',
    symbol: 'KHR',
    capital: 'Phnom Penh',
    currency: 'KHR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KH',
    alpha_3: 'KHM',
    flag: '🇰🇭',
    official_name: 'Kingdom of Cambodia',
  },
  {
    name: 'Cameroon',
    id: 39,
    phone: '+237',
    symbol: 'FCFA',
    capital: 'Yaounde',
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CM',
    alpha_3: 'CMR',
    flag: '🇨🇲',
    official_name: 'Republic of Cameroon',
  },
  {
    name: 'Canada',
    id: 40,
    phone: '+1',
    symbol: '$',
    capital: 'Ottawa',
    currency: 'CAD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'CA',
    alpha_3: 'CAN',
    flag: '🇨🇦',
  },
  {
    name: 'Cape Verde',
    id: 41,
    phone: '+238',
    symbol: '$',
    capital: 'Praia',
    currency: 'CVE',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CV',
    alpha_3: 'CPV',
    flag: '🇨🇻',
    official_name: 'Republic of Cabo Verde',
  },
  {
    name: 'Cayman Islands',
    id: 42,
    phone: '+1345',
    symbol: '$',
    capital: 'George Town',
    currency: 'KYD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'KY',
    alpha_3: 'CYM',
    flag: '🇰🇾',
  },
  {
    name: 'Central African Republic',
    id: 43,
    phone: '+236',
    symbol: 'FCFA',
    capital: 'Bangui',
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CF',
    alpha_3: 'CAF',
    flag: '🇨🇫',
  },
  {
    name: 'Chad',
    id: 44,
    phone: '+235',
    symbol: 'FCFA',
    capital: "N'Djamena",
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'TD',
    alpha_3: 'TCD',
    flag: '🇹🇩',
    official_name: 'Republic of Chad',
  },
  {
    name: 'Chile',
    id: 45,
    phone: '+56',
    symbol: '$',
    capital: 'Santiago',
    currency: 'CLP',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'CL',
    alpha_3: 'CHL',
    flag: '🇨🇱',
    official_name: 'Republic of Chile',
  },
  {
    name: 'China',
    id: 46,
    phone: '+86',
    symbol: '¥',
    capital: 'Beijing',
    currency: 'CNY',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'CN',
    alpha_3: 'CHN',
    flag: '🇨🇳',
    official_name: "People's Republic of China",
  },
  {
    name: 'Christmas Island',
    id: 47,
    phone: '+61',
    symbol: '$',
    capital: 'Flying Fish Cove',
    currency: 'AUD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'CX',
    alpha_3: 'CXR',
    flag: '🇨🇽',
  },
  {
    name: 'Cocos (Keeling) Islands',
    id: 48,
    phone: '+672',
    symbol: '$',
    capital: 'West Island',
    currency: 'AUD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'CC',
    alpha_3: 'CCK',
    flag: '🇨🇨',
  },
  {
    name: 'Colombia',
    id: 49,
    phone: '+57',
    symbol: '$',
    capital: 'Bogota',
    currency: 'COP',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'CO',
    alpha_3: 'COL',
    flag: '🇨🇴',
    official_name: 'Republic of Colombia',
  },
  {
    name: 'Comoros',
    id: 50,
    phone: '+269',
    symbol: 'CF',
    capital: 'Moroni',
    currency: 'KMF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'KM',
    alpha_3: 'COM',
    flag: '🇰🇲',
    official_name: 'Union of the Comoros',
  },
  {
    name: 'Congo',
    id: 51,
    phone: '+242',
    symbol: 'FC',
    capital: 'Brazzaville',
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CG',
    alpha_3: 'COG',
    flag: '🇨🇬',
    official_name: 'Republic of the Congo',
  },
  {
    name: 'Congo, Democratic Republic of the Congo',
    id: 52,
    phone: '+242',
    symbol: 'FC',
    capital: 'Kinshasa',
    currency: 'CDF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CD',
    alpha_3: 'COD',
    flag: '🇨🇩',
  },
  {
    name: 'Cook Islands',
    id: 53,
    phone: '+682',
    symbol: '$',
    capital: 'Avarua',
    currency: 'NZD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'CK',
    alpha_3: 'COK',
    flag: '🇨🇰',
  },
  {
    name: 'Costa Rica',
    id: 54,
    phone: '+506',
    symbol: '₡',
    capital: 'San Jose',
    currency: 'CRC',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'CR',
    alpha_3: 'CRI',
    flag: '🇨🇷',
    official_name: 'Republic of Costa Rica',
  },
  {
    name: "Cote D'Ivoire",
    id: 55,
    phone: '+225',
    symbol: 'CFA',
    capital: 'Yamoussoukro',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'CI',
    alpha_3: 'CIV',
    flag: '🇨🇮',
    official_name: "Republic of Côte d'Ivoire",
  },
  {
    name: 'Croatia',
    id: 56,
    phone: '+385',
    symbol: 'kn',
    capital: 'Zagreb',
    currency: 'HRK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'HR',
    alpha_3: 'HRV',
    flag: '🇭🇷',
    official_name: 'Republic of Croatia',
  },
  {
    name: 'Cuba',
    id: 57,
    phone: '+53',
    symbol: '$',
    capital: 'Havana',
    currency: 'CUP',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'CU',
    alpha_3: 'CUB',
    flag: '🇨🇺',
    official_name: 'Republic of Cuba',
  },
  {
    name: 'Curacao',
    id: 58,
    phone: '+599',
    symbol: 'ƒ',
    capital: 'Willemstad',
    currency: 'ANG',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'CW',
    alpha_3: 'CUW',
    flag: '🇨🇼',
    official_name: 'Curaçao',
  },
  {
    name: 'Cyprus',
    id: 59,
    phone: '+357',
    symbol: '€',
    capital: 'Nicosia',
    currency: 'EUR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'CY',
    alpha_3: 'CYP',
    flag: '🇨🇾',
    official_name: 'Republic of Cyprus',
  },
  {
    name: 'Czech Republic',
    id: 60,
    phone: '+420',
    symbol: 'Kč',
    capital: 'Prague',
    currency: 'CZK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'CZ',
    alpha_3: 'CZE',
    flag: '🇨🇿',
    official_name: 'Czech Republic',
  },
  {
    name: 'Denmark',
    id: 61,
    phone: '+45',
    symbol: 'Kr.',
    capital: 'Copenhagen',
    currency: 'DKK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'DK',
    alpha_3: 'DNK',
    flag: '🇩🇰',
    official_name: 'Kingdom of Denmark',
  },
  {
    name: 'Djibouti',
    id: 62,
    phone: '+253',
    symbol: 'Fdj',
    capital: 'Djibouti',
    currency: 'DJF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'DJ',
    alpha_3: 'DJI',
    flag: '🇩🇯',
    official_name: 'Republic of Djibouti',
  },
  {
    name: 'Dominica',
    id: 63,
    phone: '+1767',
    symbol: '$',
    capital: 'Roseau',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'DM',
    alpha_3: 'DMA',
    flag: '🇩🇲',
    official_name: 'Commonwealth of Dominica',
  },
  {
    name: 'Dominican Republic',
    id: 64,
    phone: '+1809',
    symbol: '$',
    capital: 'Santo Domingo',
    currency: 'DOP',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'DO',
    alpha_3: 'DOM',
    flag: '🇩🇴',
  },
  {
    name: 'Ecuador',
    id: 65,
    phone: '+593',
    symbol: '$',
    capital: 'Quito',
    currency: 'USD',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'EC',
    alpha_3: 'ECU',
    flag: '🇪🇨',
    official_name: 'Republic of Ecuador',
  },
  {
    name: 'Egypt',
    id: 66,
    phone: '+20',
    symbol: 'ج.م',
    capital: 'Cairo',
    currency: 'EGP',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'EG',
    alpha_3: 'EGY',
    flag: '🇪🇬',
    official_name: 'Arab Republic of Egypt',
  },
  {
    name: 'El Salvador',
    id: 67,
    phone: '+503',
    symbol: '$',
    capital: 'San Salvador',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'SV',
    alpha_3: 'SLV',
    flag: '🇸🇻',
    official_name: 'Republic of El Salvador',
  },
  {
    name: 'Equatorial Guinea',
    id: 68,
    phone: '+240',
    symbol: 'FCFA',
    capital: 'Malabo',
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GQ',
    alpha_3: 'GNQ',
    flag: '🇬🇶',
    official_name: 'Republic of Equatorial Guinea',
  },
  {
    name: 'Eritrea',
    id: 69,
    phone: '+291',
    symbol: 'Nfk',
    capital: 'Asmara',
    currency: 'ERN',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ER',
    alpha_3: 'ERI',
    flag: '🇪🇷',
    official_name: 'the State of Eritrea',
  },
  {
    name: 'Estonia',
    id: 70,
    phone: '+372',
    symbol: '€',
    capital: 'Tallinn',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'EE',
    alpha_3: 'EST',
    flag: '🇪🇪',
    official_name: 'Republic of Estonia',
  },
  {
    name: 'Ethiopia',
    id: 71,
    phone: '+251',
    symbol: 'Nkf',
    capital: 'Addis Ababa',
    currency: 'ETB',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ET',
    alpha_3: 'ETH',
    flag: '🇪🇹',
    official_name: 'Federal Democratic Republic of Ethiopia',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    id: 72,
    phone: '+500',
    symbol: '£',
    capital: 'Stanley',
    currency: 'FKP',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'FK',
    alpha_3: 'FLK',
    flag: '🇫🇰',
  },
  {
    name: 'Faroe Islands',
    id: 73,
    phone: '+298',
    symbol: 'Kr.',
    capital: 'Torshavn',
    currency: 'DKK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'FO',
    alpha_3: 'FRO',
    flag: '🇫🇴',
  },
  {
    name: 'Fiji',
    id: 74,
    phone: '+679',
    symbol: 'FJ$',
    capital: 'Suva',
    currency: 'FJD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'FJ',
    alpha_3: 'FJI',
    flag: '🇫🇯',
    official_name: 'Republic of Fiji',
  },
  {
    name: 'Finland',
    id: 75,
    phone: '+358',
    symbol: '€',
    capital: 'Helsinki',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'FI',
    alpha_3: 'FIN',
    flag: '🇫🇮',
    official_name: 'Republic of Finland',
  },
  {
    name: 'France',
    id: 76,
    phone: '+33',
    symbol: '€',
    capital: 'Paris',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'FR',
    alpha_3: 'FRA',
    flag: '🇫🇷',
    official_name: 'French Republic',
  },
  {
    name: 'French Guiana',
    id: 77,
    phone: '+594',
    symbol: '€',
    capital: 'Cayenne',
    currency: 'EUR',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'GF',
    alpha_3: 'GUF',
    flag: '🇬🇫',
  },
  {
    name: 'French Polynesia',
    id: 78,
    phone: '+689',
    symbol: '₣',
    capital: 'Papeete',
    currency: 'XPF',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'PF',
    alpha_3: 'PYF',
    flag: '🇵🇫',
  },
  {
    name: 'French Southern Territories',
    id: 79,
    phone: '+262',
    symbol: '€',
    capital: 'Port-aux-Francais',
    currency: 'EUR',
    continent: 'Antarctica',
    continent_code: 'AN',
    alpha_2: 'TF',
    alpha_3: 'ATF',
    flag: '🇹🇫',
  },
  {
    name: 'Gabon',
    id: 80,
    phone: '+241',
    symbol: 'FCFA',
    capital: 'Libreville',
    currency: 'XAF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GA',
    alpha_3: 'GAB',
    flag: '🇬🇦',
    official_name: 'Gabonese Republic',
  },
  {
    name: 'Gambia',
    id: 81,
    phone: '+220',
    symbol: 'D',
    capital: 'Banjul',
    currency: 'GMD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GM',
    alpha_3: 'GMB',
    flag: '🇬🇲',
    official_name: 'Republic of the Gambia',
  },
  {
    name: 'Georgia',
    id: 82,
    phone: '+995',
    symbol: 'ლ',
    capital: 'Tbilisi',
    currency: 'GEL',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'GE',
    alpha_3: 'GEO',
    flag: '🇬🇪',
  },
  {
    name: 'Germany',
    id: 83,
    phone: '+49',
    symbol: '€',
    capital: 'Berlin',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'DE',
    alpha_3: 'DEU',
    flag: '🇩🇪',
    official_name: 'Federal Republic of Germany',
  },
  {
    name: 'Ghana',
    id: 84,
    phone: '+233',
    symbol: 'GH₵',
    capital: 'Accra',
    currency: 'GHS',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GH',
    alpha_3: 'GHA',
    flag: '🇬🇭',
    official_name: 'Republic of Ghana',
  },
  {
    name: 'Gibraltar',
    id: 85,
    phone: '+350',
    symbol: '£',
    capital: 'Gibraltar',
    currency: 'GIP',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'GI',
    alpha_3: 'GIB',
    flag: '🇬🇮',
  },
  {
    name: 'Greece',
    id: 86,
    phone: '+30',
    symbol: '€',
    capital: 'Athens',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'GR',
    alpha_3: 'GRC',
    flag: '🇬🇷',
    official_name: 'Hellenic Republic',
  },
  {
    name: 'Greenland',
    id: 87,
    phone: '+299',
    symbol: 'Kr.',
    capital: 'Nuuk',
    currency: 'DKK',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'GL',
    alpha_3: 'GRL',
    flag: '🇬🇱',
  },
  {
    name: 'Grenada',
    id: 88,
    phone: '+1473',
    symbol: '$',
    capital: "St. George's",
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'GD',
    alpha_3: 'GRD',
    flag: '🇬🇩',
  },
  {
    name: 'Guadeloupe',
    id: 89,
    phone: '+590',
    symbol: '€',
    capital: 'Basse-Terre',
    currency: 'EUR',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'GP',
    alpha_3: 'GLP',
    flag: '🇬🇵',
  },
  {
    name: 'Guam',
    id: 90,
    phone: '+1671',
    symbol: '$',
    capital: 'Hagatna',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'GU',
    alpha_3: 'GUM',
    flag: '🇬🇺',
  },
  {
    name: 'Guatemala',
    id: 91,
    phone: '+502',
    symbol: 'Q',
    capital: 'Guatemala City',
    currency: 'GTQ',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'GT',
    alpha_3: 'GTM',
    flag: '🇬🇹',
    official_name: 'Republic of Guatemala',
  },
  {
    name: 'Guernsey',
    id: 92,
    phone: '+44',
    symbol: '£',
    capital: 'St Peter Port',
    currency: 'GBP',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'GG',
    alpha_3: 'GGY',
    flag: '🇬🇬',
  },
  {
    name: 'Guinea',
    id: 93,
    phone: '+224',
    symbol: 'FG',
    capital: 'Conakry',
    currency: 'GNF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GN',
    alpha_3: 'GIN',
    flag: '🇬🇳',
    official_name: 'Republic of Guinea',
  },
  {
    name: 'Guinea-Bissau',
    id: 94,
    phone: '+245',
    symbol: 'CFA',
    capital: 'Bissau',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'GW',
    alpha_3: 'GNB',
    flag: '🇬🇼',
    official_name: 'Republic of Guinea-Bissau',
  },
  {
    name: 'Guyana',
    id: 95,
    phone: '+592',
    symbol: '$',
    capital: 'Georgetown',
    currency: 'GYD',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'GY',
    alpha_3: 'GUY',
    flag: '🇬🇾',
    official_name: 'Republic of Guyana',
  },
  {
    name: 'Haiti',
    id: 96,
    phone: '+509',
    symbol: 'G',
    capital: 'Port-au-Prince',
    currency: 'HTG',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'HT',
    alpha_3: 'HTI',
    flag: '🇭🇹',
    official_name: 'Republic of Haiti',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    id: 97,
    phone: '+0',
    symbol: '$',
    capital: '',
    currency: 'AUD',
    continent: 'Antarctica',
    continent_code: 'AN',
    alpha_2: 'HM',
    alpha_3: 'HMD',
    flag: '🇭🇲',
  },
  {
    name: 'Holy See (Vatican City State)',
    id: 98,
    phone: '+39',
    symbol: '€',
    capital: 'Vatican City',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'VA',
    alpha_3: 'VAT',
    flag: '🇻🇦',
  },
  {
    name: 'Honduras',
    id: 99,
    phone: '+504',
    symbol: 'L',
    capital: 'Tegucigalpa',
    currency: 'HNL',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'HN',
    alpha_3: 'HND',
    flag: '🇭🇳',
    official_name: 'Republic of Honduras',
  },
  {
    name: 'Hong Kong',
    id: 100,
    phone: '+852',
    symbol: '$',
    capital: 'Hong Kong',
    currency: 'HKD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'HK',
    alpha_3: 'HKG',
    flag: '🇭🇰',
    official_name: 'Hong Kong Special Administrative Region of China',
  },
  {
    name: 'Hungary',
    id: 101,
    phone: '+36',
    symbol: 'Ft',
    capital: 'Budapest',
    currency: 'HUF',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'HU',
    alpha_3: 'HUN',
    flag: '🇭🇺',
    official_name: 'Hungary',
  },
  {
    name: 'Iceland',
    id: 102,
    phone: '+354',
    symbol: 'kr',
    capital: 'Reykjavik',
    currency: 'ISK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'IS',
    alpha_3: 'ISL',
    flag: '🇮🇸',
    official_name: 'Republic of Iceland',
  },
  {
    name: 'India',
    id: 103,
    phone: '+91',
    symbol: '₹',
    capital: 'New Delhi',
    currency: 'INR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'IN',
    alpha_3: 'IND',
    flag: '🇮🇳',
    official_name: 'Republic of India',
  },
  {
    name: 'Indonesia',
    id: 104,
    phone: '+62',
    symbol: 'Rp',
    capital: 'Jakarta',
    currency: 'IDR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'ID',
    alpha_3: 'IDN',
    flag: '🇮🇩',
    official_name: 'Republic of Indonesia',
  },
  {
    name: 'Iran, Islamic Republic of',
    id: 105,
    phone: '+98',
    symbol: '﷼',
    capital: 'Tehran',
    currency: 'IRR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'IR',
    alpha_3: 'IRN',
    flag: '🇮🇷',
    official_name: 'Islamic Republic of Iran',
  },
  {
    name: 'Iraq',
    id: 106,
    phone: '+964',
    symbol: 'د.ع',
    capital: 'Baghdad',
    currency: 'IQD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'IQ',
    alpha_3: 'IRQ',
    flag: '🇮🇶',
    official_name: 'Republic of Iraq',
  },
  {
    name: 'Ireland',
    id: 107,
    phone: '+353',
    symbol: '€',
    capital: 'Dublin',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'IE',
    alpha_3: 'IRL',
    flag: '🇮🇪',
  },
  {
    name: 'Isle of Man',
    id: 108,
    phone: '+44',
    symbol: '£',
    capital: 'Douglas, Isle of Man',
    currency: 'GBP',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'IM',
    alpha_3: 'IMN',
    flag: '🇮🇲',
  },
  {
    name: 'Israel',
    id: 109,
    phone: '+972',
    symbol: '₪',
    capital: 'Jerusalem',
    currency: 'ILS',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'IL',
    alpha_3: 'ISR',
    flag: '🇮🇱',
    official_name: 'State of Israel',
  },
  {
    name: 'Italy',
    id: 110,
    phone: '+39',
    symbol: '€',
    capital: 'Rome',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'IT',
    alpha_3: 'ITA',
    flag: '🇮🇹',
    official_name: 'Italian Republic',
  },
  {
    name: 'Jamaica',
    id: 111,
    phone: '+1876',
    symbol: 'J$',
    capital: 'Kingston',
    currency: 'JMD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'JM',
    alpha_3: 'JAM',
    flag: '🇯🇲',
  },
  {
    name: 'Japan',
    id: 112,
    phone: '+81',
    symbol: '¥',
    capital: 'Tokyo',
    currency: 'JPY',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'JP',
    alpha_3: 'JPN',
    flag: '🇯🇵',
  },
  {
    name: 'Jersey',
    id: 113,
    phone: '+44',
    symbol: '£',
    capital: 'Saint Helier',
    currency: 'GBP',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'JE',
    alpha_3: 'JEY',
    flag: '🇯🇪',
  },
  {
    name: 'Jordan',
    id: 114,
    phone: '+962',
    symbol: 'ا.د',
    capital: 'Amman',
    currency: 'JOD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'JO',
    alpha_3: 'JOR',
    flag: '🇯🇴',
    official_name: 'Hashemite Kingdom of Jordan',
  },
  {
    name: 'Kazakhstan',
    id: 115,
    phone: '+7',
    symbol: 'лв',
    capital: 'Astana',
    currency: 'KZT',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KZ',
    alpha_3: 'KAZ',
    flag: '🇰🇿',
    official_name: 'Republic of Kazakhstan',
  },
  {
    name: 'Kenya',
    id: 116,
    phone: '+254',
    symbol: 'KSh',
    capital: 'Nairobi',
    currency: 'KES',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'KE',
    alpha_3: 'KEN',
    flag: '🇰🇪',
    official_name: 'Republic of Kenya',
  },
  {
    name: 'Kiribati',
    id: 117,
    phone: '+686',
    symbol: '$',
    capital: 'Tarawa',
    currency: 'AUD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'KI',
    alpha_3: 'KIR',
    flag: '🇰🇮',
    official_name: 'Republic of Kiribati',
  },
  {
    name: "Korea, Democratic People's Republic of",
    id: 118,
    phone: '+850',
    symbol: '₩',
    capital: 'Pyongyang',
    currency: 'KPW',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KP',
    alpha_3: 'PRK',
    flag: '🇰🇵',
    official_name: "Democratic People's Republic of Korea",
  },
  {
    name: 'Korea, Republic of',
    id: 119,
    phone: '+82',
    symbol: '₩',
    capital: 'Seoul',
    currency: 'KRW',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KR',
    alpha_3: 'KOR',
    flag: '🇰🇷',
  },
  {
    name: 'Kosovo',
    id: 120,
    phone: '+381',
    symbol: '€',
    capital: 'Pristina',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'XK',
    alpha_3: 'XKX',
    flag: '🇽🇰',
  },
  {
    name: 'Kuwait',
    id: 121,
    phone: '+965',
    symbol: 'ك.د',
    capital: 'Kuwait City',
    currency: 'KWD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KW',
    alpha_3: 'KWT',
    flag: '🇰🇼',
    official_name: 'State of Kuwait',
  },
  {
    name: 'Kyrgyzstan',
    id: 122,
    phone: '+996',
    symbol: 'лв',
    capital: 'Bishkek',
    currency: 'KGS',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'KG',
    alpha_3: 'KGZ',
    flag: '🇰🇬',
    official_name: 'Kyrgyz Republic',
  },
  {
    name: "Lao People's Democratic Republic",
    id: 123,
    phone: '+856',
    symbol: '₭',
    capital: 'Vientiane',
    currency: 'LAK',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'LA',
    alpha_3: 'LAO',
    flag: '🇱🇦',
  },
  {
    name: 'Latvia',
    id: 124,
    phone: '+371',
    symbol: '€',
    capital: 'Riga',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'LV',
    alpha_3: 'LVA',
    flag: '🇱🇻',
    official_name: 'Republic of Latvia',
  },
  {
    name: 'Lebanon',
    id: 125,
    phone: '+961',
    symbol: '£',
    capital: 'Beirut',
    currency: 'LBP',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'LB',
    alpha_3: 'LBN',
    flag: '🇱🇧',
    official_name: 'Lebanese Republic',
  },
  {
    name: 'Lesotho',
    id: 126,
    phone: '+266',
    symbol: 'L',
    capital: 'Maseru',
    currency: 'LSL',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'LS',
    alpha_3: 'LSO',
    flag: '🇱🇸',
    official_name: 'Kingdom of Lesotho',
  },
  {
    name: 'Liberia',
    id: 127,
    phone: '+231',
    symbol: '$',
    capital: 'Monrovia',
    currency: 'LRD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'LR',
    alpha_3: 'LBR',
    flag: '🇱🇷',
    official_name: 'Republic of Liberia',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    id: 128,
    phone: '+218',
    symbol: 'د.ل',
    capital: 'Tripolis',
    currency: 'LYD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'LY',
    alpha_3: 'LBY',
    flag: '🇱🇾',
    official_name: 'Libya',
  },
  {
    name: 'Liechtenstein',
    id: 129,
    phone: '+423',
    symbol: 'CHf',
    capital: 'Vaduz',
    currency: 'CHF',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'LI',
    alpha_3: 'LIE',
    flag: '🇱🇮',
    official_name: 'Principality of Liechtenstein',
  },
  {
    name: 'Lithuania',
    id: 130,
    phone: '+370',
    symbol: '€',
    capital: 'Vilnius',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'LT',
    alpha_3: 'LTU',
    flag: '🇱🇹',
    official_name: 'Republic of Lithuania',
  },
  {
    name: 'Luxembourg',
    id: 131,
    phone: '+352',
    symbol: '€',
    capital: 'Luxembourg',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'LU',
    alpha_3: 'LUX',
    flag: '🇱🇺',
    official_name: 'Grand Duchy of Luxembourg',
  },
  {
    name: 'Macao',
    id: 132,
    phone: '+853',
    symbol: '$',
    capital: 'Macao',
    currency: 'MOP',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'MO',
    alpha_3: 'MAC',
    flag: '🇲🇴',
    official_name: 'Macao Special Administrative Region of China',
  },
  {
    name: 'North Macedonia',
    id: 133,
    phone: '+389',
    symbol: 'ден',
    capital: 'Skopje',
    currency: 'MKD',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'MK',
    alpha_3: 'MKD',
    flag: '🇲🇰',
    official_name: 'Republic of North Macedonia',
  },
  {
    name: 'Madagascar',
    id: 134,
    phone: '+261',
    symbol: 'Ar',
    capital: 'Antananarivo',
    currency: 'MGA',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MG',
    alpha_3: 'MDG',
    flag: '🇲🇬',
    official_name: 'Republic of Madagascar',
  },
  {
    name: 'Malawi',
    id: 135,
    phone: '+265',
    symbol: 'MK',
    capital: 'Lilongwe',
    currency: 'MWK',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MW',
    alpha_3: 'MWI',
    flag: '🇲🇼',
    official_name: 'Republic of Malawi',
  },
  {
    name: 'Malaysia',
    id: 136,
    phone: '+60',
    symbol: 'RM',
    capital: 'Kuala Lumpur',
    currency: 'MYR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'MY',
    alpha_3: 'MYS',
    flag: '🇲🇾',
  },
  {
    name: 'Maldives',
    id: 137,
    phone: '+960',
    symbol: 'Rf',
    capital: 'Male',
    currency: 'MVR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'MV',
    alpha_3: 'MDV',
    flag: '🇲🇻',
    official_name: 'Republic of Maldives',
  },
  {
    name: 'Mali',
    id: 138,
    phone: '+223',
    symbol: 'CFA',
    capital: 'Bamako',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ML',
    alpha_3: 'MLI',
    flag: '🇲🇱',
    official_name: 'Republic of Mali',
  },
  {
    name: 'Malta',
    id: 139,
    phone: '+356',
    symbol: '€',
    capital: 'Valletta',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'MT',
    alpha_3: 'MLT',
    flag: '🇲🇹',
    official_name: 'Republic of Malta',
  },
  {
    name: 'Marshall Islands',
    id: 140,
    phone: '+692',
    symbol: '$',
    capital: 'Majuro',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'MH',
    alpha_3: 'MHL',
    flag: '🇲🇭',
    official_name: 'Republic of the Marshall Islands',
  },
  {
    name: 'Martinique',
    id: 141,
    phone: '+596',
    symbol: '€',
    capital: 'Fort-de-France',
    currency: 'EUR',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'MQ',
    alpha_3: 'MTQ',
    flag: '🇲🇶',
  },
  {
    name: 'Mauritania',
    id: 142,
    phone: '+222',
    symbol: 'MRU',
    capital: 'Nouakchott',
    currency: 'MRO',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MR',
    alpha_3: 'MRT',
    flag: '🇲🇷',
    official_name: 'Islamic Republic of Mauritania',
  },
  {
    name: 'Mauritius',
    id: 143,
    phone: '+230',
    symbol: '₨',
    capital: 'Port Louis',
    currency: 'MUR',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MU',
    alpha_3: 'MUS',
    flag: '🇲🇺',
    official_name: 'Republic of Mauritius',
  },
  {
    name: 'Mayotte',
    id: 144,
    phone: '+269',
    symbol: '€',
    capital: 'Mamoudzou',
    currency: 'EUR',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'YT',
    alpha_3: 'MYT',
    flag: '🇾🇹',
  },
  {
    name: 'Mexico',
    id: 145,
    phone: '+52',
    symbol: '$',
    capital: 'Mexico City',
    currency: 'MXN',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'MX',
    alpha_3: 'MEX',
    flag: '🇲🇽',
    official_name: 'United Mexican States',
  },
  {
    name: 'Micronesia, Federated States of',
    id: 146,
    phone: '+691',
    symbol: '$',
    capital: 'Palikir',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'FM',
    alpha_3: 'FSM',
    flag: '🇫🇲',
    official_name: 'Federated States of Micronesia',
  },
  {
    name: 'Moldova',
    id: 147,
    phone: '+373',
    symbol: 'L',
    capital: 'Chisinau',
    currency: 'MDL',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'MD',
    alpha_3: 'MDA',
    flag: '🇲🇩',
    official_name: 'Republic of Moldova',
  },
  {
    name: 'Monaco',
    id: 148,
    phone: '+377',
    symbol: '€',
    capital: 'Monaco',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'MC',
    alpha_3: 'MCO',
    flag: '🇲🇨',
    official_name: 'Principality of Monaco',
  },
  {
    name: 'Mongolia',
    id: 149,
    phone: '+976',
    symbol: '₮',
    capital: 'Ulan Bator',
    currency: 'MNT',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'MN',
    alpha_3: 'MNG',
    flag: '🇲🇳',
  },
  {
    name: 'Montenegro',
    id: 150,
    phone: '+382',
    symbol: '€',
    capital: 'Podgorica',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'ME',
    alpha_3: 'MNE',
    flag: '🇲🇪',
    official_name: 'Montenegro',
  },
  {
    name: 'Montserrat',
    id: 151,
    phone: '+1664',
    symbol: '$',
    capital: 'Plymouth',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'MS',
    alpha_3: 'MSR',
    flag: '🇲🇸',
  },
  {
    name: 'Morocco',
    id: 152,
    phone: '+212',
    symbol: 'DH',
    capital: 'Rabat',
    currency: 'MAD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MA',
    alpha_3: 'MAR',
    flag: '🇲🇦',
    official_name: 'Kingdom of Morocco',
  },
  {
    name: 'Mozambique',
    id: 153,
    phone: '+258',
    symbol: 'MT',
    capital: 'Maputo',
    currency: 'MZN',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'MZ',
    alpha_3: 'MOZ',
    flag: '🇲🇿',
    official_name: 'Republic of Mozambique',
  },
  {
    name: 'Myanmar',
    id: 154,
    phone: '+95',
    symbol: 'K',
    capital: 'Nay Pyi Taw',
    currency: 'MMK',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'MM',
    alpha_3: 'MMR',
    flag: '🇲🇲',
    official_name: 'Republic of Myanmar',
  },
  {
    name: 'Namibia',
    id: 155,
    phone: '+264',
    symbol: '$',
    capital: 'Windhoek',
    currency: 'NAD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'NA',
    alpha_3: 'NAM',
    flag: '🇳🇦',
    official_name: 'Republic of Namibia',
  },
  {
    name: 'Nauru',
    id: 156,
    phone: '+674',
    symbol: '$',
    capital: 'Yaren',
    currency: 'AUD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'NR',
    alpha_3: 'NRU',
    flag: '🇳🇷',
    official_name: 'Republic of Nauru',
  },
  {
    name: 'Nepal',
    id: 157,
    phone: '+977',
    symbol: '₨',
    capital: 'Kathmandu',
    currency: 'NPR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'NP',
    alpha_3: 'NPL',
    flag: '🇳🇵',
    official_name: 'Federal Democratic Republic of Nepal',
  },
  {
    name: 'Netherlands',
    id: 158,
    phone: '+31',
    symbol: '€',
    capital: 'Amsterdam',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'NL',
    alpha_3: 'NLD',
    flag: '🇳🇱',
    official_name: 'Kingdom of the Netherlands',
  },
  {
    name: 'New Caledonia',
    id: 160,
    phone: '+687',
    symbol: '₣',
    capital: 'Noumea',
    currency: 'XPF',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'NC',
    alpha_3: 'NCL',
    flag: '🇳🇨',
  },
  {
    name: 'New Zealand',
    id: 161,
    phone: '+64',
    symbol: '$',
    capital: 'Wellington',
    currency: 'NZD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'NZ',
    alpha_3: 'NZL',
    flag: '🇳🇿',
  },
  {
    name: 'Nicaragua',
    id: 162,
    phone: '+505',
    symbol: 'C$',
    capital: 'Managua',
    currency: 'NIO',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'NI',
    alpha_3: 'NIC',
    flag: '🇳🇮',
    official_name: 'Republic of Nicaragua',
  },
  {
    name: 'Niger',
    id: 163,
    phone: '+227',
    symbol: 'CFA',
    capital: 'Niamey',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'NE',
    alpha_3: 'NER',
    flag: '🇳🇪',
    official_name: 'Republic of the Niger',
  },
  {
    name: 'Nigeria',
    id: 164,
    phone: '+234',
    symbol: '₦',
    capital: 'Abuja',
    currency: 'NGN',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'NG',
    alpha_3: 'NGA',
    flag: '🇳🇬',
    official_name: 'Federal Republic of Nigeria',
  },
  {
    name: 'Niue',
    id: 165,
    phone: '+683',
    symbol: '$',
    capital: 'Alofi',
    currency: 'NZD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'NU',
    alpha_3: 'NIU',
    flag: '🇳🇺',
    official_name: 'Niue',
  },
  {
    name: 'Norfolk Island',
    id: 166,
    phone: '+672',
    symbol: '$',
    capital: 'Kingston',
    currency: 'AUD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'NF',
    alpha_3: 'NFK',
    flag: '🇳🇫',
  },
  {
    name: 'Northern Mariana Islands',
    id: 167,
    phone: '+1670',
    symbol: '$',
    capital: 'Saipan',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'MP',
    alpha_3: 'MNP',
    flag: '🇲🇵',
    official_name: 'Commonwealth of the Northern Mariana Islands',
  },
  {
    name: 'Norway',
    id: 168,
    phone: '+47',
    symbol: 'kr',
    capital: 'Oslo',
    currency: 'NOK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'NO',
    alpha_3: 'NOR',
    flag: '🇳🇴',
    official_name: 'Kingdom of Norway',
  },
  {
    name: 'Oman',
    id: 169,
    phone: '+968',
    symbol: '.ع.ر',
    capital: 'Muscat',
    currency: 'OMR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'OM',
    alpha_3: 'OMN',
    flag: '🇴🇲',
    official_name: 'Sultanate of Oman',
  },
  {
    name: 'Pakistan',
    id: 170,
    phone: '+92',
    symbol: '₨',
    capital: 'Islamabad',
    currency: 'PKR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'PK',
    alpha_3: 'PAK',
    flag: '🇵🇰',
    official_name: 'Islamic Republic of Pakistan',
  },
  {
    name: 'Palau',
    id: 171,
    phone: '+680',
    symbol: '$',
    capital: 'Melekeok',
    currency: 'USD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'PW',
    alpha_3: 'PLW',
    flag: '🇵🇼',
    official_name: 'Republic of Palau',
  },
  {
    name: 'Palestinian Territory, Occupied',
    id: 172,
    phone: '+970',
    symbol: '₪',
    capital: 'East Jerusalem',
    currency: 'ILS',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'PS',
    alpha_3: 'PSE',
    flag: '🇵🇸',
    official_name: 'the State of Palestine',
  },
  {
    name: 'Panama',
    id: 173,
    phone: '+507',
    symbol: 'B/.',
    capital: 'Panama City',
    currency: 'PAB',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'PA',
    alpha_3: 'PAN',
    flag: '🇵🇦',
    official_name: 'Republic of Panama',
  },
  {
    name: 'Papua New Guinea',
    id: 174,
    phone: '+675',
    symbol: 'K',
    capital: 'Port Moresby',
    currency: 'PGK',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'PG',
    alpha_3: 'PNG',
    flag: '🇵🇬',
    official_name: 'Independent State of Papua New Guinea',
  },
  {
    name: 'Paraguay',
    id: 175,
    phone: '+595',
    symbol: '₲',
    capital: 'Asuncion',
    currency: 'PYG',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'PY',
    alpha_3: 'PRY',
    flag: '🇵🇾',
    official_name: 'Republic of Paraguay',
  },
  {
    name: 'Peru',
    id: 176,
    phone: '+51',
    symbol: 'S/.',
    capital: 'Lima',
    currency: 'PEN',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'PE',
    alpha_3: 'PER',
    flag: '🇵🇪',
    official_name: 'Republic of Peru',
  },
  {
    name: 'Philippines',
    id: 177,
    phone: '+63',
    symbol: '₱',
    capital: 'Manila',
    currency: 'PHP',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'PH',
    alpha_3: 'PHL',
    flag: '🇵🇭',
    official_name: 'Republic of the Philippines',
  },
  {
    name: 'Pitcairn',
    id: 178,
    phone: '+64',
    symbol: '$',
    capital: 'Adamstown',
    currency: 'NZD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'PN',
    alpha_3: 'PCN',
    flag: '🇵🇳',
  },
  {
    name: 'Poland',
    id: 179,
    phone: '+48',
    symbol: 'zł',
    capital: 'Warsaw',
    currency: 'PLN',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'PL',
    alpha_3: 'POL',
    flag: '🇵🇱',
    official_name: 'Republic of Poland',
  },
  {
    name: 'Portugal',
    id: 180,
    phone: '+351',
    symbol: '€',
    capital: 'Lisbon',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'PT',
    alpha_3: 'PRT',
    flag: '🇵🇹',
    official_name: 'Portuguese Republic',
  },
  {
    name: 'Puerto Rico',
    id: 181,
    phone: '+1787',
    symbol: '$',
    capital: 'San Juan',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'PR',
    alpha_3: 'PRI',
    flag: '🇵🇷',
  },
  {
    name: 'Qatar',
    id: 182,
    phone: '+974',
    symbol: 'ق.ر',
    capital: 'Doha',
    currency: 'QAR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'QA',
    alpha_3: 'QAT',
    flag: '🇶🇦',
    official_name: 'State of Qatar',
  },
  {
    name: 'Reunion',
    id: 183,
    phone: '+262',
    symbol: '€',
    capital: 'Saint-Denis',
    currency: 'EUR',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'RE',
    alpha_3: 'REU',
    flag: '🇷🇪',
  },
  {
    name: 'Romania',
    id: 184,
    phone: '+40',
    symbol: 'lei',
    capital: 'Bucharest',
    currency: 'RON',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'RO',
    alpha_3: 'ROU',
    flag: '🇷🇴',
  },
  {
    name: 'Russian Federation',
    id: 185,
    phone: '+70',
    symbol: '₽',
    capital: 'Moscow',
    currency: 'RUB',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'RU',
    alpha_3: 'RUS',
    flag: '🇷🇺',
  },
  {
    name: 'Rwanda',
    id: 186,
    phone: '+250',
    symbol: 'FRw',
    capital: 'Kigali',
    currency: 'RWF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'RW',
    alpha_3: 'RWA',
    flag: '🇷🇼',
    official_name: 'Rwandese Republic',
  },
  {
    name: 'Saint Barthelemy',
    id: 187,
    phone: '+590',
    symbol: '€',
    capital: 'Gustavia',
    currency: 'EUR',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'BL',
    alpha_3: 'BLM',
    flag: '🇧🇱',
  },
  {
    name: 'Saint Helena',
    id: 188,
    phone: '+290',
    symbol: '£',
    capital: 'Jamestown',
    currency: 'SHP',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SH',
    alpha_3: 'SHN',
    flag: '🇸🇭',
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 189,
    phone: '+1869',
    symbol: '$',
    capital: 'Basseterre',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'KN',
    alpha_3: 'KNA',
    flag: '🇰🇳',
  },
  {
    name: 'Saint Lucia',
    id: 190,
    phone: '+1758',
    symbol: '$',
    capital: 'Castries',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'LC',
    alpha_3: 'LCA',
    flag: '🇱🇨',
  },
  {
    name: 'Saint Martin',
    id: 191,
    phone: '+590',
    symbol: '€',
    capital: 'Marigot',
    currency: 'EUR',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'MF',
    alpha_3: 'MAF',
    flag: '🇲🇫',
  },
  {
    name: 'Saint Pierre and Miquelon',
    id: 192,
    phone: '+508',
    symbol: '€',
    capital: 'Saint-Pierre',
    currency: 'EUR',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'PM',
    alpha_3: 'SPM',
    flag: '🇵🇲',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: 193,
    phone: '+1784',
    symbol: '$',
    capital: 'Kingstown',
    currency: 'XCD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'VC',
    alpha_3: 'VCT',
    flag: '🇻🇨',
  },
  {
    name: 'Samoa',
    id: 194,
    phone: '+684',
    symbol: 'SAT',
    capital: 'Apia',
    currency: 'WST',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'WS',
    alpha_3: 'WSM',
    flag: '🇼🇸',
    official_name: 'Independent State of Samoa',
  },
  {
    name: 'San Marino',
    id: 195,
    phone: '+378',
    symbol: '€',
    capital: 'San Marino',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'SM',
    alpha_3: 'SMR',
    flag: '🇸🇲',
    official_name: 'Republic of San Marino',
  },
  {
    name: 'Sao Tome and Principe',
    id: 196,
    phone: '+239',
    symbol: 'Db',
    capital: 'Sao Tome',
    currency: 'STD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ST',
    alpha_3: 'STP',
    flag: '🇸🇹',
    official_name: 'Democratic Republic of Sao Tome and Principe',
  },
  {
    name: 'Saudi Arabia',
    id: 197,
    phone: '+966',
    symbol: '﷼',
    capital: 'Riyadh',
    currency: 'SAR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'SA',
    alpha_3: 'SAU',
    flag: '🇸🇦',
    official_name: 'Kingdom of Saudi Arabia',
  },
  {
    name: 'Senegal',
    id: 198,
    phone: '+221',
    symbol: 'CFA',
    capital: 'Dakar',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SN',
    alpha_3: 'SEN',
    flag: '🇸🇳',
    official_name: 'Republic of Senegal',
  },
  {
    name: 'Serbia',
    id: 199,
    phone: '+381',
    symbol: 'din',
    capital: 'Belgrade',
    currency: 'RSD',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'RS',
    alpha_3: 'SRB',
    flag: '🇷🇸',
    official_name: 'Republic of Serbia',
  },
  {
    name: 'Seychelles',
    id: 201,
    phone: '+248',
    symbol: 'SRe',
    capital: 'Victoria',
    currency: 'SCR',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SC',
    alpha_3: 'SYC',
    flag: '🇸🇨',
    official_name: 'Republic of Seychelles',
  },
  {
    name: 'Sierra Leone',
    id: 202,
    phone: '+232',
    symbol: 'Le',
    capital: 'Freetown',
    currency: 'SLL',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SL',
    alpha_3: 'SLE',
    flag: '🇸🇱',
    official_name: 'Republic of Sierra Leone',
  },
  {
    name: 'Singapore',
    id: 203,
    phone: '+65',
    symbol: '$',
    capital: 'Singapur',
    currency: 'SGD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'SG',
    alpha_3: 'SGP',
    flag: '🇸🇬',
    official_name: 'Republic of Singapore',
  },
  {
    name: 'Sint Maarten',
    id: 204,
    phone: '+1',
    symbol: 'ƒ',
    capital: 'Philipsburg',
    currency: 'ANG',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'SX',
    alpha_3: 'SXM',
    flag: '🇸🇽',
    official_name: 'Sint Maarten (Dutch part)',
  },
  {
    name: 'Slovakia',
    id: 205,
    phone: '+421',
    symbol: '€',
    capital: 'Bratislava',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'SK',
    alpha_3: 'SVK',
    flag: '🇸🇰',
    official_name: 'Slovak Republic',
  },
  {
    name: 'Slovenia',
    id: 206,
    phone: '+386',
    symbol: '€',
    capital: 'Ljubljana',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'SI',
    alpha_3: 'SVN',
    flag: '🇸🇮',
    official_name: 'Republic of Slovenia',
  },
  {
    name: 'Solomon Islands',
    id: 207,
    phone: '+677',
    symbol: 'Si$',
    capital: 'Honiara',
    currency: 'SBD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'SB',
    alpha_3: 'SLB',
    flag: '🇸🇧',
  },
  {
    name: 'Somalia',
    id: 208,
    phone: '+252',
    symbol: 'Sh.so.',
    capital: 'Mogadishu',
    currency: 'SOS',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SO',
    alpha_3: 'SOM',
    flag: '🇸🇴',
    official_name: 'Federal Republic of Somalia',
  },
  {
    name: 'South Africa',
    id: 209,
    phone: '+27',
    symbol: 'R',
    capital: 'Pretoria',
    currency: 'ZAR',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ZA',
    alpha_3: 'ZAF',
    flag: '🇿🇦',
    official_name: 'Republic of South Africa',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    id: 210,
    phone: '+500',
    symbol: '£',
    capital: 'Grytviken',
    currency: 'GBP',
    continent: 'Antarctica',
    continent_code: 'AN',
    alpha_2: 'GS',
    alpha_3: 'SGS',
    flag: '🇬🇸',
  },
  {
    name: 'South Sudan',
    id: 211,
    phone: '+211',
    symbol: '£',
    capital: 'Juba',
    currency: 'SSP',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SS',
    alpha_3: 'SSD',
    flag: '🇸🇸',
    official_name: 'Republic of South Sudan',
  },
  {
    name: 'Spain',
    id: 212,
    phone: '+34',
    symbol: '€',
    capital: 'Madrid',
    currency: 'EUR',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'ES',
    alpha_3: 'ESP',
    flag: '🇪🇸',
    official_name: 'Kingdom of Spain',
  },
  {
    name: 'Sri Lanka',
    id: 213,
    phone: '+94',
    symbol: 'Rs',
    capital: 'Colombo',
    currency: 'LKR',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'LK',
    alpha_3: 'LKA',
    flag: '🇱🇰',
    official_name: 'Democratic Socialist Republic of Sri Lanka',
  },
  {
    name: 'Sudan',
    id: 214,
    phone: '+249',
    symbol: '.س.ج',
    capital: 'Khartoum',
    currency: 'SDG',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SD',
    alpha_3: 'SDN',
    flag: '🇸🇩',
    official_name: 'Republic of the Sudan',
  },
  {
    name: 'Suriname',
    id: 215,
    phone: '+597',
    symbol: '$',
    capital: 'Paramaribo',
    currency: 'SRD',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'SR',
    alpha_3: 'SUR',
    flag: '🇸🇷',
    official_name: 'Republic of Suriname',
  },
  {
    name: 'Svalbard and Jan Mayen',
    id: 216,
    phone: '+47',
    symbol: 'kr',
    capital: 'Longyearbyen',
    currency: 'NOK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'SJ',
    alpha_3: 'SJM',
    flag: '🇸🇯',
  },
  {
    name: 'Swaziland',
    id: 217,
    phone: '+268',
    symbol: 'E',
    capital: 'Mbabane',
    currency: 'SZL',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'SZ',
    alpha_3: 'SWZ',
    flag: '🇸🇿',
    official_name: 'Kingdom of Eswatini',
  },
  {
    name: 'Sweden',
    id: 218,
    phone: '+46',
    symbol: 'kr',
    capital: 'Stockholm',
    currency: 'SEK',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'SE',
    alpha_3: 'SWE',
    flag: '🇸🇪',
    official_name: 'Kingdom of Sweden',
  },
  {
    name: 'Switzerland',
    id: 219,
    phone: '+41',
    symbol: 'CHf',
    capital: 'Berne',
    currency: 'CHF',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'CH',
    alpha_3: 'CHE',
    flag: '🇨🇭',
    official_name: 'Swiss Confederation',
  },
  {
    name: 'Syrian Arab Republic',
    id: 220,
    phone: '+963',
    symbol: 'LS',
    capital: 'Damascus',
    currency: 'SYP',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'SY',
    alpha_3: 'SYR',
    flag: '🇸🇾',
  },
  {
    name: 'Taiwan, Province of China',
    id: 221,
    phone: '+886',
    symbol: '$',
    capital: 'Taipei',
    currency: 'TWD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TW',
    alpha_3: 'TWN',
    flag: '🇹🇼',
    official_name: 'Taiwan, Province of China',
  },
  {
    name: 'Tajikistan',
    id: 222,
    phone: '+992',
    symbol: 'SM',
    capital: 'Dushanbe',
    currency: 'TJS',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TJ',
    alpha_3: 'TJK',
    flag: '🇹🇯',
    official_name: 'Republic of Tajikistan',
  },
  {
    name: 'Tanzania, United Republic of',
    id: 223,
    phone: '+255',
    symbol: 'TSh',
    capital: 'Dodoma',
    currency: 'TZS',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'TZ',
    alpha_3: 'TZA',
    flag: '🇹🇿',
    official_name: 'United Republic of Tanzania',
  },
  {
    name: 'Thailand',
    id: 224,
    phone: '+66',
    symbol: '฿',
    capital: 'Bangkok',
    currency: 'THB',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TH',
    alpha_3: 'THA',
    flag: '🇹🇭',
    official_name: 'Kingdom of Thailand',
  },
  {
    name: 'Timor-Leste',
    id: 225,
    phone: '+670',
    symbol: '$',
    capital: 'Dili',
    currency: 'USD',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TL',
    alpha_3: 'TLS',
    flag: '🇹🇱',
    official_name: 'Democratic Republic of Timor-Leste',
  },
  {
    name: 'Togo',
    id: 226,
    phone: '+228',
    symbol: 'CFA',
    capital: 'Lome',
    currency: 'XOF',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'TG',
    alpha_3: 'TGO',
    flag: '🇹🇬',
    official_name: 'Togolese Republic',
  },
  {
    name: 'Tokelau',
    id: 227,
    phone: '+690',
    symbol: '$',
    capital: '',
    currency: 'NZD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'TK',
    alpha_3: 'TKL',
    flag: '🇹🇰',
  },
  {
    name: 'Tonga',
    id: 228,
    phone: '+676',
    symbol: '$',
    capital: "Nuku'alofa",
    currency: 'TOP',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'TO',
    alpha_3: 'TON',
    flag: '🇹🇴',
    official_name: 'Kingdom of Tonga',
  },
  {
    name: 'Trinidad and Tobago',
    id: 229,
    phone: '+1868',
    symbol: '$',
    capital: 'Port of Spain',
    currency: 'TTD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'TT',
    alpha_3: 'TTO',
    flag: '🇹🇹',
    official_name: 'Republic of Trinidad and Tobago',
  },
  {
    name: 'Tunisia',
    id: 230,
    phone: '+216',
    symbol: 'ت.د',
    capital: 'Tunis',
    currency: 'TND',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'TN',
    alpha_3: 'TUN',
    flag: '🇹🇳',
    official_name: 'Republic of Tunisia',
  },
  {
    name: 'Turkey',
    id: 231,
    phone: '+90',
    symbol: '₺',
    capital: 'Ankara',
    currency: 'TRY',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TR',
    alpha_3: 'TUR',
    flag: '🇹🇷',
    official_name: 'Republic of Turkey',
  },
  {
    name: 'Turkmenistan',
    id: 232,
    phone: '+7370',
    symbol: 'T',
    capital: 'Ashgabat',
    currency: 'TMT',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'TM',
    alpha_3: 'TKM',
    flag: '🇹🇲',
  },
  {
    name: 'Turks and Caicos Islands',
    id: 233,
    phone: '+1649',
    symbol: '$',
    capital: 'Cockburn Town',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'TC',
    alpha_3: 'TCA',
    flag: '🇹🇨',
  },
  {
    name: 'Tuvalu',
    id: 234,
    phone: '+688',
    symbol: '$',
    capital: 'Funafuti',
    currency: 'AUD',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'TV',
    alpha_3: 'TUV',
    flag: '🇹🇻',
  },
  {
    name: 'Uganda',
    id: 235,
    phone: '+256',
    symbol: 'USh',
    capital: 'Kampala',
    currency: 'UGX',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'UG',
    alpha_3: 'UGA',
    flag: '🇺🇬',
    official_name: 'Republic of Uganda',
  },
  {
    name: 'Ukraine',
    id: 236,
    phone: '+380',
    symbol: '₴',
    capital: 'Kiev',
    currency: 'UAH',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'UA',
    alpha_3: 'UKR',
    flag: '🇺🇦',
  },
  {
    name: 'United Arab Emirates',
    id: 237,
    phone: '+971',
    symbol: 'إ.د',
    capital: 'Abu Dhabi',
    currency: 'AED',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'AE',
    alpha_3: 'ARE',
    flag: '🇦🇪',
  },
  {
    name: 'United Kingdom',
    id: 238,
    phone: '+44',
    symbol: '£',
    capital: 'London',
    currency: 'GBP',
    continent: 'Europe',
    continent_code: 'EU',
    alpha_2: 'GB',
    alpha_3: 'GBR',
    flag: '🇬🇧',
    official_name: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    name: 'United States',
    id: 239,
    phone: '+1',
    symbol: '$',
    capital: 'Washington',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'US',
    alpha_3: 'USA',
    flag: '🇺🇸',
    official_name: 'United States of America',
  },
  {
    name: 'United States Minor Outlying Islands',
    id: 240,
    phone: '+1',
    symbol: '$',
    capital: '',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'UM',
    alpha_3: 'UMI',
    flag: '🇺🇲',
  },
  {
    name: 'Uruguay',
    id: 241,
    phone: '+598',
    symbol: '$',
    capital: 'Montevideo',
    currency: 'UYU',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'UY',
    alpha_3: 'URY',
    flag: '🇺🇾',
    official_name: 'Eastern Republic of Uruguay',
  },
  {
    name: 'Uzbekistan',
    id: 242,
    phone: '+998',
    symbol: 'лв',
    capital: 'Tashkent',
    currency: 'UZS',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'UZ',
    alpha_3: 'UZB',
    flag: '🇺🇿',
    official_name: 'Republic of Uzbekistan',
  },
  {
    name: 'Vanuatu',
    id: 243,
    phone: '+678',
    symbol: 'VT',
    capital: 'Port Vila',
    currency: 'VUV',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'VU',
    alpha_3: 'VUT',
    flag: '🇻🇺',
    official_name: 'Republic of Vanuatu',
  },
  {
    name: 'Venezuela',
    id: 244,
    phone: '+58',
    symbol: 'Bs',
    capital: 'Caracas',
    currency: 'VEF',
    continent: 'South America',
    continent_code: 'SA',
    alpha_2: 'VE',
    alpha_3: 'VEN',
    flag: '🇻🇪',
    official_name: 'Bolivarian Republic of Venezuela',
  },
  {
    name: 'Viet Nam',
    id: 245,
    phone: '+84',
    symbol: '₫',
    capital: 'Hanoi',
    currency: 'VND',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'VN',
    alpha_3: 'VNM',
    flag: '🇻🇳',
    official_name: 'Socialist Republic of Viet Nam',
  },
  {
    name: 'Virgin Islands, British',
    id: 246,
    phone: '+1284',
    symbol: '$',
    capital: 'Road Town',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'VG',
    alpha_3: 'VGB',
    flag: '🇻🇬',
    official_name: 'British Virgin Islands',
  },
  {
    name: 'Virgin Islands, U.s.',
    id: 247,
    phone: '+1340',
    symbol: '$',
    capital: 'Charlotte Amalie',
    currency: 'USD',
    continent: 'North America',
    continent_code: 'NA',
    alpha_2: 'VI',
    alpha_3: 'VIR',
    flag: '🇻🇮',
    official_name: 'Virgin Islands of the United States',
  },
  {
    name: 'Wallis and Futuna',
    id: 248,
    phone: '+681',
    symbol: '₣',
    capital: 'Mata Utu',
    currency: 'XPF',
    continent: 'Oceania',
    continent_code: 'OC',
    alpha_2: 'WF',
    alpha_3: 'WLF',
    flag: '🇼🇫',
  },
  {
    name: 'Western Sahara',
    id: 249,
    phone: '+212',
    symbol: 'MAD',
    capital: 'El-Aaiun',
    currency: 'MAD',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'EH',
    alpha_3: 'ESH',
    flag: '🇪🇭',
  },
  {
    name: 'Yemen',
    id: 250,
    phone: '+967',
    symbol: '﷼',
    capital: 'Sanaa',
    currency: 'YER',
    continent: 'Asia',
    continent_code: 'AS',
    alpha_2: 'YE',
    alpha_3: 'YEM',
    flag: '🇾🇪',
    official_name: 'Republic of Yemen',
  },
  {
    name: 'Zambia',
    id: 251,
    phone: '+260',
    symbol: 'ZK',
    capital: 'Lusaka',
    currency: 'ZMW',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ZM',
    alpha_3: 'ZMB',
    flag: '🇿🇲',
    official_name: 'Republic of Zambia',
  },
  {
    name: 'Zimbabwe',
    id: 252,
    phone: '+263',
    symbol: '$',
    capital: 'Harare',
    currency: 'ZWL',
    continent: 'Africa',
    continent_code: 'AF',
    alpha_2: 'ZW',
    alpha_3: 'ZWE',
    flag: '🇿🇼',
    official_name: 'Republic of Zimbabwe',
  },
];
