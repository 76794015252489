import { useTranslations } from '../../../components/UI/Theme';
import { Paper, Form, FormInput, showApiError } from '../../../components/UI';
import {
  container,
  formTitleContainer,
  loginTitle,
  title,
  detailStylesLogin,
  loginSubtitle,
  forgotStyles,
} from './styles';
import { Link } from 'react-router-dom';
import { getIdentityProviderByEmail } from '../../../services/profiles';
import { identityProviderRedirectUrlPath } from '../../../enum';

const ForgotPassword = () => {
  const { translations } = useTranslations();

  const onSubmit = async values => {
    const [res, err] = await getIdentityProviderByEmail(values.email);
    if (err) {
      showApiError(err);
      return;
    }

    if (res) {
      const redirectUrl = `${res.rootUri}/${identityProviderRedirectUrlPath[res.provider]}`;
      window.location = redirectUrl;
    }
  };

  return (
    <div css={container}>
      <div css={formTitleContainer}>
        <Paper
          header={
            <div css={loginTitle}>
              <h1 css={title}>{translations.forgotPassword}</h1>
              <div css={detailStylesLogin}>
                <p css={loginSubtitle}>
                  {translations.confirmEmailAddress}
                  <span css={forgotStyles}><Link to="/login">{`${translations.cancel}?`}</Link></span>
                </p>
              </div>
            </div>
          }>
          <Form
            onSubmit={onSubmit}
            submitButton={{ children: translations.submit }}
            preFilledValues={{
              email: ''
            }}>
            <FormInput
              id="email"
              label={translations.emailAddress}
              placeholder={translations.emailAddress}
              required
            />
          </Form>
        </Paper>
      </div>
    </div>
  );
};

export default ForgotPassword;
